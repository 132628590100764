<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <br>
        <!-- pradeep -->
        <h4 style="margin-left: 32px; margin-top: 0px;" class="set_msg">New Project
            <a class="btn-rotate help-icon" @click="showUrl()">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
            </a>
        </h4>
        <!-- End -->
        <hr>
        <!-- Pradeep Start -->
        <!-- set responsive -->
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="col-sm-12">
                    <div class="row">
                        <label class="col-sm-12 control-label">Project Type</label>
                        <div class="col-sm-12">
                            <p-radio inline v-model="activeTab" value="MV" label="MV"><img :src="mvlogo"
                                                                                           height="35" width="35"/>
                                Mass Vaccination
                            </p-radio>
                            <p-radio inline v-model="activeTab" value="PM" label="PM">
                                <div>
                                    <div style="float: left; margin-right: 5px;">
                                        <img :src="pmlogo"
                                             height="35" width="35"/>
                                    </div>
                                        Population Management<br/>(spay neuter)

                                </div>
                            </p-radio>
                            <p-radio inline v-model="activeTab" value="HC" label="HC"><img :src="hclogo"
                                                                                           height="35" width="35"/>
                                Health Clinic
                            </p-radio>
                        </div>
                    </div>
                </div>
                <br/>
                <new-mv-project v-if="activeTab==='MV'"></new-mv-project>
                <new-pm-project v-if="activeTab==='PM'"></new-pm-project>
                <new-hc-project v-if="activeTab==='HC'"></new-hc-project>
            </div>
            <div class="col-md-1"></div>
        </div>
        <help-modal ref="helpModal"></help-modal>
    </div>
    <!--    Pradeep End   -->
</template>
<script>

import mixin from '../../mixins/GlobalMixin';

import {Checkbox} from 'element-ui';

import {API_KEY} from './Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['places'];

import pmlogo from '../../assets/images/pm-logo.png';
import mvlogo from '../../assets/images/mv-logo.png';
import hcLogo from '../../assets/images/hc_logo_small.png';

import NewMvProject from "@/components/Template/NewMvProject";
import NewPmProject from "@/components/Template/Pm/NewPmProject";
import NewHcProject from "@/components/Template/HealthClinic/NewHcProject";
import Swal from "sweetalert2";


export default {
    components: {
        NewMvProject,
        NewPmProject,
        NewHcProject,
        [Checkbox.name]: Checkbox,
    },
    mixins: [mixin],
    props: {
        projectId: {
            type: Number,
            default: 0,
        },
        mvlogo: {
            type: String,
            default: mvlogo,
        },
        pmlogo: {
            type: String,
            default: pmlogo,
        },
        hclogo: {
            type: String,
            default: hcLogo,
        },
    },
    data() {
        return {
            activeTab: 'MV',
        }
    },
    methods: {
        showUrl() {
            this.$refs.helpModal.loadUrl(window.USER_MANUAL_URL + "#h.5qjvw4h0lc41");
        },
    },

    beforeRouteLeave(to, from, next) {
        // If the form is dirty and the user did not confirm leave,
        // prevent losing unsaved changes by canceling navigation
            new Swal({
                title: "Leave this page",
                text: 'You are about to leave this page. Any unsaved changes will be lost!',
                type: "warning",
                icon: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Continue",
                buttonsStyling: false
            }).then(function (result) {

                if(result.value){
                    next();
                }else{
                    next(false);
                }

            })
    },

}

</script>
<style scoped>

</style>
