import defaultLogo from './assets/images/HSIApps-Trnprnt-green.png';

export default {
    appName: 'HSAPPS',
    logo: defaultLogo,
    title: 'MVD App',
    project: 'mvd',
    nav: [
        {
            name: 'Dashboard',
            icon: 'ti-dashboard',
            path: '/overview',
            p:'/overview',
        },
        {
            name: 'Areas',
            icon: 'ti-map-alt',
            path: 'areas',
            p:'/areas',
        },
        {
            name: 'Assign Users',
            icon: 'ti-check-box',
            path: 'assignments',
            p:'/assignments',
        },
        {
            name: 'Records',
            icon: 'ti-view-list-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Vaccination',
                    path: 'vaccination',
                    p:'/vaccination',
                },
                {
                    name: 'Out-of-Area Vaccinations',
                    path: 'vaccination/outer',
                    p:'/vaccination/outer',
                },
                {
                    name: 'Surveys',
                    path: 'survey',
                    p:'/survey',
                },
                {
                    name: 'Archived',
                    path: 'archived',
                    p:'/archived',
                },
            ],
        },
        {
            name: 'Vaccination Reports',
            icon: 'ti-receipt',
            id: 'third-party',
            children: [
                {
                    name: 'City/District/Village',
                    path: 'report/location',
                    p:'/report/location',
                },
                {
                    name: 'Area',
                    path: 'report/area',
                    p:'/report/area',
                },
                {
                    name: 'Map',
                    path: 'vaccination/mapview',
                    p:'/vaccination/mapview',
                },
            ],
        },
        {
            name: 'Survey Reports',
            icon: 'ti-receipt',
            id: 'third-party',
            children: [
                {
                    name: 'City/District/Village',
                    path: 'report/surveyLocation',
                    p:'/report/surveyLocation',
                },
                {
                    name: 'Area',
                    path: 'report/surveyArea',
                    p:'/report/surveyArea',
                },
                {
                    name: 'Map',
                    path: 'survey/mapview',
                    p:'/survey/mapview',
                },
            ],
        },
        {
            name: 'Settings',
            icon: 'nc-icon nc-settings',
            path: 'settings',
            p:'/settings',
        },
    ],
};
