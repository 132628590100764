<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-md-6">
                        <h5 class="set_font_title">Species</h5>
                    </div>
                    <div class="col-md-6">
                        <div class="add-drugs-species-button">
                            <button
                                type="button"
                                class="btn btn-success btn-round"
                                style="background-color: #2a9055 !important;"
                                @click="showSpeciesModal"
                            >
                                Add Species
                            </button>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-12">
                        <p>Add the animal species the organization intends to serve so they can later be associated with
                            various projects. For some orgs, this may just be a single species.</p>
                    </div>
                </div>

                <div class="row" v-for="(item, index) in speciesData" :key="index">
                    <div class="col-md-10 col-sm-6">
                        <p class="species_name">{{ item.species_name }}</p>
                    </div>
                    <div class="col-md-2 col-sm-6 icon-main">
            <span
                class="ti-pencil icon-pencil"
                @click="updateSpecies(item)"
            ></span>
                        <span
                            class="ti-trash icon-trash"
                            @click="deleteSpecies(item.id)"
                        ></span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="speciesModal" class="modal species-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content animle-modal-content">
                    <div class="modal-header species-header">
                        <h4 class="modal-title">
                            {{ allowEditSpecies ? "Edit" : "Add" }} Species
                        </h4>
                    </div>
                    <div class="modal-body species-modal-body">
                        <div class="form-group row species-row">
                            <label>Species Name</label><br/>
                            <input
                                class="form-control col-sm-12"
                                type="text"
                                name="species-name"
                                v-model="species_name"
                            />
                            <br/>
                            <p>
                                (Note: This will make new species type visible to entire
                                organisation)
                            </p>
                        </div>
                    </div>
                    <div class="species-footer">
                        <button
                            type="button"
                            class="btn btn-default"
                            style="background-color: #eb6532 !important;"
                            data-dismiss="modal"
                            @click="hideSpecieslModal"
                        >
                            Close
                        </button>
                        <button
                            v-bind:disabled="!onLine"
                            type="button"
                            class="btn btn-default"
                            style="background-color: #2a9055 !important;"
                            data-dismiss="modal"
                            @click="updateSpeciesData"
                        >
                            {{ allowEditSpecies ? "Edit" : "Add" }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

import mixin from "../../mixins/GlobalMixin";
import Swal from "sweetalert2"

export default {
    components: {},
    data: function () {
        return {
            speciesData: [],
            species_name: "",
            allowEditSpecies: false,
            speciesModal: ""
        };
    },
    mixins: [mixin],
    methods: {
        showSpeciesModal() {
            this.species_name = "";
            this.allowEditSpecies = false;
            this.speciesModal = true;
        },
        updateSpeciesData() {
            let vm = this;
            if (this.allowEditSpecies) {
                this.editSpecies();
                return;
            } else {
                if (vm.species_name != "") {
                    axios
                        .post("animal/add/org", {
                            animal_name: vm.species_name,
                            organisation_id: vm.organisationId,
                        })
                        .then((response) => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert("Success", response.data.response.error.msg, true);
                                vm.getSpecies(vm.organisationId);
                                vm.speciesModal = false;
                            }
                        })
                        .catch((error) => {
                            vm.speciesModal = false;
                            vm.showAlert(
                                "Sorry",
                                error.response.data.response.data.animal_name[0],
                                false
                            );
                        });
                } else {
                    vm.showAlert("Error", "please enter species name", false);
                }
            }
        },
        getSpecies(id) {
            var vm = this;
            axios
                .get("/animal/organisation/" + id)
                .then((response) => {
                    if (response.data.response.error.error_code == 0) {
                        var speciesList = response.data.response.data.animals;
                        vm.speciesData = speciesList;
                    }
                })
                .catch((error) => {
                });
        },
        deleteSpecies(speciesId) {
            let vm = this;
            new Swal({
                title: "Alert!",
                text: "Are you sure you want to delete species ? ",
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "yes",
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios
                        .delete(
                            "/animal/remove/org/" +
                            vm.organisationId +
                            "?animal_id=" +
                            speciesId
                        )
                        .then((response) => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert("Success", response.data.response.error.msg, true);
                                vm.getSpecies(vm.organisationId);
                            }
                        })
                        .catch((error) => {
                            vm.showAlert(
                                "Error",
                                error.response.data.response.error.error_msg,
                                false
                            );
                        });
                }
            });
        },
        updateSpecies(data) {
            this.speciesModal = true;
            this.allowEditSpecies = true;
            this.updateSpeciesId = data.id;
            this.species_name = data.species_name;
        },
        hideSpecieslModal() {
            this.speciesModal = false;
        },
        editSpecies() {
            var vm = this;
            if (vm.species_name != "") {
                axios
                    .put(
                        "/animal/edit/org/" +
                        vm.organisationId +
                        "?animal_id=" +
                        vm.updateSpeciesId +
                        "&edited_animal_name=" +
                        vm.species_name
                    )
                    .then((response) => {
                        if (response.data.response.error.error_code == 0) {
                            vm.showAlert("Success", response.data.response.error.msg, true);
                            vm.getSpecies(vm.organisationId);
                            this.speciesModal = false;
                        }
                    })
                    .catch((error) => {
                        vm.speciesModal = false;
                        vm.showAlert(
                            "Sorry",
                            error.response.data.response.data.edited_animal_name[0],
                            false
                        );
                    });
            } else {
                vm.showAlert("Error", "please enter species name", false);
            }
        },
    },
    computed: {
        ...mapGetters("user", ["organisation"]),
    },
    mounted() {
        const vm = this;
        vm.organisationId = this.organisation.id;
        this.getSpecies(vm.organisationId);
    },
};
</script>
<style scoped>
.card-body {
    text-align: left !important;
}

a {
    color: white;
}

.a_color {
    color: black !important;
}

/* Pradeep Start */
@media (min-width: 320px) {
    .set_row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

/* Pradeep end */
.set_font_title {
    margin-top: 15px;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.species_name {
    margin-top: 5px !important;
    text-align: left;
}

.icon-main {
    margin-top: 5px !important;
    text-align: right;
}

.icon-pencil {
    cursor: pointer;
}

.icon-trash {
    cursor: pointer;
    margin-left: 10px;
}

.modal-footer {
    border-top: 1px solid #dddddd;
    padding: 10px !important;
}

.modal-title {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.species-footer {
    text-align: right;
    margin-right: 4%;
    margin-bottom: 1%;
}

.add-drugs-species-button {
    text-align: left;
    float: right;
    /* margin-left: 12px; */
}

.species-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    padding: 80px;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
    display: block;
    opacity: 1 !important;
}
@media (max-width: 767px) {
    .species-modal{
        padding: 0;
    }
    .modal-title {
        font-size: 1.4em;
    }
    .modal-body{
        padding: 20px 30px;
    }
}

.species-modal-body {
    color: #000;
}
</style>
