<template>
    <div>
        <v-offline
                online-class="online"
                offline-class="offline"
                @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <div class="containBytitile">
                            <h4 class="card-title">Microchip Report</h4>
                        </div>
                        <p class="category"></p>
                    </div>
                    <div class="card-body">
                        <!-- <microchip-tables></microchip-tables> -->
                        <div class="row set_row">
                            <!-- <div class="col-xl-1">
                            </div> -->
                            <div class="col-sm-12 col-xl-12 set_table_center">
                                <table id="example" class="display table-striped table-bordered select" cellspacing="0"
                                       width="100%">
                                    <thead>
                                    <tr>
                                        <th>Species</th>
                                        <th>Photo</th>
                                        <th>Gender</th>
                                        <th>HSIApps ID</th>
                                        <th>Microchip No.</th>
                                        <th>Collar ID</th>
                                        <th>Colour</th>
                                        <th>RFID inserted by</th>
                                        <th>Caught Date</th>
                                        <th>Area</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <!-- <div class="col-xl-1">
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- The Modal -->
        <div id="myModal" class="modal_full_img">
            <span class="close-img">&times;</span>
            <img class="modal-content-full-img" id="img01">
            <div id="caption"></div>
        </div>
    </div>
</template>
<script>
import mixin from '../../../../mixins/GlobalMixin';
import VOffline from 'v-offline';
import MicrochipTables from "../../Tables/MicrochipTables.vue";
import {Select, Option,} from 'element-ui';

export default {
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        "microchip-tables": MicrochipTables,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data()
    {
        return {
            table: null,
        }
    },
    mixins: [mixin],
    methods: {
        microchipRecords()
        {
            let vm = this;
            if (vm.table != null) {
                vm.table.destroy();
            }
            try {
                vm.table = $('#example').DataTable({
                    "order": [[8, "desc"]],
                    "scrollX": true,
                    responsive: true,
                    dom: 'lBfrtip',
                    "bInfo": false,
                    'columns': [
                        null,
                        {'searchable': false},
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ],
                    buttons: [
                        {
                            extend: 'excel',
                            text: 'Export to Excel',
                            title: 'Microchip Report',
                        },
                    ],
                    "processing": true,
                    "serverSide": true,
                    "ajax": {
                        "url": window.DATATABLES_BASE_URL + "pm/reports/microchip/" + vm.projectId,
                        "type": "POST",
                        "dataSrc": function (json) {
                            var rowData = json.data;
                            for (var i = 0; i < rowData.length; i++) {
                                rowData[i][1] = '<span style="display: none;">' + rowData[i][1] + '</span><img src=' + rowData[i][1] + ' class="datatable_image_thumbnail" style="cursor: pointer;" height="70" width="70"/>';
                                rowData[i][8]=vm.convertDate(rowData[i][8])
                            }
                            return rowData;
                        }
                    },
                    lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                    pageLength: 15,
                });
            } catch (e) {
            }
        },
        zoomImagePopup()
        {
            var modal = document.getElementById("myModal");
            var img = document.querySelectorAll(".datatable_image_thumbnail");
            var modalImg = document.getElementById("img01");
            var captionText = document.getElementById("caption");
            for (var i = 0; i < img.length; i++) {
                img[i].addEventListener('click', function () {
                    modal.style.display = "block";
                    modalImg.src = this.src;
                    captionText.innerHTML = this.alt;
                }, false);
            }
            var span = document.getElementsByClassName("close-img")[0];
            span.onclick = function () {
                modal.style.display = "none";
            }
        }
    },
    computed: {},
    created()
    {
        var self = this;
        setTimeout(function () {
            self.zoomImagePopup();
        }, 2000);
    },
    mounted()
    {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        vm.projectId = vm.project.id;
        //Bus.$emit("projectype", vm.project);
        vm.microchipRecords();
    }
}

</script>
<style lang="scss">
.containBytitile h4 {
  font-size: 1.2em;
  line-height: 1.45em;
}

.dt-buttons {
  margin-top: -10px;
  margin-left: 10px;
}

/* Model Css */
/* The Modal (background) */
.modal_full_img {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  padding: 80px;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
  display: none;
}

/* Modal Content (image) */
.modal-content-full-img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  //height: 100%;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content-full-img, #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }
  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

/* The Close Button */
.close-img {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close-img:hover,
.close-img:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content-full-img {
    width: 100%;
  }
}

.close-edit-records {
  position: absolute;
  top: 15px;
  right: 35px;
  font-size: 40px;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 900px !important;
    margin: 1.75rem auto;
  }
}

.delete_btn {
  background-color: red !important;
}

.set_footer {
  padding: 15px;
}

.edit-header {
  height: 60px !important;
  margin-top: -13px !important;;
}

.form-group {
  margin-bottom: 0px !important;
  position: relative;
}

label {
  display: inline-block;
  margin-bottom: 0.3rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pac-container {
  z-index: 1000000000 !important;
  opacity: 1.5 !important;
}
</style>
