<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <h5 class="set_font_title">Users Report</h5>
            </div>
        </div>

        <div class="row setMarginTop">
            <div class="col-md-3">
                <label class="pr-2">Select Users</label>
                <br>
                <el-select v-model="selectedUser" multiple filterable :disabled="isAllUserSelected">
                    <el-option
                        v-for="(assignedUser) in assignedUsers"
                        :key="assignedUser.id"
                        :value="assignedUser.user_id"
                        :label="assignedUser.user.first_name + ' ' + assignedUser.user.last_name">
                        {{assignedUser.user.first_name}} {{ assignedUser.user.last_name  }}
                    </el-option>
                </el-select>
                <br>
                <el-checkbox v-model="isAllUserSelected" @change="onAllUserSelect">All users</el-checkbox>
            </div>
            <div class="col">
                <label class="pr-2">Select Date(s)</label>
                <br>
                <v-md-date-range-picker
                    :class="{'disable-dtp':isAllDateSelected}"
                    :start-date="startDatePast"
                    :end-date="endDatePast"
                    @change="handleChange"
                    style="width:100% !important">
                </v-md-date-range-picker>
                <br>
                <el-checkbox v-model="isAllDateSelected">All dates</el-checkbox>
            </div>
            <div class="col">
                <p></p>
                <el-button @click="getUserReport" class="mt-4">Download</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VOffline from 'v-offline';
import {Button, Checkbox} from 'element-ui';
import VMdDateRangePicker from "v-md-date-range-picker";
import healthClinicService from "../../../../services/health-clinic.service";
import dateRangeDefaultPresetsMixin from '../../../../mixins/DateRangeDefaultPresetsMixin';

Vue.use(VMdDateRangePicker);

export default {
    components: {
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        VOffline
    },
    props: {
        projectId: {
            type: String | Number
        },
    },
    data() {
        return {
            startDatePast: '',
            endDatePast: '',
            startDate: '',
            endDate: '',
            assignedUsers: [],
            selectedUser: [],
            project: null,
            isAllUserSelected: true,
            isAllDateSelected: true
        }
    },
    mixins: [dateRangeDefaultPresetsMixin],
    methods: {

        getUserReport() {
            const vm = this;
            vm.startDate = vm.formatDate(vm.startDatePast);
            vm.endDate = vm.formatDate(vm.endDatePast);
            const params = {
                start_date: !vm.isAllDateSelected ? vm.startDate : undefined,
                end_date: !vm.isAllDateSelected ? vm.endDate : undefined,
                user_ids: !vm.isAllUserSelected ? vm.selectedUser : undefined
            };

            const loading = vm.$loading({
                lock: true,
                text: 'Please wait generating users report...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            healthClinicService.getUserReport(vm.projectId, params)
            .then((apiResposne) => {
                loading.close();
                if (!apiResposne.error) {
                    const reportHtml = apiResposne;
                    // Open a new window/tab
                    const newWindow = window.open('', '_blank');

                    // Write the HTML to the new window/tab
                    newWindow.document.open();
                    newWindow.document.write(reportHtml);
                    newWindow.document.close();
                }
            })
            .catch(() => {
                loading.close();
            });
        },
        handleChange(values) {
            this.startDate = this.formatDate(values[0]._d);
            this.endDate = this.formatDate(values[1]._d);
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        getAssignedUsers() {
            let vm = this;
            const loading = vm.$loading({
                lock: true,
                text: 'Please wait loading users...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            healthClinicService.getAssignedUsers(vm.projectId)
            .then((apiResponse) => {
                loading.close();
                vm.assignedUsers = apiResponse.response.data.assigned_users;
            });
        },
        onAllUserSelect() {
            const vm = this;
            if (vm.isAllUserSelected) {
                vm.selectedUser = [];
            }
        }

    },
    mounted() {
        const vm = this;
        vm.$store.dispatch("setScreenName", "User Report");
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        const date = new Date();
        vm.startDatePast = new Date(vm.project ? vm.project.created_at : (date.getFullYear(), date.getMonth(), 1)).toLocaleDateString('en-US');
        vm.endDatePast = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('en-US');
        vm.getAssignedUsers();
    }
}
</script>
<style scoped>
.disable-dtp {
    cursor: none !important;
    pointer-events: none !important;
    opacity: 0.5;
}
</style>
