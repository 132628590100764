<template>
    <div>
        <div class="card">
            <div class="card-body">
                <h5>
                    Organisation
                    <template v-if="organisation != null">
                        <span v-if="organisation.verified == 0"> (Pending Approval)</span>
                    </template>
                    <template v-if="organisation != null"
                    ><span v-if="organisation.verified == 1"> (Verified)</span>
                    </template>
                    <a class="btn-rotate help-icon" @click="showUrl()">
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </a>
                </h5>
                <hr/>
                <template v-if="organisation != null">
                    <div class="alert alert-info" v-if="organisation.verified == 0">
        <span
        ><b>Thank you! </b>Your organisation request has been submitted to the
          super admin successfully and is waiting for approval from
          <b>HSI</b>.</span
        ><br/>
                        <span
                        >for further detail and query contact
          <a target="_blank" href="mailto:admin@hsiapps.org"
          ><b>admin@hsiapps.org</b></a
          ></span
                        >
                    </div>
                    <div class="alert alert-success" v-if="organisation.verified == 1">
                        <span>Your organisation is <b>Verified</b></span>
                    </div>
                    <div class="alert alert-danger" v-if="organisation.verified == 2">
        <span
        ><b>Sorry! </b>Your organisation request has been rejected by the
          super admin or <b>HSI</b>.</span
        ><br/>
                        <span
                        >for further detail and query contact
          <a target="_blank" href="mailto:admin@hsiapps.org"
          ><b>admin@hsiapps.org</b></a
          ></span
                        >
                    </div>
                </template>

                <div class="row set_row">
                    <div class="col-md-4 col-sm-8" style="margin-bottom: 25px">
                        <template v-if="organisation != null && !uploadNew">
                            <div class="project-img">
                                <img :src="organisation.logo_url"/>
                            </div>
                            <template v-if="organisation.verified == 1">
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    v-on:click="toggleUploadNew"
                                >
                                    Change
                                </button>
                            </template>
                        </template>
                        <template v-if="uploadNew">
                            <p>Logo image ({{ uploadingLabel }})</p>
                            <image-upload
                                :target="imageUploadApiUrl"
                                selector="example"
                                :auto-push="true"
                            >
                            </image-upload>
                            <template v-if="organisation != null">
                                <template v-if="organisation.logo_url != ''">
                                    <button
                                        v-if="organisation != null"
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        v-on:click="toggleUploadNew"
                                    >
                                        Cancel
                                    </button>
                                </template>
                            </template>
                            <br/>
                        </template>
                    </div>
                </div>

                <div class="row set_row">
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.nameDisabled"
                                    type="text"
                                    label="Name*"
                                    name="Name"
                                    v-validate="modelValidations.orgName"
                                    :error="getError('Name')"
                                    v-model="model.orgName"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.inputDisabled"
                                    type="textarea"
                                    label="Website"
                                    name="Website"
                                    placeholder="http://example.com"
                                    v-validate="modelValidations.orgWebsite"
                                    :error="getError('Website')"
                                    v-model="model.orgWebsite"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.inputDisabled"
                                    type="text"
                                    label="Email*"
                                    name="Email"
                                    v-validate="modelValidations.orgEmail"
                                    :error="getError('Email')"
                                    v-model="model.orgEmail"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row set_row">
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.inputDisabled"
                                    type="text"
                                    label="Country"
                                    v-model="model.orgCountry"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.inputDisabled"
                                    type="text"
                                    label="State"
                                    v-model="model.orgState"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.inputDisabled"
                                    type="text"
                                    label="City"
                                    v-model="model.orgCity"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row set_row">
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.inputDisabled"
                                    textarea
                                    type="text"
                                    placeholder="Enter some description here..."
                                    label="About*"
                                    name="About"
                                    v-validate="modelValidations.orgAbout"
                                    :error="getError('About')"
                                    v-model="model.orgAbout"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.inputDisabled"
                                    textarea
                                    type="text"
                                    label="Address"
                                    name="Address"
                                    v-model="model.orgAddress"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.inputDisabled"
                                    type="text"
                                    label="Zip Code"
                                    name="Zip Code"
                                    v-validate="modelValidations.orgZipCode"
                                    :error="getError('Zip Code')"
                                    v-model="model.orgZipCode"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row set_row">
                    <div class="col-md-4 col-sm-8">
                        <fieldset>
                            <div class="form-group">
                                <fg-input
                                    :disabled="model.inputDisabled"
                                    type="text"
                                    label="Contact Name"
                                    name="Contact"
                                    v-validate="modelValidations.orgContact"
                                    :error="getError('Contact')"
                                    v-model="model.orgContact"
                                >
                                </fg-input>
                            </div>
                        </fieldset>
                        <div v-if="organisation == null || organisation.verified == 2">
                            <span>Optional: Upload a document describing your program(s) in more detail.</span><br/>
                            <input
                                type="file"
                                id="file"
                                ref="file"
                                v-on:change="handleFileUpload()"
                            />
                            <button
                                :disabled="isUploadingDocument"
                                v-on:click="submitFile()"
                                class="btn btn-info"
                            >
                                {{ getUploadStatus }}
                            </button>
                        </div>
                        <div v-if="attachments.documents.length > 0">
                            <h5 style="margin: 0">Uploaded Documents</h5>
                            <div v-for="(doc, index) in attachments.documents" :key="index">
                                <i class="nc-icon nc-check-2"></i>
                                <a class="a_color" :href="doc.src">{{ doc.file_name }}</a
                                ><br/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <template v-if="organisation != null">
                            <template v-if="organisation.verified == 0">
                                <button disabled type="button" class="btn btn-round btn-info">
                                    Already Submitted
                                </button>
                            </template>
                            <template v-else-if="organisation.verified == 1">
                                <button
                                    v-if="onLine"
                                    v-bind:disabled="model.isDisabled"
                                    v-on:click="validateAndSubmit"
                                    type="button"
                                    class="btn btn-round btn-info"
                                >
                                    Save Changes
                                </button>
                                <button
                                    v-else
                                    v-bind:disabled="true"
                                    v-on:click="validateAndSubmit"
                                    type="button"
                                    class="btn btn-round btn-info"
                                >
                                    Save Changes
                                </button>
                            </template>
                            <template v-else-if="organisation.verified == 2">
                                <button
                                    v-if="onLine"
                                    v-bind:disabled="model.isDisabled"
                                    v-on:click="validateAndSubmit"
                                    type="button"
                                    class="btn btn-round btn-info"
                                >
                                    Submit
                                </button>
                                <button
                                    v-else
                                    v-bind:disabled="true"
                                    v-on:click="validateAndSubmit"
                                    type="button"
                                    class="btn btn-round btn-info"
                                >
                                    Submit
                                </button>
                            </template>
                        </template>
                        <template v-else>
                            <button
                                v-if="onLine"
                                v-bind:disabled="model.isDisabled"
                                v-on:click="validateAndSubmit"
                                type="button"
                                class="btn btn-round btn-info"
                            >
                                Submit
                            </button>
                            <button
                                v-else
                                v-bind:disabled="true"
                                v-on:click="validateAndSubmit"
                                type="button"
                                class="btn btn-round btn-info"
                            >
                                Submit
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h5>Transfer Organisation</h5>
                        <hr>
                        <p>Transfer the ownership of your organisation to some other user. This will also transfer all
                            the projects and related data. Note: You will loose access to this organisation after
                            transferring it.</p>
                    </div>
                    <div class="col-12 text-right">
                        <button type="button" class="btn btn-round btn-info btn-danger" @click="transferOrgOwner">
                            Transfer Organisation
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <help-modal ref="helpModal"></help-modal>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Swal from 'sweetalert2';
import mixin from "../../mixins/GlobalMixin";
import VOffline from "v-offline";
import ImageUpload from "../ImageUpload.vue";

export default {
    components: {
        "image-upload": ImageUpload,
        VOffline,
    },
    data: function () {
        return {
            model: {
                orgName: "",
                orgWebsite: "",
                orgAbout: "",
                orgAddress: "",
                orgCountry: "",
                orgState: "",
                orgCity: "",
                orgZipCode: "",
                orgContact: "",
                orgEmail: "",
                isDisabled: false,
                inputDisabled: false,
                nameDisabled: false,
            },
            modelValidations: {
                orgName: {
                    required: true,
                },
                orgAbout: {
                    required: true,
                },
                orgWebsite: {
                    url: true,
                },
                orgZipCode: {
                    numeric: true,
                },
                orgContact: {
                    numeric: true,
                    min: 10,
                    max: 15,
                },
                orgEmail: {
                    required: true,
                    email: true,
                },
            },
            imageUploadApiUrl:
                window.API_BASE_URL + "/UploadToS3/upload/organisation_logo",
            uploadURL: "",
            uploadingLabel: "None",
            uploadPending: false,
            uploadNew: true,

            file: null,
            attachments: {
                documents: [],
            },
            isUploadingDocument: false,
            organisationData: "",
            organisationId: "",
        };
    },
    mixins: [mixin],
    methods: {
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
        submitFile() {
            if (this.file == null) {
                alert("Please select file");
                return;
            }
            let vm = this;
            vm.isUploadingDocument = true;
            let formData = new FormData();
            formData.append("document", this.file);
            axios
                .post("/UploadToS3/upload/upload_organisation_document", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    vm.isUploadingDocument = false;
                    if (response.data.response.error.error_code == 0) {
                        vm.file = null;
                        vm.attachments.documents.push(response.data.response.data);
                        vm.showAlert("DONE", response.data.response.error.msg, true);
                    }
                })
                .catch((error) => {
                    vm.isUploadingDocument = false;
                    console.log(error);
                });
        },
        getOrganisation() {
            let vm = this;
            this.$store.dispatch("user/getOrganisation").then(() => {
                vm.setModelFromOrganisation();
            });
        },
        load() {
            let vm = this;
            vm.$store.dispatch("user/getOrganisation").then(() => {
                vm.setModelFromOrganisation();
            });

            Bus.$on("example-did-upload", (error, data, response) => {
                if (error) {
                    toastr.error(error);
                } else {
                    vm.uploadURL = response.src;
                    vm.uploadingLabel = "Uploaded";
                    vm.uploadPending = false;
                }
            });

            Bus.$on("example-is-saving", () => {
                vm.uploadPending = true;
                vm.uploadingLabel = "Uploading Image...";
            });
        },
        validateAndSubmit: function () {
            let vm = this;

            if (vm.uploadPending) {
                vm.showAlert("Alert", "Please wait image is being uploaded", false);
                return;
            }

            if (vm.isUploadingDocument) {
                vm.showAlert("Alert", "Please wait document is being uploaded", false);
                return;
            }

            if (
                vm.model.orgName == "" ||
                vm.model.orgAbout == "" ||
                vm.model.orgEmail == ""
            ) {
                vm.showAlert(
                    "Alert",
                    "Organisation Name, About and Email fields are required",
                    false
                );
                return;
            }

            vm.$validator.validateAll().then((result) => {
                if (result) {
                    vm.model.isDisabled = true;
                    axios
                        .post("/organisation/create", {
                            name: vm.model.orgName,
                            about: vm.model.orgAbout,
                            address: vm.model.orgAddress,
                            country: vm.model.orgCountry,
                            state: vm.model.orgState,
                            city: vm.model.orgCity,
                            zip_code: vm.model.orgZipCode,
                            website: vm.model.orgWebsite,
                            email: vm.model.orgEmail,
                            contact: vm.model.orgContact,
                            logo_url:
                                vm.uploadURL != ""
                                    ? vm.uploadURL
                                    : "https://hsi-demo.s3.amazonaws.com/organisation_logos/hsapps_org_placeholder.png",
                            attachments: JSON.stringify(vm.attachments.documents),
                        })
                        .then((response) => {
                            if (response.data.response.error.error_code == 0) {
                                vm.getUsersDetails();
                                vm.showAlert("Success", response.data.response.error.msg, true);
                                vm.getOrganisation();
                            }
                            vm.model.isDisabled = false;
                        })
                        .catch((error) => {
                            if (
                                error.response.status === 400 ||
                                error.response.status === 422
                            ) {
                                let errors = [];
                                if (error.response.data.response.data !== null) {
                                    for (let key in error.response.data.response.data) {
                                        console.log("Key: " + key);
                                        console.log(
                                            "Value: " + error.response.data.response.data[key]
                                        );
                                        errors.push(error.response.data.response.data[key]);
                                    }
                                    let errorMsg = errors.toString();
                                    vm.showAlert("Error", errorMsg, false);
                                } else {
                                    let errorMsg = error.response.data.response.error.error_msg;
                                    console.log(vm.errorMsg);
                                    vm.showAlert("Error", errorMsg, false);
                                }
                            } else {
                                console.log(error);
                            }
                            vm.model.isDisabled = false;
                        });
                }
            });
        },
        setModelFromOrganisation() {
            if (this.organisation != null) {
                if (this.organisation.logo_url != "") {
                    this.uploadNew = false;
                }
                if (this.organisation.verified != 1) {
                    if (this.organisation.verified == 2) {
                        this.model.inputDisabled = false;
                        this.model.nameDisabled = false;
                        this.$validator.reset();
                    } else {
                        this.model.inputDisabled = true;
                        this.model.nameDisabled = true;
                        this.$validator.reset();
                    }
                } else {
                    this.model.nameDisabled = true;
                }
                this.model.orgName = this.organisation.name;
                this.model.orgAbout = this.organisation.about;
                this.model.orgAddress = this.organisation.address;
                this.model.orgCountry = this.organisation.country;
                this.model.orgState = this.organisation.state;
                this.model.orgCity = this.organisation.city;
                this.model.orgZipCode = this.organisation.zip_code;
                this.model.orgWebsite = this.organisation.website;
                this.model.orgEmail = this.organisation.email;
                this.model.orgContact = this.organisation.contact;

                if (this.organisation.attachments != null) {
                    this.attachments.documents = JSON.parse(
                        this.organisation.attachments
                    );
                } else {
                    this.attachments.documents = [];
                }
            } else {
                console.log("Organisation is null");
            }
        },
        toggleUploadNew() {
            this.uploadNew = !this.uploadNew;
        },
        getUsersDetails() {
            var vm = this;
            axios
                .get("/user")
                .then((response) => {
                    if (response.data.response.error.error_code == 0) {
                        this.organisationData = response.data.response.data.organisation;
                        var orgId = this.organisationData.id;
                        vm.organisationId = orgId;
                    }
                })
                .catch((error) => {
                });
        },
        showUrl() {
            this.$refs.helpModal.loadUrl(window.USER_MANUAL_URL + "#h.bswdx03ab2pw");
        },
        transferOrgOwner() {
            const vm = this;
            new Swal({
                icon: 'warning',
                title: 'Transfer Organisation Ownership',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-danger btn-fill',
                cancelButtonClass: 'btn btn-success btn-fill',
                confirmButtonText: 'Transfer',
                buttonsStyling: false,
                html:
                    `<p>Are you sure you want to transfer ownership of this organisation? You cannot undo this.</p>
                <input id="swal-input1" class="swal2-input" placeholder="Type organisation name">
                <input id="swal-input2" class="swal2-input" placeholder="New owner email">`,
                preConfirm: function () {
                    return new Promise(function (resolve) {
                        const orgName = $('#swal-input1').val();
                        const newOwnerEmail = $('#swal-input2').val();

                        if (orgName !== vm.model.orgName) {
                            Swal.showValidationMessage("Enter a valid organisation name");
                        } else if (newOwnerEmail == '') {
                            Swal.showValidationMessage("Enter valid email");
                        } else {
                            Swal.resetValidationMessage();
                            resolve([orgName, newOwnerEmail]);
                        }
                        Swal.getConfirmButton().removeAttribute('disabled');
                        Swal.getCancelButton().removeAttribute('disabled');
                    })
                },
                onOpen: function () {
                    $('#swal-input1').focus()
                }
            }).then(function (result) {
                if (result.value) {
                    const [orgName, email] = result.value;
                    const loading = vm.$loading({
                        lock: true,
                        text: 'Please wait transfering the ownership...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    const payload = {
                        organisation_name: orgName,
                        new_owner_email: email
                    };
                    axios.post(`/organisation/transfer/ownership`, payload).then(async (response) => {
                        loading.close();
                        if (response.data.response.error.error_code == 0) {
                            vm.showAlert('Success', response.data.response.error.msg, true);
                            await this.$store.dispatch('LogOut')
                            this.$router.push('/login')
                        } else {
                            vm.showAlert('Error', error.response.error.error_msg, false);
                        }
                    }).catch(error => {
                        loading.close();
                        vm.showAlert('Error', error.response.error.error_msg, false);
                    })

                }
            });
        }
    },
    computed: {
        ...mapGetters("user", ["organisation"]),
        getUploadStatus: function () {
            if (this.isUploadingDocument) {
                return "Please Wait...";
            } else {
                return "Upload";
            }
        },
        userOwnsAnOrganisation() {
            return this.organisation != null;
        },
    },
    mounted() {
        this.getUsersDetails();
        this.load();
    },
};
</script>

<style>
.org-title-row {
    padding-left: 40px;
    padding-top: 10px;
}

@media (max-width: 767px) {
    .org-title-row {
        padding-left: 0;
        padding-top: 15px;
    }
}
</style>
