<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ibox ">
                                <div class="ibox-content">
                                    <div class="panel-heading">
                                        <h2 class="set_font_title" v-if="roles.length == 0">Add Catch-Release Area
                                            <a class="btn-rotate help-icon" style="cursor:pointer" @click="showUrl()">
                                                <i class="fa fa-question-circle" aria-hidden="true"></i>
                                            </a>
                                        </h2>
                                        <h2 class="set_font_title" v-else>Catch-Release Area
                                            <a class="btn-rotate help-icon" style="cursor:pointer" @click="showUrl()">
                                                <i class="fa fa-question-circle" aria-hidden="true"></i>
                                            </a>
                                        </h2>
                                        <p></p>
                                    </div>
                                    <div v-if="!isReadOnly" class="card setShadow" style="padding: 10px;">
                                        <div class="row">
                                            <div class="panel-options col-md-8">
                                                <p>
                                                    <!--Enter a search term or zoom/position the map to a particular area, then draw a polygon.-->
                                                    Catch-Release Areas are “geofences” which are virtual fences that
                                                    limit the areas a field team can operate in. Catching Areas (or
                                                    geofences) are defined by a polygon which is drawn on the map below
                                                    or you can upload a geofence using the KML file format. To draw a
                                                    polygon click on the polygon icon and when it is completed (last
                                                    points connected) a prompt will appear to SAVE.
                                                    Optionally use the Search by location or move the map around to
                                                    change the map location.
                                                    <br>
                                                    To add more Catch-Release areas, Click on the polygon icon again.
                                                    <span><img width="25"
                                                               src="@/assets/images/polygon-icon.png"/></span>
                                                    <br>
                                                </p>
                                            </div>
                                            <div class="col col-md-4 text-right up_kml_btn">
                                                <button v-if="!isReadOnly" v-bind:disabled="!onLine"
                                                        @click="uploadVaccinationArea" class="btn btn-warning"
                                                        type="button">
                                                    UPLOAD GEOFENCE (KML)
                                                </button>
                                            </div>
                                            <div class="col col-sm-12">
                                                <el-checkbox type="checkbox" v-model="isShowingKmlLayers"
                                                             @change="toggleKMLlayers">Hide existing geofences
                                                </el-checkbox>
                                                <br>
                                                <br>
                                            </div>
                                            <br>
                                            <br>
                                        </div>
                                        <div class="panel-body set_map">
                                            <form @submit.prevent="submit" class="form-horizontal">
                                                <input type="hidden" name="no_of_fence" id="no_of_fence" value="">
                                                <div class="pac-card" id="pac-card">
                                                    <div>
                                                    </div>
                                                    <div id="pac-container">
                                                        <input id="pac-input" type="text"
                                                               placeholder="Search by location">
                                                    </div>
                                                </div>
                                                <div id="map"></div>
                                                <!-- <div id="vaccination-areas" style="padding: 5px;">
                                                    <input type="hidden" value="" name="kml_data_1" id="kml_data_1">
                                                </div>
                                                <div v-if="fenceCount>0" class="form-group" id="vaccination-areas-btn">
                                                    <div class="col-sm-12 text-right">
                                                        <button class="btn btn-white" type="button"
                                                                @click="goBack">Cancel
                                                        </button>
                                                        <button v-if="onLine" type="submit"
                                                                :disabled="isUploadingKMLData" class="btn btn-primary">
                                                            Save
                                                        </button>
                                                        <button v-else v-bind:disabled="true" type="submit"
                                                                class="btn btn-primary">Save
                                                        </button>
                                                    </div>
                                                </div> -->
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row setMarginTop set_res_margin_top">
                                        <div class="col-xs-12 col-md-12 col-xl-12">
                                            <div class="row setTitleDiv" v-if="roles.length == 0">
                                                <p>Catch-Release Areas</p>
                                            </div>
                                            <div class="row setMarginTop set_res_margin_top">
                                                <div class="col-xs-6 col-sm-12 col-md-6 col-xl-3">
                                                    <span style="color: #4285f4;">Select Filter</span>
                                                    <div class="form-group">
                                                        <template>
                                                            <el-select v-model="selectFilter" filterable
                                                                       placeholder="All" class="el_select_width">
                                                                <el-option
                                                                    v-for="area in filterArrAreas"
                                                                    :key="area.id"
                                                                    :label="area.name"
                                                                    :value="area.key">
                                                                </el-option>
                                                            </el-select>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6 col-sm-12 col-md-6 col-xl-3" v-if="!inputshow">
                                                    <fg-input type="text" style="margin-top: 23px;"
                                                              :placeholder="placeholderVal" name="selectFilterVal"
                                                              v-model="selectFilterVal">
                                                    </fg-input>
                                                </div>
                                                <div class="col-xs-6 col-sm-12 col-md-6 col-xl-3"
                                                     v-if="!daterangPicker">
                                                    <span style="color: #4285f4;">Select Date Range</span>
                                                    <v-md-date-range-picker :presets="dateRangePresets"
                                                                            @change="handleChange"></v-md-date-range-picker>
                                                </div>
                                            </div>
                                            <br>
                                            <p>Note: An area is considered submitted/closed when all individual
                                                assignments are submitted</p>
                                            <div class="setMarginTop set_res_margin_top"
                                                 v-if="filteredVaccinationArea.length == 0" style="text-align: center;">
                                                <p class="no_rec_msg">No Records found</p>
                                            </div>
                                        </div>
                                        <div class="color-status-card" v-if="filteredVaccinationArea.length">
                                            <!--                                            <get-stats />-->
                                            <div class="row setMarginTop">
                                                <div class="col-xs-6 col-sm-12 col-md-4 col-xl-4 set_cursor"
                                                     v-for="item in filteredVaccinationArea" :key="item.id"
                                                     @click="areaDetail(item.id)">
                                                    <area-card
                                                        :areaId="item.id"
                                                        :animals-caught="item.catched_count"
                                                        :animals-sterilized="item.sterilized_count"
                                                        :cleared-for-release="item.cleared_for_release"
                                                        :animals-released="item.released_count"
                                                        :title="item.name"
                                                        :country="item.google_country"
                                                        :createdOn="item.created_at"
                                                        :areaState="item.google_admin_area_level1!='null'?item.google_admin_area_level1:'Not specified'"
                                                        :locality="item.google_locality!='null'?item.google_locality:'Not specified'"
                                                    ></area-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog width="50%" title="Enter Area Details" :visible.sync="saveDialogVisible">
            <form @submit.prevent="submit" class="form-horizontal">
                <input type="hidden" value="" :name="'kml_data_' + fenceCount" :id="'kml_data_' + fenceCount">
                <div class="row fence-fields" id="fence-fields-' + fenceCount + '">
                    <div class="col-12"><label>Area Name <span class="error">*</span></label><input
                            class="form-control settlement_name" value="" :name="'settlement_name' + fenceCount " required></div>
                    <div class="col-12"><label>Country <span class="error">*</span></label><input
                            :name="'country' + fenceCount" class="form-control"
                            :value="AreaCountry ? AreaCountry : ''" required>
                    </div>
                    <div class="col-12"><label>State/Province<span class="error">*</span></label><input
                            :name="'state' + fenceCount" class="form-control "
                            :value="StateProvince ? StateProvince : ''" required>
                    </div>
                    <div class="col-12"><label>City/District/Village <span class="error">*</span></label><input
                            :name="'locality' + fenceCount " class="form-control"
                            :value="CityDistrictVillage ? CityDistrictVillage : ''" required>
                    </div>
<!--                    <span class="col-12" :id="'fence-field-msg' + fenceCount ">Not Saved</span>-->
                </div>
                <div v-if="fenceCount>0" class="form-group" id="vaccination-areas-btn">
                    <div class="col-sm-12 text-right">
                        <button class="btn btn-white" type="button"
                                @click="onCancelDialog">Cancel
                        </button>
                        <button v-if="onLine" type="submit"
                                :disabled="isUploadingKMLData" class="btn btn-primary">
                            Save
                        </button>
                        <button v-else v-bind:disabled="true" type="submit"
                                class="btn btn-primary">Save
                        </button>
                    </div>
                </div>
            </form>
        </el-dialog>
        <help-modal ref="helpModal"></help-modal>
    </div>
</template>
<script>
import {API_KEY} from '../Maps/API_KEY';
// import GoogleMapsLoader from 'google-maps';
import { Loader } from '@googlemaps/js-api-loader';

// GoogleMapsLoader.KEY = API_KEY;
// GoogleMapsLoader.LIBRARIES = ['drawing', 'places'];

const loader = new Loader({
    apiKey: API_KEY,
    version: "weekly",
    libraries: ["drawing", "places"],
});

import {MAP_STYLE} from "../Maps/MAP_STYLE";

import mixin from '../../../mixins/GlobalMixin';
import dateRangeDefaultPresetsMixin from '../../../mixins/DateRangeDefaultPresetsMixin';
import AreaCard from "./Components/PmAreaCardView.vue";
import GetStats from '../../modules/GetStats.vue'
import {Select, Option, Dialog} from 'element-ui';
import VOffline from 'v-offline';
import {Checkbox} from 'element-ui';

export default {
    components: {
        "area-card": AreaCard,
        [Option.name]: Option,
        [Select.name]: Select,
        [Checkbox.name]: Checkbox,
        [Dialog.name]: Dialog,
        VOffline,
        GetStats,
    },
    props: {
        projectId: {
            type: Number,
        }
    },
    data() {
        return {
            isReadOnly: false,
            mapObj: null,
            kmlLayers: [],
            isShowingKmlLayers: false,
            areaId: '',
            title: '',
            createdOn: '',
            country: '',
            areaState: '',
            locality: '',
            fieldUsers: '',

            all_overlays: [],
            all_shapes: [],
            selectedShape: null,

            polygonstyles: [],
            selectedCountry: [],
            selectFilter: "",
            selectFilterVal: "",
            selectedDistrict: [],
            selectedState: [],
            placeholderVal: "",

            fenceCount: 0,

            project: null,

            isUploadingKMLData: false,

            uploadedCount: 0,
            area: [],
            filterArrAreas: [
                {id: 1, name: 'All', 'key': 'all'},
                {id: 2, name: 'Created On', 'key': 'created_at'},
                {id: 3, name: 'Modifed On', 'key': 'last_modified'},
                {id: 4, name: 'Country', 'key': 'country'},
                {id: 5, name: 'State/Province', 'key': 'google_admin_area_level1'},
                {id: 6, name: 'City/District/Village', 'key': 'google_locality'},
            ],
            stratDate: '',
            endDate: '',
            inputshow: true,
            daterangPicker: true,
            roles: [],
            latitude: '',
            longitude: '',
            CityDistrictVillage: '',
            StateProvince: '',
            AreaCountry: '',
            polygon: [],
            colorStatusWithText: [
                {color_code: 'grey', label: '0 Vaccination & 0 survery records'},
                {color_code: 'purple', label: '0 < vaccination & 0 survey records'},
                {color_code: 'yellow', label: '1 < vaccination & 1 < survey records'},
                {color_code: 'blue', label: ' Vaccination submitted & 0 survey records'},
                {color_code: 'pink', label: 'Vaccination submitted & 0 < survey records'},
                {color_code: 'red', label: 'Surveys submitted & survey ratio < target ratio'},
                {color_code: 'green', label: 'Surveys submitted & survey ratio is >= target ratio'},
            ],
            dateRangePresets: [],
            saveDialogVisible: false
        }
    },
    mixins: [mixin, dateRangeDefaultPresetsMixin],
    methods: {
        submit() {
            let vm = this;

            vm.isUploadingKMLData = true;

            vm.generate_kml_data();

            for (let i = 1; i <= vm.fenceCount; i++) {
                axios.post('/area/create', {
                    'kml_data': $('#kml_data_' + i).val(),
                    'project_id': vm.projectId,
                    'name': $('[name="settlement_name' + i + '"]').val(),
                    'country': $('[name="country' + i + '"]').val(),
                    'google_admin_area_level1': $('[name="state' + i + '"]').val(),
                    'google_locality': $('[name="locality' + i + '"]').val(),
                }).then(response => {
                    if (response.data.response.error.error_code == 0) {
                        //Success
                        $('[name="settlement_name' + i + '"]').val("")
                        this.showAlert('Success', response.data.response.error.msg, true);
                        //$('#fence-field-msg' + i).text(response.data.response.error.msg);
                        vm.uploadedCount++;
                        //$('#fence-fields-' + i).css('background-color', 'lime');
                        if (vm.uploadedCount == vm.fenceCount) {
                            vm.isUploadingKMLData = false;
                        }
                        //Get All area
                        this.getArea();
                        vm.saveDialogVisible = false;
                        vm.fenceCount = 0;
                    } else {
                        //Failure
                        //$('#fence-field-msg' + i).text(response.data.response.error.error_msg);
                        this.showAlert('Error', response.data.response.error.msg, false);
                    }
                }).catch(error => {
                    this.showAlert('Error', error.response.data.response.error.error_msg, false);
                    vm.isUploadingKMLData = false;
                    //$('#fence-field-msg' + i).text(error.response.data.response.error.error_msg);
                    //$('#fence-fields-' + i).css('color', 'red');
                });
            }

        },
        initMap(google) {
            let vm = this;

            let latLng = vm.project.project_location_lat_lng.split(',');
            const myLatlng = new google.maps.LatLng(latLng[0], latLng[1]);

            const map = new google.maps.Map(document.getElementById('map'), {
                center: myLatlng,
                zoom: 7,
                styles: MAP_STYLE,
                streetViewControl: false,
            });


            let card = document.getElementById('pac-card');
            let input = document.getElementById('pac-input');
            let types = document.getElementById('type-selector');
            let strictBounds = document.getElementById('strict-bounds-selector');
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

            let autocomplete = new google.maps.places.Autocomplete(input);

            // Bind the map's bounds (viewport) property to the autocomplete object,
            // so that the autocomplete requests use the current map bounds for the
            // bounds option in the request.
            autocomplete.bindTo('bounds', map);

            autocomplete.addListener('place_changed', function () {

                let place = autocomplete.getPlace();
                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);  // Why 17? Because it looks good.
                }

            });


            let drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [
                        google.maps.drawing.OverlayType.POLYGON,
                    ],
                },
                polygonOptions: {
                    clickable: true,
                    draggable: true,
                    strokeColor: '#9c27b0',
                    editable: true,
                    strokeOpacity: 1,
                    strokeWeight: 4,
                    fillOpacity: 0,
                },
            });


            function clearSelection() {
                if (vm.selectedShape) {
                    vm.selectedShape.setEditable(false);
                    vm.selectedShape = null;
                }
            }

            function setSelection(shape) {
                clearSelection();
                vm.selectedShape = shape;
                shape.setEditable(true);
                $("#vaccination-areas").show();
                for (let i = 1; i <= vm.fenceCount; i++) {
                    //alert(i);
                    $('#fence-fields-' + i).css('background-color', '');
                }
                //$('#fence-fields-' + shape.id).css('background-color', 'rgb(156, 39, 176,0.6)');
                // google.maps.event.addListener(selectedShape.getPath(), 'insert_at', getPolygonCoords(shape));
                // google.maps.event.addListener(selectedShape.getPath(), 'set_at', getPolygonCoords(shape));
            }



            function deleteAllShape() {
                for (let i = 0; i < vm.all_overlays.length; i++) {
                    vm.all_overlays[i].overlay.setMap(null);
                }
                vm.all_overlays = [];
            }

            function CenterControl(controlDiv, map) {

                // Set CSS for the control border.
                let controlUI = document.createElement('div');
                controlUI.style.backgroundColor = '#fff';
                controlUI.style.border = '2px solid #fff';
                controlUI.style.borderRadius = '3px';
                controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
                controlUI.style.cursor = 'pointer';
                controlUI.style.marginBottom = '22px';
                controlUI.style.textAlign = 'center';
                controlUI.title = 'Select to delete the shape';
                controlDiv.appendChild(controlUI);

                // Set CSS for the control interior.
                let controlText = document.createElement('div');
                controlText.style.color = 'rgb(25,25,25)';
                controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
                controlText.style.fontSize = '16px';
                controlText.style.lineHeight = '38px';
                controlText.style.paddingLeft = '5px';
                controlText.style.paddingRight = '5px';
                controlText.innerHTML = 'Delete Selected Area';
                controlUI.appendChild(controlText);

                // Setup the click event listeners: simply set the map to Chicago.
                controlUI.addEventListener('click', function () {
                    vm.deleteSelectedShape();
                });

            }

            drawingManager.setMap(map);

            google.maps.event.addListener(drawingManager, 'polygoncomplete', function (event) {
                //alert("poly complete");
                //event.getPath().getLength();
                google.maps.event.addListener(event.getPath(), 'insert_at', function () {
                    //alert("insert_at");
                    let len = event.getPath().getLength();
                    for (let i = 0; i < len; i++) {
                        //alert(event.getPath().getAt(i).toUrlValue(5));
                    }
                });
                google.maps.event.addListener(event.getPath(), 'set_at', function () {
                    //alert("set_at");
                    let len = event.getPath().getLength();
                    for (let i = 0; i < len; i++) {

                    }
                });
            });

            google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
                if (event.type == google.maps.drawing.OverlayType.POLYGON) {
                    //    vm.latitude = event.overlay.getPath().getArray()[0].lat();
                    //    vm.longitude = event.overlay.getPath().getArray()[0].lng();
                    event.overlay.getPath().getArray().forEach(function (position) {
                        var obj = {};
                        obj["x"] = position.lat();
                        obj["y"] = position.lng();
                        vm.polygon.push(obj);
                    });

                    Region.prototype.area = function () {
                        var area = 0,
                            i,
                            j,
                            point1,
                            point2;

                        for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
                            point1 = this.points[i];
                            point2 = this.points[j];
                            area += point1.x * point2.y;
                            area -= point1.y * point2.x;
                        }
                        area /= 2;

                        return area;
                    };

                    Region.prototype.centroid = function () {
                        var x = 0,
                            y = 0,
                            i,
                            j,
                            f,
                            point1,
                            point2;

                        for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
                            point1 = this.points[i];
                            point2 = this.points[j];
                            f = point1.x * point2.y - point2.x * point1.y;
                            x += (point1.x + point2.x) * f;
                            y += (point1.y + point2.y) * f;
                        }

                        f = this.area() * 6;

                        return new Point(x / f, y / f);
                    };
                    var region = new Region(vm.polygon);
                    vm.latitude = region.centroid().x;
                    vm.longitude = region.centroid().y;
                    // console.log(vm.latitude);
                    // console.log(vm.longitude);
                    // console.log(region.centroid());
                }
                //alert("overlaycomplete");
                vm.fenceCount = vm.fenceCount + 1;
                $("#no_of_fence").val(vm.fenceCount);
                vm.appendNewInputFields();
                vm.all_overlays.push(event);

                if (event.type !== google.maps.drawing.OverlayType.MARKER) {
                    drawingManager.setDrawingMode(null);
                    //Write code to select the newly selected object.

                    let newShape = event.overlay;
                    newShape.type = event.type;
                    newShape.id = vm.fenceCount;
                    vm.all_shapes[vm.fenceCount] = newShape;
                    google.maps.event.addListener(newShape, 'click', function () {
                        setSelection(newShape);
                    });

                    setSelection(newShape);
                }
            });

            function Point(x, y) {
                this.x = x;
                this.y = y;
            }

            function Region(points) {
                this.points = points || [];
                this.length = points.length;
            }

            let centerControlDiv = document.createElement('div');
            let centerControl = new CenterControl(centerControlDiv, map);

            centerControlDiv.index = 1;
            map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);


            vm.createpolygonstyleobject();

            $("#pac-input").focus();

            vm.getAllKmls(map)

        },
        deleteSelectedShape() {
            const vm = this;
            if (vm.selectedShape) {
                //if (vm.selectedShape.id > 1) {
                $('#fence-fields-' + vm.selectedShape.id).remove();
                //}
                vm.fenceCount = vm.fenceCount - 1;
                vm.selectedShape.setMap(null);
            }
        },
        appendNewInputFields() {
            let vm = this;
            var geocoder = new google.maps.Geocoder;
            var latlng = {lat: parseFloat(vm.latitude), lng: parseFloat(vm.longitude)};
            geocoder.geocode({'location': latlng}, function (results, status) {
                if (status === 'OK') {
                    if (results[0]) {
                        var address_components = results[0].address_components;
                        for (var i = 1; i < address_components.length; i++) {
                            var singleAdd = address_components[i];
                            var type = singleAdd.types[0];
                            if (type == "locality") {
                                vm.CityDistrictVillage = singleAdd.long_name;
                            }
                            if (type == "administrative_area_level_1") {
                                vm.StateProvince = singleAdd.long_name;
                            }
                            if (type == "country") {
                                vm.AreaCountry = singleAdd.long_name;
                            }
                        }
                        vm.saveDialogVisible = true;
                    }
                }
            });
            //if (vm.fenceCount > 1) {

            //fillCountry();
            //}
        },
        getInputFields() {
            let vm = this;
            vm.isUploadingKMLData = false;
            return '<input type="hidden" value="" name="kml_data_' + vm.fenceCount + '" id="kml_data_' + vm.fenceCount + '">' +
                '<div class="row fence-fields" id="fence-fields-' + vm.fenceCount + '">'
                + '<div class="col-sm-12 col-md-3"><label>Catching Area Name <span class="error">*</span></label><input class="form-control settlement_name" value="" name="settlement_name' + vm.fenceCount + '" required></div>'
                + '<div class="col-sm-12 col-md-3"><label>Country <span class="error">*</span></label><input name="country' + vm.fenceCount + '" class="form-control" value="' + (vm.AreaCountry ? vm.AreaCountry : '') + '" required>'
                + '</div>'
                + '<div class="col-sm-12 col-md-3"><label>State/Province<span class="error">*</span></label><input name="state' + vm.fenceCount + '" class="form-control " value="' + (vm.StateProvince ? vm.StateProvince : '') + '" required>'
                + '</div>'
                + '<div class="col-sm-12 col-md-3"><label>City/District/Village <span class="error">*</span></label><input name="locality' + vm.fenceCount + '" class="form-control" value="' + (vm.CityDistrictVillage ? vm.CityDistrictVillage : '') + '" required>'
                + '</div>'
                // + '<span class="col-sm-12" id="fence-field-msg' + vm.fenceCount + '">Not Saved</span>';

        },
        generate_kml_data() {
            let vm = this;
            for (let i = 1; i <= vm.fenceCount; i++) {
                vm.getPolygonCoords(vm.all_shapes[i]);
            }
        },
        getPolygonCoords(newShape) {
            let vm = this;
            let len = newShape.getPath().getLength();
            for (let i = 0; i < len; i++) {
                let latLng = newShape.getPath().getAt(i).toUrlValue(6).split(',');
            }
            vm.logCode2(newShape);
        },
        logCode2(newShape) {
            let vm = this;
            let code = "";
            let kmltext1 = '<Placemark><name>' + 'name of geofence' + '</name>\n' +
                '<description>' + 'Description' + '</description>\n' +
                '<styleUrl>#' + 'poly-01579B-6-0-nodesc' + '</styleUrl>\n' +
                '<Polygon>\n<tessellate>' + '1' + '</tessellate>\n' +
                '<outerBoundaryIs><LinearRing><coordinates>\n';

            let len = newShape.getPath().getLength();
            for (let i = 0; i < len; i++) {
                let latLng = newShape.getPath().getAt(i).toUrlValue(6).split(',');
                code += latLng[1] + ',' + latLng[0] + ',0.0 \n';
            }

            let kmltext2 = '</coordinates></LinearRing></outerBoundaryIs>\n</Polygon>\n</Placemark>\n';

            let generatedKml = vm.kmlheading() + kmltext1 + code + kmltext2 + vm.kmlend();


            document.getElementById("kml_data_" + newShape.id).value = generatedKml;

            //alert(generatedKml);
        },
        kmlheading() {
            let vm = this;
            let heading = "";
            let styleforpolygon = "";
            let styleforrectangle = "";
            let styleforpolyline = "";
            let styleformarker = "";
            let i;
            heading = '<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n' +
                '<kml xmlns="http://www.opengis.net/kml/2.2">\n' +
                '<Document><name>' + 'Untitled layer' + '</name>\n' +
                '<description>' + 'layer description' + '</description>\n';

            for (i = 0; i < vm.selectedShape.getPath().getLength(); i++) {
                styleforpolygon += '<Style id="' + vm.polygonstyles[0].name + '">\n' +
                    '<LineStyle><color>' + vm.polygonstyles[0].kmlcolor + '</color><width>' + vm.polygonstyles[0].width + '</width></LineStyle>\n' +
                    '<PolyStyle><color>' + vm.polygonstyles[0].kmlfill + '</color></PolyStyle>\n' +
                    '</Style>\n';
            }
            return heading + styleforpolygon;
        },
        kmlend() {
            let ending;
            return ending = '</Document>\n</kml>';
        },
        createpolygonstyleobject() {
            let vm = this;
            let polygonstyle = new vm.Polystyle();
            vm.polygonstyles.push(polygonstyle);
        },
        Polystyle() {
            this.name = "Lump";
            this.kmlcolor = "CD0000FF";
            this.kmlfill = "9AFF0000";
            this.color = "#FF0000";
            this.fill = "#0000FF";
            this.width = 2;
            this.lineopac = 0.8;
            this.fillopac = 0.6;
        },
        uploadVaccinationArea() {
            this.$router.push({name: 'uploadAreaKmlPm', params: {projectId: this.projectId}})
            //window.location = '/project/vaccination_area/upload/' + this.projectId;
        },
        FilterAreas() {
            //console.log("filter click");
        },
        handleChange(values) {
            var sDate = this.formatDate(values[0]._d);
            var eDate = this.formatDate(values[1]._d);
            this.stratDate = sDate;
            this.endDate = eDate;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        getArea() {
            let vm = this;
            axios.get("/area/pm?project_id=" + this.projectId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.area = response.data.response.data;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        areaDetail(area_id) {
            Bus.$emit("area_id", area_id);
            //this.projectId = area_id;
            //window.location = '/project/area_details/' + this.projectId;
            this.$router.push({name: 'viewAreaDetailsPm', params: {projectId: this.projectId, areaId: area_id}})
        },
        setMapCss() {
            var checkScreenWidth = window.innerWidth;
            if (checkScreenWidth < 426) {
                setTimeout(function () {
                    document.querySelectorAll('.gm-style-mtc').forEach(function (icon) {
                        icon.style.setProperty("top", "50px", "important");
                    });
                    document.querySelectorAll('div[title*=drawing]')[0].offsetParent.style.top = '100px';
                }, 1500);
            }
        },
        showUrl() {
            this.$refs.helpModal.loadUrl(window.USER_MANUAL_URL + "#h.z5cvb0kvk9mx");
        },
        /*getStatusColorCode()
        {
            axios.get('/area/status/color_codes').then(response => {
                this.colorStatusWithText = response.data.response.data;
                console.log('response', response.data.response);
            }).catch(err => {
            });
        },*/
        getAllKmls(mapRef) {

            let vm = this;

            vm.mapObj = mapRef;

            axios.get('/area/all/kmls/' + this.projectId).then(response => {
                console.log('response', response.data.response);
                let areaKmls = response.data.response.data.area_kmls;

                for (let j = 0; j < areaKmls.length; j++) {
                    var kmlUrl = areaKmls[j];
                    var layer = new google.maps.KmlLayer({
                        url: kmlUrl,
                        map: vm.mapObj,
                        suppressInfoWindows: true,
                        preserveViewport: true,
                    });

                    vm.kmlLayers.push(layer)
                }


            }).catch(err => {
                console.log(err);
            });
        },
        onCancelDialog() {
            const vm = this;
            vm.deleteSelectedShape();
            vm.saveDialogVisible = false;
        },
        toggleKMLlayers() {
            let vm = this;
            for (let j = 0; j < vm.kmlLayers.length; j++) {
                if (vm.isShowingKmlLayers) {
                    vm.kmlLayers[j].setMap(null)
                } else {
                    vm.kmlLayers[j].setMap(vm.mapObj)
                }
            }
        }
    },
    computed: {
        filteredVaccinationArea: function () {
            if (this.area.length == 0) {
                return [];
            }
            var type = this.selectFilter;
            if (type == "country" || type == "google_admin_area_level1" || type == "google_locality") {
                this.daterangPicker = true;
                this.inputshow = false;
                if (type == "country") {
                    this.placeholderVal = "Enter Country";
                } else if (type == "google_admin_area_level1") {
                    this.placeholderVal = "Enter State/Province";
                } else if (type == "google_locality") {
                    this.placeholderVal = "Enter City/District/Village";
                }
                if (this.selectFilter && this.selectFilterVal) {
                    return this.area.filter(item => {
                        if (item[type] != null) {
                            return (
                                item[type].toLowerCase().indexOf(this.selectFilterVal.toLowerCase()) > -1
                            );
                        }
                    });
                } else {
                    return this.area.filter(item => {
                        return item
                    });
                }
            } else if (type == "last_modified" || type == "created_at") {
                this.inputshow = true;
                this.daterangPicker = false;
                try {
                    setTimeout(function () {
                        document.getElementsByClassName("bar")[0].nextElementSibling.textContent = '';
                    }, 50);
                } catch (e) {
                }
                if (this.selectFilter && this.stratDate && this.endDate) {
                    return this.area.filter(item => {
                        return (
                            new Date(this.formatDate(item[type])) >= new Date(this.stratDate) && new Date(this.formatDate(item[type])) <= new Date(this.endDate)
                        );
                    });
                } else {
                    return this.area.filter(item => {
                        return item
                    });
                }
            } else {
                this.daterangPicker = true;
                this.inputshow = true;
                return this.area.filter(item => {
                    return item
                });
            }
        }
    },
    mounted() {
        this.getArea();
        let vm = this;
        vm.$store.dispatch("setScreenName", "Areas");
        vm.project = vm.$store.getters.getCurrentSelectedProject;

        vm.roles = vm.project.roles ? vm.project.roles : [];

        vm.isReadOnly = false;
        vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
            if (r.role_name === 'VIEWER') {
                vm.isReadOnly = true;
            }
        });

        const loader = new Loader({
            apiKey: API_KEY,
            version: "weekly",
            libraries: ["drawing", "places"],
        });

        loader.load().then(google => {
            vm.initMap(google);
        });

        vm.setMapCss();
        this.dateRangePresets = this.getDateRangePresets();
    }
}

</script>
<style scoped>

.help-icon {
    cursor: pointer;
    position: absolute;
    margin-left: 5px;
    margin-top: -15px;
    color: #51cbce !important;;
}

#map {
    height: 500px;
    width: 100%;
    margin-bottom: 10px;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
}

#pac-container {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls label {
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

.setTitleDiv p {
    font-size: 17px;
    font-weight: 600;
    color: black;
}

.el_select_width {
    width: 100% !important;
}

.btn-color {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.text-field {
    width: 75% !important;
}

.no_rec_msg {
    font-size: 20px;
    height: 220px;
}

.set_font_title {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin-left: -12px !important;
}

.set_map {
    margin-top: -17px;
    margin-bottom: -10px;
}

.up_kml_btn {
    margin-top: -10px;
}

.fence-fields {
    padding-bottom: 0px !important;
    padding-top: 5px;
}

.set_cursor {
    cursor: pointer;
}

.color-status-card {
    padding: 5px 15px;
    width: 100%;
}

.color-status-box {
    margin: 5px;
    float: left;
    height: 15px;
    width: 15px;
    border-radius: 30px;
}

/* .card .card-body {
    padding: 15px 0px 10px;
} */
.color-status-item {
    width: 25%;
}

@media (max-width: 1366px) {
    .color-status-item {
        width: 33.33%
    }
}

@media (max-width: 768px) {
    .color-status-item {
        width: 50%
    }
}

@media (min-width: 768px)and (max-width: 1025px) {
    #pac-input {
        width: 275px;
    }
}

@media (max-width: 513px) {
    .color-status-item {
        width: 100%
    }
}

@media (min-width: 377px)and (max-width: 425px) {
    #pac-input {
        width: 330px;
    }
}

@media (min-width: 374px)and (max-width: 376px) {
    #pac-input {
        width: 281px;
    }
}

@media (min-width: 320px)and (max-width: 373px) {
    #pac-input {
        width: 225px;
    }
}
</style>
