<template>
    <div>
        <!-- <div class="navbar navbar-expand-lg navbar-transparent nav_link_list setWhite desktopnavbar boxshadowsetheader responsive_menu_item"
             id="scrolldiv">
            <div class="col-md-6">
                <ul class="navbar-nav" style="margin-left: 16px;">
                    <li class="nav-item">
                        <div class="hoverclick">
                            <router-link to="projects" class="nav-link">
                                Projects
                            </router-link>
                        </div>
                    </li>
                    <li class="nav-item">
                        <router-link to="fieldteams" class="nav-link nav-link-field-users">
                            Field Teams
                        </router-link>
                    </li>
                </ul>
            </div>
        </div> -->

        <div class="card" v-if="userOrganisations.length && getOrgApiCallFinished">

            <div class="card-body">

                <h5>
                    Manage Field Teams
                    <a class="btn-rotate help-icon" @click="showUrl()">
                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </a>
                </h5>

                <hr/>


                <div class="row">
                    <div class="col-12">
                        <p>Setup groups of field users for your Organisation so that you can re-deploy them in multiple
                            different projects.</p>
                    </div>
                </div>


                <div>
                    <!-- <div class="col-md-1 col-sm-1"></div> -->
                    <div class="col-md-10 col-sm-10" v-if="teams.length == 0 && getTeamsApiCallFinished">
                        <template>
                            <p style="text-align: -webkit-center">
                                You don't have any Field Team. Start creating one by
                                clicking
                                <b
                                ><span class="text-primary"
                                >+ NEW FIELD TEAM</span
                                ></b
                                >
                                Button.
                            </p>
                        </template>
                    </div>
                    <template v-else>

                        <div class="m-4 s-12 mobile-m-top-only">
                            <div class="row">
                                <div class="col-md-3 col-sm-12">
                                    <label>Select Organisation</label>
                                    <br>
                                    <el-select class="select-info"
                                               style="width: 100%"
                                               size="large"
                                               placeholder="Select Organisation"
                                               @change="handleChange"
                                               v-model="selectedOrganisation">
                                        <el-option v-for="org in userOrganisations"
                                                   class="select-danger"
                                                   :value="org.organisation.id"
                                                   :label="org.organisation.name"
                                                   :key="org.organisation.id">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="col-md-3 col-sm-12" style="margin-bottom: 10px;">
                                    <label>Select Team</label>
                                    <br>
                                    <el-select
                                        id="feildTeams"
                                        v-bind:disabled="isDisabled"
                                        @change="getTeamUsers"
                                        v-model="selectedTeam"
                                        placeholder="Field Teams"
                                        style="width: 100%">
                                        <el-option
                                            v-for="(team, key) in teams"
                                            :key="key"
                                            :label="team.name"
                                            :value="team.id">

                                        </el-option>
                                    </el-select>

                                    <router-link to="inviteft?view=add-field-team" class="nav-link" style="padding: 0;">
                                    <span
                                        type="button"
                                        class="btn btn-round btn-success">
                                        + Add Field Team
                                    </span>
                                    </router-link>

                                    <a href="#" v-on:click="deleteSelectedFieldTeam()"
                                       class="btn btn-round btn-danger">Delete Selected Team</a>

                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <p>Select users to ADD or unselect to REMOVE them from field team</p>

                                    <custom-transfer
                                        v-model="transferValue"
                                        :data="allUsersFormatted"
                                        :selectedDataIds="assignedUsers"
                                        :showSearchBar="true"
                                        title="Select All"
                                        class="custom-transfer col-12 mobile-m-top-only"
                                    />
                                    <br>

                                    <div class="row text-center">

                                        <div class="col-6">
                                            <router-link to="inviteft?view=add-team-user" class="nav-link"
                                                         style="padding: 0;">
                                            <span
                                                type="button"
                                                class="btn btn-round btn-success">
                                                + Add Field User
                                            </span>
                                            </router-link>
                                        </div>

                                        <div class="col-6">
                                            <button
                                                v-bind:disabled="isDisabled"
                                                v-on:click="saveAssignedUsers"
                                                type="button"
                                                class="btn btn-round btn-default">
                                                Save Changes
                                            </button>
                                        </div>


                                    </div>


                                </div>

                            </div>
                        </div>


                    </template>
                    <!-- <div class="col-md-1 col-sm-1"></div> -->
                </div>

            </div>

        </div>

        <div v-else class="m-5 text-center">
            <h5 v-if="getOrgApiCallFinished"> You don't own any Organisation and You are not a member of any
                Organisation. </h5>
        </div>


        <help-modal ref="helpModal"></help-modal>
    </div>
</template>

<script>
import mixin from "../../mixins/GlobalMixin";
import VOffline from "v-offline";
import {Transfer, Select, Option} from "element-ui";
import CustomTransfer from '../Template/Components/CustomMultiselect.vue'
import {mapGetters} from 'vuex'
import Swal from "sweetalert2"

export default {
    components: {
        [Transfer.name]: Transfer,
        [Select.name]: Select,
        [Option.name]: Option,
        VOffline,
        CustomTransfer,
    },

    data() {
        const generateData = _ => {
            const allUsers = [];
            for (let i = 1; i <= 15; i++) {
                allUsers.push({
                    key: i,
                    label: `Option ${i}`,
                    disabled: 0
                });
            }
            return data;
        };
        return {
            allUsers: [],
            unassignedUsers: [],
            assignedUsers: [],
            selectedTeam: "",
            teams: [],
            isDisabled: false,
            organisationData: [],
            isModal: false,
            transferValue: [],
            userOnTeam: [],
            remainingUsers: [],
            allUsersFormatted: [],
            selectedOrganisation: '',
            //isOrgDeleted:false
            getOrgApiCallFinished: false,
            getTeamsApiCallFinished: false,
        };
    },
    props: {
        organisation: String
    },
    mixins: [mixin],
    computed: {
        ...mapGetters('user', ['userOrganisations']),
        teamsExist() {
            let vm = this;
            return vm.teams.length > 0
        }
    },
    methods: {
        deleteSelectedFieldTeam() {
            let vm = this;

            new Swal({
                title: 'Alert!',
                text: 'Are you sure you want to delete selected field team?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-danger btn-fill',
                cancelButtonClass: 'btn btn-success btn-fill',
                confirmButtonText: 'Delete',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {

                    axios
                        .delete(`/field_team/delete/${vm.selectedTeam}`)
                        .then(response => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert(
                                    "Deleted",
                                    response.data.response.error.msg,
                                    true
                                );
                                vm.handleChange();
                            }
                        })
                        .catch(error => {
                        });

                }
            });

        },
        async handleChange() {
            const fieldTeamResponse = await axios.get(`/field_team/teams/org/${this.selectedOrganisation}`)
            const {response: {data: {teams}}} = fieldTeamResponse.data
            this.getTeamsApiCallFinished = true;
            this.teams = teams
            await this.getFieldTeams();
        },

        getFieldTeams() {
            let vm = this;
            axios
                .get(`/field_team/teams/org/${this.selectedOrganisation}`)
                .then(response => {
                    vm.teams = response.data.response.data.teams;
                    if (vm.teams.length > 0) {
                        console.log("id", vm.teams[0].id);
                        vm.selectedTeam = vm.teams[0].id;
                        vm.getTeamUsers();
                    }
                })
                .catch(error => {
                });
        },
        showUrl() {
            this.$refs.helpModal.loadUrl(window.USER_MANUAL_URL + "#h.5xiwsqqhy7z3");
        },
        async init() {
            await this.$store.dispatch('user/getUserOrganisations');
            if (this.userOrganisations.length) {
                this.selectedOrganisation = this.userOrganisations[0].organisation.id
                this.getOrgApiCallFinished = true;
            }
            console.log('after succes fetch org')
            this.getFieldTeams();
        },
        getTeamUsers() {
            let vm = this;

            vm.allUsers = [];
            vm.unassignedUsers = [];
            vm.assignedUsers = [];

            axios
                .get("/field_team/users", {
                    params: {
                        field_team_id: vm.selectedTeam,
                        organisation_id: vm.selectedOrganisation
                    }
                })
                .then(response => {
                    this.userOnTeam = []
                    this.remainingUsers = []
                    let teamUsers =
                        response.data.response.data.field_team.field_users;
                    let unassigned =
                        response.data.response.data.field_team.unassigned_users;
                    teamUsers
                    // this.userOnTeam = teamUsers
                    this.remainingUsers = unassigned
                    if (teamUsers.length > 0) {
                        for (let i = 0; i < teamUsers.length; i++) {
                            const user = teamUsers[i].user
                            const formatForUserOnTeam = {
                                email: user.email,
                                id: user.id,
                                first_name: user.first_name,
                                last_name: user.last_name,
                                name: `${user.first_name} ${user.last_name}`
                            }
                            this.userOnTeam.push(formatForUserOnTeam)
                            vm.assignedUsers.push(teamUsers[i].user.id);
                            vm.allUsers.push({
                                key: teamUsers[i].user.id,
                                label: teamUsers[i].user.email,
                                disabled: false
                            });
                        }
                    }
                    if (unassigned.length > 0) {
                        for (let i = 0; i < unassigned.length; i++) {
                            const curr = unassigned[i]
                            curr.name = `${curr.first_name} ${curr.last_name}`
                            vm.unassignedUsers.push(unassigned[i].id);
                            vm.allUsers.push({
                                key: unassigned[i].id,
                                label: unassigned[i].email,
                                disabled: false
                            });
                        }
                    }
                    this.allUsersFormatted = [...this.userOnTeam, ...this.remainingUsers]
                })
                .catch(error => {
                });
        },
        saveAssignedUsers: function () {
            let vm = this;

            if (vm.isDisabled == true) {
                return;
            }

            if (vm.selectedTeam == null) {
                alert("Please select team");
                return;
            }

            vm.isDisabled = true;

            axios
                .post("/field_team/assign_users", {
                    field_team_id: vm.selectedTeam,
                    user_ids:
                        vm.transferValue.length > 0
                            ? vm.transferValue.toString()
                            : 0
                })
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert(
                            "Updated",
                            response.data.response.error.msg,
                            true
                        );
                    }
                    this.getTeamUsers()
                    vm.isDisabled = false;
                })
                .catch(error => {
                    console.log(error);
                    vm.isDisabled = false;
                });
        },
        rightListChanged(keyArray, direction, itemKey) {
            let vm = this;
            if (direction === "left") {
                //assign this user and remove from unassigned list
                for (let i = 0; i < itemKey.length; i++) {
                    let index = vm.assignedUsers.indexOf(parseInt(itemKey[i]));
                    if (index > -1) {
                        vm.assignedUsers.splice(index, 1);
                    }
                    vm.assignedUsers.push(parseInt(itemKey[i]));
                }
            } else {
                for (let i = 0; i < itemKey.length; i++) {
                    let index = vm.assignedUsers.indexOf(parseInt(itemKey[i]));
                    if (index > -1) {
                        vm.assignedUsers.splice(index, 1);
                    }
                }
            }
        },
        getUsersDetails() {
            axios
                .get("/user")
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        this.organisationData =
                            response.data.response.data.organisation;
                    }
                })
                .catch(error => {
                });
        }
    },
    mounted() {
        this.init();
        this.getUsersDetails();
        document.getElementsByClassName("nav-link-field-users")[0].style.color =
            "#f58220";
        // Pradeep Start
        // check organisation verified
        // if(this.organisation ==2){
        //     this.isOrgDeleted=true
        // }
        // Pradeep End
    }
};
</script>

<style scoped>
.el-select .el-input:hover .el-input__icon,
.el-select .el-input:hover input {
    color: black;
}

.el-transfer {
    text-align: center;
}

.el-transfer-panel {
    text-align: left;
    width: 40%;
}

.el-transfer__button.is-disabled {
    border: 1px solid #dcdfe6;
    background-color: #f5f7fa !important;
    color: #c0c4cc;
}

.el-transfer__button:first-child {
    background-color: rgb(42, 144, 85);
}

.el-transfer__button:nth-child(2) {
    background-color: rgb(235, 101, 50);
}

.el-icon-arrow-left:before {
    content: "";
}

.el-icon-arrow-right:before {
    content: "";
}

.custom-transfer {
    width: 100%;
    /* margin: auto; */
}

@media (max-width: 1200px) {
    .el-transfer-panel {
        width: 35%;
    }

    .el-transfer-panel__body {
        height: 240px;
    }
}

@media (max-width: 800px) {
    .el-transfer-panel {
        width: 95%;
    }

    .el-transfer__buttons {
        width: 100%;
    }

    .el-transfer__buttons button {
        float: right;
        margin: 10px !important;
    }
}

@media (max-width: 720px) {
    .custom-transfer {
        width: auto;
    }
}

.list-box {
    width: 300px;
    background-color: white;
    border-radius: 5px;
    float: left;
    margin-right: 50px;
}

select {
    width: 300px;
    height: 243px;
    border: none;
}

option {
    width: 300px;
    padding: 10px;
}

h6 {
    margin: 0;
}

/* Pardeep Start */
.fieldteams {
    width: 300px;
}

.set_row {
    /* margin-right: -5px !important; */
    margin-right: -2px !important;
    margin-left: -5px !important;
}

.set_btn {
    margin: 0;
}

.help-icon {
    cursor: pointer;
    position: absolute;
    margin-left: 5px;
    margin-top: -15px;
    color: #51cbce !important;
}

@media (min-width: 320px) and (max-width: 767px) {
    .set_btn {
        margin-top: 10px !important;
    }
}

@media (max-width: 320px) {
    .field_teams_box {
        margin-bottom: 8% !important;
    }

    .fieldteams {
        font-size: 13px;
    }
}

@media (min-width: 321px) and (max-width: 374px) {
    .field_teams_box {
        margin-bottom: 8% !important;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .field_teams_box {
        margin-bottom: 8% !important;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .field_teams_box {
        margin-bottom: 8% !important;
    }
}

@media (min-width: 768px) and (max-width: 769px) {
    .field_teams_box {
        margin-bottom: 4% !important;
    }
}

.title-row {
    padding-left: 30px;
    padding-top: 15px;
}

@media (max-width: 767px) {
    .title-row {
        padding-left: 0;
        padding-top: 0;
    }

    .mobile-m-top-only {
        margin: 1.5rem 0 0 !important;
    }

    .set_row .save-btn-wrap {
        margin: 0 !important;
        width: 100%;
        text-align: center;
    }
}

.title-row h5 {
    margin-bottom: 0;
}


.hidden_href {
    pointer-events: none !important;
    cursor: default !important;
}

@media (min-width: 320px) and (max-width: 425px) {
    select {
        width: 100% !important;
    }

    .fieldteams {
        width: 100% !important;
    }

    .field_teams_box {
        width: 100% !important;
    }

    option {
        width: 100% !important;
    }
}

@media (min-width: 426px) {
    .maindiv {
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 1441px) {
    .savebtn {
        margin-left: -196px;
    }
}

@media (min-width: 1023px) and (max-width: 1025px) {
    .savebtn {
        margin-left: -149px !important;
    }
}

@media (min-width: 767px) and (max-width: 769px) {
    .savebtn {
        margin-left: 32px !important;
    }
}

@media (min-width: 1366px) and (max-width: 1367px) {
    .maindiv {
        display: contents;
    }
}

/* Pardeep End */
</style>
