import {Message, Loading} from "element-ui";

window.DefaultUserImage = process.env.VUE_APP_DEFAULT_USER_IMAGE_URL;

window._ = require('lodash');
window.Popper = require('popper.js').default;

window.$ = window.jQuery = require('jquery');

require('bootstrap');

window.API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
window.DATATABLES_BASE_URL = window.API_BASE_URL + process.env.VUE_APP_DATATABLES_URL_STRING;
window.USER_MANUAL_URL = process.env.VUE_APP_USER_MANUAL_URL;

window.axios = require('axios');


window.axios.defaults.baseURL = window.API_BASE_URL;

window.appConfig = require("./config").default;

import Vue from 'vue'

window.Vue = Vue;

window.Vue.prototype.$message = Message;
window.Vue.use(Loading.directive);
window.Vue.prototype.$loading = Loading.service;

window.Bus = new Vue();

window.moment = require('moment-timezone');
window.toastr = require('toastr');

window.toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-full-width",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

window.cdnPrefix = process.env.VUE_APP_CDN_PREFIX;
window.demoPrefix = `${window.cdnPrefix}/demo`;

import jsZip from 'jszip';

//require('pdfmake/build/pdfmake');

require('imports-loader?this=>window!pdfmake/build/vfs_fonts');

require('imports-loader?define=>false!datatables.net')(window, $);
require('imports-loader?define=>false!datatables.net-bs')(window, $);
require('imports-loader?define=>false!datatables.net-buttons')(window, $);
require('imports-loader?define=>false!datatables.net-buttons/js/buttons.print')(
    window, $);
require('imports-loader?define=>false!datatables.net-buttons/js/buttons.html5')(
    window, $);
require('imports-loader?define=>false!datatables.net-buttons-bs4')(window, $);
require('imports-loader?define=>false!datatables.net-fixedheader')(window, $);
require('imports-loader?this=>window!pdfmake/build/vfs_fonts');
require('datatables.net-buttons/js/buttons.colVis.js');


import 'datatables.net-buttons';

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-buttons-bs/css/buttons.bootstrap.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
import 'datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css';
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css';

window.JSZip = jsZip;

import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
        libraries: '',
    }
});
Vue.component('GmapCluster', GmapCluster)

import GlobalMixin from "./mixins/GlobalMixin";

Vue.mixin(GlobalMixin)

const App = () => import('./App.vue');

import PaperDashboard from "./plugins/paperDashboard";
import "vue-notifyjs/themes/default.css";
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(PaperDashboard);

import VMdDateRangePicker from "v-md-date-range-picker";

window.Vue.use(VMdDateRangePicker);

import VueRouter from 'vue-router'

import routes from "@/routes";

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition)
    {
        return {x: 0, y: 0}
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getJwt;
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getJwt;
            if(store.getters.isSuperAdmin){
                next("/admin/dashboard");
            }else{
                next("/platform/projects");
            }
            return;
        }
        next();
    } else {
        next();
    }
});

Vue.use(VueRouter)

import store from "./store";
import i18n from './i18n'

window.axios.interceptors.response.use(undefined, async function (error) {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await store.dispatch('SetAuthError', "Authentication missing")
            await store.dispatch('LogOut')
            return router.push('/login')
        } else
            if (error.response.status === 400 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    await store.dispatch('SetAuthError', error.response.data.response.error.error_msg+" "+error.response.data.response.error.msg)
                    window.Bus.$emit('api_error_422', error.response.data.response.error.error_msg)
                } catch (e) {
                    if (error.response.data.error === 'Provided token is expired.') {
                        await store.dispatch('SetAuthError', "Your login has expired, please login again")
                        await store.dispatch('LogOut')
                        return router.push('/login')
                    }
                }

            } else
                if ((error.response.status === 422) && !originalRequest._retry) {
                    if (error.response.data.response.error.error_msg == 'validation failed') {
                        let errorMsg = '';
                        let values = Object.values(error.response.data.response.data);
                        console.log(values);
                        for (let i = 0; i < values.length; i++) {
                            console.log(values[i][0]);
                            errorMsg = errorMsg + values[i][0] + ' ';
                        }
                        window.Bus.$emit('api_error_422', errorMsg)
                    } else {
                        window.Bus.$emit('api_error_422', error.response.data.response.error.error_msg)
                    }
                } else
                    if (error.response.status === 500) {
                        window.Bus.$emit('api_error_422', "Internal Server Error.")
                    }
    }
})

let app = new Vue({
    router,
    render: h => h(App),
    i18n,

    methods: {
        navigateToMoveOuterRecords(areaId)
        {
            router.push({name: 'projectMoveOuterVaccinations', params: {areaId: areaId}})
        }
    }
}).$mount('#app');

window.moveOuterVaccinationRecords = function (areaId) {
    app.$options.methods.navigateToMoveOuterRecords(areaId);
};
