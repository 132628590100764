<template>
    <div class="set_self_project_res">
        <!-- <div class="" style="text-align: right;">
           <button type="button" class="btn btn-round btn-default" @click="downloadExcel">Download Excel</button>
           <button type="button" class="btn btn-round btn-default" @click="downloadPdf">Download PDf</button>
        </div>
        <hr> -->
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <el-row class="setMarginTop">
            <!-- <div class="container"> -->
            <el-col>
                <div class="panel-heading">
                    <!-- <h3>Vaccination Area</h3> -->
                    <h5>Records > Vaccination > List {{ activeAreaName }}</h5>
                    <hr>
                </div>
            </el-col>
            <!-- </div> -->
        </el-row>
        <div class="row setMarginTop">
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Total Animals</p>
                                        {{ stats.total_animals }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Male</p>
                                        {{ stats.total_males }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Female</p>
                                        {{ stats.total_female }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Young</p>
                                        {{ stats.total_young }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Senior</p>
                                        {{ stats.total_senior }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Adult</p>
                                        {{ stats.total_adult }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Lactating</p>
                                        {{ stats.total_lactating }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Pregnant</p>
                                        {{ stats.total_pregnant }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Owned</p>
                                        {{ stats.total_owned }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="col-xs-6 col-sm-6 col-md-2">-->
<!--                <div class="card setShadow">-->
<!--                    <div class="card-body">-->
<!--                        <div>-->
<!--                            <div class="row">-->
<!--                                <div class="col-12">-->
<!--                                    <div class="numbers">-->
<!--                                        <p>Stray</p>-->
<!--                                        {{ stats.total_stray }}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Owned free roaming</p>
                                        {{ stats.total_owned_free_roaming }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Community</p>
                                        {{ stats.total_community }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Community free roaming</p>
                                        {{ stats.total_community_free_roaming }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Wild</p>
                                        {{ stats.total_wild }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Areas</p>
                                        {{ areas.length ? areas.length : 0 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>Field Users</p>
                                        {{ stats.total_field_users }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row setMarginTop">
            <div v-for="(species, index) in stats.species" class="col-xs-6 col-sm-6 col-md-2" :key="index">
                <div class="card setShadow">
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="numbers">
                                        <p>{{ species.name }}</p>
                                        {{ species.count }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12" v-if="stats.total_out_of_area>0">
                <p>Note: There are {{ stats.total_out_of_area }} additional animals outside the Area boundary geofence,
                    click <span style="color: dodgerblue; cursor: pointer;" @click="showOutOfAreaRecords()">here</span>
                    to process them.
                </p>
            </div>
            <div class="col-sm-12 col-xs-12 col-md-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row set_row">
                            <div class="col-12" style="text-align: right;">
                                <button type="button" class="btn btn-default" :disabled="isGeneratingData"
                                        @click="downloadAllRecordCsv()">Export to CSV
                                </button>
                            </div>
                            <div class="col-12">
                                <table id="vaccRecords" class="display table-striped table-bordered select"
                                       cellspacing="0" width="100%">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Date Time</th>
                                        <th>Image</th>
                                        <th>RFID</th>
                                        <th>HSIApps ID</th>
                                        <th>Species</th>
                                        <th>Gender</th>
                                        <th>Age</th>
                                        <th>Ownership</th>
                                        <th>Area</th>
                                        <th>Vaccinator</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <div v-if="!isReadOnly" class="float-right">
                            <button v-if="bulkArchiveData.isLoading" class="btn btn-primary" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                            <button v-else class="btn btn-default" :disabled="!bulkArchiveData.selectedEntries.length"
                                    @click="bulkArchive">Archive Selected
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- The Modal -->
        <div id="image-modal" class="modal_full_img">
            <span class="close close-image-modal">&times;</span>
            <img class="modal-content-full-img" id="image-modal-src">
            <div id="caption"></div>
        </div>
        <!-- Edit Record Modal Satrt -->
        <div id="editRecordModal" class="modal edit-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content" style="padding: 10px;">
                    <div class="modal-header edit-header">
                        <h4 class="modal-title">Vaccination Record Details</h4>
                    </div>
                    <div class="modal-body edit-modal-body">
                        <form class="form-horizontal" enctype="multipart/form-data">
                            <div class="form-group row" style="text-align: center;">
                                <input class="form-control" type="hidden" v-model="ownerData" disabled>
                                <input class="form-control" type="hidden" v-model="record_id" disabled>
                            </div>
                            <br>
                            <div class="form-group row">

                                <div class="col-md-4 text-center">
                                    <img @click="showFullSizeImage(record_img)" id="record_img" :src="record_img"
                                         style="height: 250px;width: 250px;"/>
                                </div>

                                <div class="col-md-4">
                                    <label>Species</label>
                                    <input class="form-control" id="species" type="text" name="species"
                                           v-model="species" disabled>

                                    <label>Gender</label>
                                    <input class="form-control" id="gender" type="text" name="gender" v-model="gender"
                                           disabled>

                                    <label>Ownership</label>
                                    <input class="form-control" id="ownership" type="text" name="ownership"
                                           v-model="ownership" disabled>
                                </div>

                                <div class="col-md-4">
                                    <label>RFID</label>
                                    <input class="form-control" id="rfid" type="text" name="rfid"
                                           v-model="rfid" disabled>

                                    <label>HSIApps ID</label>
                                    <input class="form-control" id="uniqueid" type="text" name="uniqueid"
                                           v-model="uniqueid" disabled>

                                    <label>Date Time</label>
                                    <input class="form-control" type="text" name="latlong"
                                           v-model="datetime.toLocaleString()" disabled>
                                </div>

                            </div>

                            <br>

                            <div class="form-group row">
                                <label class="col-md-6">Area</label>
                                <label class="col-md-6">Location</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input class="form-control" id="areaName" type="text" name="areaName"
                                           v-model="areaName" disabled>
                                </div>
                                <div class="col-md-6">
                                    <a style="margin: 0" class="btn btn-secondary"
                                       :href="`https://www.google.com/maps/search/?api=1&query=${latlong}`"
                                       target="_blank">Click to View on Map</a>
                                </div>
                            </div>
                            <div class="row"
                                 style="border: 2px solid darkgrey; border-radius: 5px; position:relative; padding-bottom: 10px; padding-top: 10px; margin-bottom: 10px;">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label class="col-md-6">Owner Name</label>
                                        <label class="col-md-6">Owner Number</label>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <input class="form-control" id="ownerName" ref="ownerName" type="password"
                                                   name="ownerName"
                                                   v-model="ownerName" disabled>
                                        </div>
                                        <div class="col-md-6">
                                            <input class="form-control" id="ownerNumber" type="password"
                                                   name="ownerNumber"
                                                   ref="ownerNumber"
                                                   v-model="ownerNumber" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <button type="button" @click="unHideOwnerInfo()" class="btn btn-warning"
                                            ref="showHideButton"
                                            style="position:absolute; bottom: -25px; right: 10px; height: 30px; border-radius: 35px; padding: 0px 10px 0px 10px; margin: 0;">
                                        {{ this.isOwnerInfoHidden ? 'SHOW' : 'HIDE' }}
                                    </button>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">Vaccinator</label>
                                <label class="col-md-6">Vaccinator Assistant</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input class="form-control" id="vaccinator-user" type="text" name="user"
                                           v-model="vaccinatedByName"
                                           disabled>
                                </div>
                                <div class="col-md-6">
                                    <input class="form-control" id="user" type="text" name="user" v-model="user"
                                           disabled>
                                    <p style="font-size: 12px; color: grey">The firstname/lastname of someone
                                        that is using the Vaccinations phone (app).</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">Given Vaccines/Drugs</label>
                                <label class="col-md-6">Note</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <template v-for="vaccine in givenVaccines">
                                        <span class="vaccine-chip">{{ vaccine }}</span>
                                    </template>
                                </div>
                                <div class="col-md-6">
                                    <textarea class="form-control" id="note" type="text" name="note" v-model="note">
                                    </textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr>
                    <div class="row set_footer">
                        <div class="col-md-4" style="">
                            <button v-if="!isReadOnly" type="button" class="btn btn-default delete_btn"
                                    id="delete-record"
                                    data-dismiss="modal" @click="deleteRecordDetails"><span class="ti-trash"
                                                                                            style="cursor: pointer;"></span>
                            </button>
                        </div>
                        <div class="col-md-8" style="text-align:right">
                            <button type="button" class="btn btn-default" style="background-color: #eb6532 !important;"
                                    id="close-record" data-dismiss="modal">Close
                            </button>
                            <button v-if="!isReadOnly" type="button" class="btn btn-default"
                                    style="background-color: #2a9055 !important;"
                                    id="save-record" data-dismiss="modal" @click="updateRecordDetails">Save Note
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Owner Details Modal Satrt -->
        <div id="ownerDetailsModal" class="modal edit-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header edit-header">
                        <h4 class="modal-title">Owner Details</h4>
                    </div>
                    <div class="modal-body edit-modal-body">
                        <form class="form-horizontal" enctype="multipart/form-data">
                            <div class="form-group row">
                                <label class="col-md-6">Owner Name</label>
                                <label class="col-md-6">Phone</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input class="form-control" type="hidden" v-model="record_id">
                                    <input class="form-control" id="owner_name" type="text" name="owner_name"
                                           v-model="owner_name" required>
                                </div>
                                <div class="col-md-6">
                                    <input class="form-control" id="telephone_mobile" type="text"
                                           name="telephone_mobile" v-model="telephone_mobile">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">Email Address</label>
                                <label class="col-md-6">Address</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input class="form-control" id="email_address" type="email" name="email_address"
                                           v-model="email_address">
                                </div>
                                <div class="col-md-6">
                                    <input id="autocomplete" class="form-control" placeholder="Enter your address"
                                           @onFocus="geolocate()" type="text"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">City</label>
                                <label class="col-md-6">Admin Area Level 1</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input class="form-control" id="locality" type="text" name="city">
                                </div>
                                <div class="col-md-6">
                                    <input class="form-control" id="administrative_area_level_1" type="text"
                                           name="admin_area_level1">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">Country</label>
                                <label class="col-md-6">Zip Code </label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input class="form-control" id="country" type="text" name="country">
                                </div>
                                <div class="col-md-6">
                                    <input class="form-control" id="postal_code" type="number"
                                           name="google_postal_code">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-6">Details</label>
                                <label class="col-md-6">Note</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input class="form-control" id="details" type="text" name="details"
                                           v-model="owner_details">
                                </div>
                                <div class="col-md-6">
                                    <input class="form-control" id="owner_note" type="text" name="owner_note"
                                           v-model="owner_note">
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr>
                    <div class="row set_footer">
                        <!-- <div> -->
                        <div class="col-md-4" style="">
                            <button v-show="isOwner" type="button" class="btn btn-default delete_btn"
                                    id="delete-owner-record"
                                    data-dismiss="modal"><span class="ti-trash" style="cursor: pointer;"></span>
                            </button>
                        </div>
                        <div class="col-md-8" style="text-align:right">
                            <button type="button" class="btn btn-default" style="background-color: #eb6532 !important;"
                                    id="close-owner-popup" data-dismiss="modal">Close
                            </button>
                            <button type="button" class="btn btn-default" style="background-color: #2a9055 !important;"
                                    id="save-owner-record" data-dismiss="modal" @click="updateOwnerDetails">
                                {{ isOwner ? 'Save' : 'Add Owner' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Owner Details Modal Satrt -->

        <div class="row">
            <div class="col-12" style="display: none">
                <div v-html="tableData"></div>
            </div>
        </div>

        <FullscreenLoader ref="loader" msg="Please wait generating CSV..." :api-source="apiSource"></FullscreenLoader>

    </div>
</template>
<script>
import {API_KEY} from '../Template/Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
import {Row, Col} from 'element-ui'
import FullscreenLoader from "../FullscreenLoader";

GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['places'];
var autocomplete;
var componentForm = {
    // street_number: 'short_name',
    //route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    country: 'long_name',
    postal_code: 'short_name'
};


import mixin from '../../mixins/GlobalMixin';
import Swal from 'sweetalert2';
import VOffline from 'v-offline';

export default {
    components: {
        FullscreenLoader,
        VOffline,
        [Row.name]: Row,
        [Col.name]: Col,
    },
    props: {
        projectId: {
            type: Number,
            default: 0
        },
        areaId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            apiSource: null,
            isReadOnly: false,
            isOwnerInfoHidden: true,
            projectName: "",
            table: null,
            stats: [],
            areas: [],
            //edit Records popup Start
            record_img: '',
            uniqueid: '',
            species: '',
            gender: '',
            reproductive_status: '',
            ownership: '',
            note: '',
            areaName: '',
            givenVaccines: [],
            rfid: '',
            user: '',
            vaccinatedByName: '',
            ownerName: '',
            ownerNumber: '',
            latlong: '',
            hidden_owner_name: '',
            hidden_zip_code: '',
            record_id: '',
            datetime: '',
            //edit Records popup end
            //Owner details Popup start
            owner_name: '',
            telephone_mobile: '',
            email_address: '',
            city_district_village: '',
            state_province: '',
            google_street_address: '',
            country: '',
            postal_code: '',
            owner_details: '',
            owner_note: '',
            //Owner details Popup end
            ownerData: '',
            bulkArchiveData: {
                selectedEntries: [],
                isLoading: false,
            },
            activeAreaName: '',
            projectLevelView: false,
            isOwner: false,
            isGeneratingData: false,
            generationStatus: null,
            tableData: '',
        }
    },
    mixins: [mixin],
    methods: {
        downloadAllRecordCsv() {
            let vm = this

            vm.isGeneratingData = true

            const cancelToken = axios.CancelToken;
            vm.apiSource = cancelToken.source();

            this.$refs.loader.show()

            axios.get('/vaccination/generate/full/program/data/' + vm.projectId, {
                cancelToken: vm.apiSource.token
            }).then(response => {
                console.log(response)
                vm.tableData = response.data.response.data.data
                vm.fileName = response.data.response.data.file_name

                setTimeout(() => {
                    vm.tableToCSV()
                    vm.isGeneratingData = false
                    this.$refs.loader.hide()
                }, 2000)

            }).catch(error => {
                vm.generationStatus = 'api_error'
                console.log(error)
                vm.isGeneratingData = false
            })
        },
        tableToCSV() {

            const vm = this

            vm.generationStatus = 'creating_csv'

            // Variable to store the final csv data
            let csv_data = [];

            // Get each row data
            let table = document.getElementById('csv-table');
            let rows = table.getElementsByTagName('tr');

            for (let i = 0; i < rows.length; i++) {

                // Get each column data
                let cols = rows[i].querySelectorAll('td,th');

                // Stores each csv row data
                let csvrow = [];
                for (let j = 0; j < cols.length; j++) {

                    // Get the text data of each cell
                    // of a row and push it to csvrow
                    csvrow.push(cols[j].innerHTML);
                }

                // Combine each column value with comma
                csv_data.push(csvrow.join(","));
            }

            // Combine each row data with new line character
            csv_data = csv_data.join('\n');

            // Call this function to download csv file
            this.downloadCSVFile(csv_data);

        },
        downloadCSVFile(csv_data) {
            const vm = this
            vm.generationStatus = 'downloading_file'

            // Create CSV file object and feed
            // our csv_data into it
            let CSVFile = new Blob([csv_data], {
                type: "text/csv"
            });

            // Create to temporary link to initiate
            // download process
            let temp_link = document.createElement('a');

            // Download csv file
            temp_link.download = vm.fileName + ".csv";
            let url = window.URL.createObjectURL(CSVFile);
            temp_link.href = url;

            // This link should not be displayed
            temp_link.style.display = "none";
            document.body.appendChild(temp_link);

            // Automatically click the link to
            // trigger download
            temp_link.click();
            document.body.removeChild(temp_link);

            setTimeout(function () {
                vm.generationStatus = null
            }, 500)

        },
        unHideOwnerInfo() {
            if (this.isOwnerInfoHidden) {
                this.$refs.ownerName.type = 'text';
                this.$refs.ownerNumber.type = 'text';
            } else {
                this.$refs.ownerName.type = 'password';
                this.$refs.ownerNumber.type = 'password';
            }
            this.isOwnerInfoHidden = !this.isOwnerInfoHidden;
        },
        showOutOfAreaRecords() {
            let project = this.$store.getters.getCurrentSelectedProject;
            //window.location = '/area/outer_vaccinations/' + project.id;
            this.$router.push({name: 'projectOuterVaccinations'})
        },
        backVaccination() {
            window.location = '/project/vaccination_record/' + this.projectId;
        },
        getStatsData() {
            let url = "/stats/vaccination/" + this.projectId;
            if (this.areaId > 0) {
                url = "/stats/vaccinationByArea/" + this.areaId;
            }

            axios.get(url)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        let vm = this;
                        vm.stats = response.data.response.data.stats;
                        let species = vm.stats.species;
                    }
                })
                .catch(error => {
                    if (error.response.data.response.error) {

                    }
                });
        },
        downloadExcel() {
            this.showAlert('Error', "Under Construction", false);
        },
        downloadPdf() {
            this.showAlert('Error', "Under Construction", false);
        },
        init() {
            this.getVaccinationRecords();
        },
        getVaccinationRecords() {
            let vm = this;

            if (vm.table != null) {
                vm.table.destroy();
            }
            try {
                vm.table = $('#vaccRecords').DataTable({
                    "scrollX": true,
                    responsive: true,
                    dom: 'lfrtp',
                    "bInfo": false,
                    "order": [[1, "desc"]],
                    "columnDefs": [
                        {
                            "visible": false,
                            "targets": []
                        }, {
                            "searchable": false,
                            "targets": [0, 2, 10]
                        }, {
                            "orderable": false,
                            "targets": [0, 2, 10]
                        }
                    ],
                    'columns': [
                        {
                            'targets': 0,
                            'render': function (data, type, full, meta) {
                                return `<input type="checkbox" id="rowCheck${data}" />`
                            },
                            'orderable': false,
                            "searchable": false
                        },
                        {
                            'targets': 1,
                            'render': function (data, type, full, meta) {
                                return vm.formatRecordDisplayDate(full[1], 'DD MMM YYYY, HH:mm:ss', ',');
                            },
                        }, {
                            'targets': 2,
                            'render': function (data, type, full, meta) {
                                return '<img src=' + full[2] + ' class="datatable_image_thumbnail" style="cursor: pointer;" height="70" width="70"/>';
                            },
                            "searchable": false
                        }, {
                            'targets': 3,
                            'render': function (data, type, full, meta) {
                                return full[3];
                            },
                        }, {
                            'targets': 4,
                            'render': function (data, type, full, meta) {
                                return full[4];
                            },
                        }, {
                            'targets': 5,
                            'render': function (data, type, full, meta) {
                                return full[5];
                            },
                        }, {
                            'targets': 6,
                            'render': function (data, type, full, meta) {
                                return full[6];
                            },
                        }, {
                            'targets': 7,
                            'render': function (data, type, full, meta) {
                                return full[7];
                            },
                        }, {
                            'targets': 8,
                            'render': function (data, type, full, meta) {
                                return full[8];
                            },
                        }, {
                            'targets': 9,
                            'render': function (data, type, full, meta) {
                                return full[9];
                            },
                        }, {
                            'targets': 10,
                            'render': function (data, type, full, meta) {
                                return full[10];
                            },
                        }, {
                            'targets': 11,
                            'render': function (data, type, full, meta) {
                                var ownership = full[7];
                                return '<button type="button" class="btn btn-info editRecord">View/Edit</button>';
                                /*if (ownership.toUpperCase() == "OWNED") {
                                    return '<span class="ti-eye editRecord" style="padding: 10px;cursor: pointer;"></span><span class="ti-user viewOwnerRecord" style="padding: 10px;cursor: pointer;"></span>';
                                } else {
                                    return '<span class="ti-eye editRecord" style="padding: 10px;cursor: pointer;"></span></span><span class="addOwnerInfo" style="padding: 10px;cursor: pointer;"><i class="fa fa-user-plus" aria-hidden="true"></i></span>';
                                }*/
                            },
                            'orderable': false,
                            "searchable": false
                        }
                    ],
                    buttons: [
                        {
                            extend: 'csv',
                            //text: 'Download Visible Records CSV',
                            //className: 'btn btn-default',
                            exportOptions: {
                                columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                                format: {
                                    body: function (data, col, row) {
                                        var isImg = ~data.toLowerCase().indexOf('img') ? true : false;
                                        if (isImg) {
                                            var div = document.createElement('div');
                                            div.innerHTML = data;
                                            var firstImage = div.getElementsByTagName('img')[0]
                                            var rawImgSrc = firstImage ? firstImage.getAttribute("src") : "";
                                            return rawImgSrc;
                                        }
                                        var isSpan = ~data.toLowerCase().indexOf('span') ? true : false;
                                        if (isSpan) {
                                            return "";
                                        }
                                        return data;
                                    }
                                }
                            }
                        }
                    ],
                    'fnRowCallback': function (nRow, aData, iDisplayIndex) {
                        const checkBox = $("input[type='checkbox']", nRow);
                        const editIconElement = $('.editRecord', nRow);
                        const ownerEyeIconElement = $('.viewOwnerRecord', nRow);
                        const addOwnerIconElement = $('.addOwnerInfo', nRow)
                        const imageElement = $('.datatable_image_thumbnail', nRow);
                        vm.selectTableEntries(checkBox, nRow);
                        var lastTd = $('td:eq(11)', nRow)[0];
                        lastTd.firstChild.dataset.data = Object.values(aData);
                        lastTd.lastChild.dataset.data = Object.values(aData);
                        vm.editRecordPopup(editIconElement);
                        vm.zoomImagePopup(imageElement);
                        if (aData[7].toUpperCase() === 'OWNED') {
                            //vm.ownerDetailsPopup(ownerEyeIconElement);
                        } else {
                            //vm.addOwnerInfoPopup(addOwnerIconElement)
                        }
                        if ($.inArray(aData.DT_RowId, vm.bulkArchiveData.selectedEntries) !== -1) {
                            $(nRow).addClass('selected');
                            checkBox.prop('checked', true);
                        }
                        return nRow;
                    },
                    "processing": true,
                    "serverSide": true,
                    "ajax": {
                        "url": `${window.DATATABLES_BASE_URL}vaccinationRecords`,
                        //"url": "http://localhost:8888/api/datatables/vaccinationRecords",
                        // "url": "http://127.0.0.1:8000/vaccinationLocalData.json",
                        "type": "POST",
                        "data": {
                            project_id: vm.projectId,
                            selected_area: vm.areaId,
                        },
                    },
                    lengthMenu: [[15, 100, -1], [15, 100, "All (Warning: Slow)"]],
                    pageLength: 15,
                });
            } catch (e) {
                console.log(e);
            }

        },

        selectTableEntries(checkBoxEl, row) {
            const vm = this;
            checkBoxEl.on('change', function () {
                const id = row.id;
                const index = $.inArray(id, vm.bulkArchiveData.selectedEntries);
                if (index === -1) {
                    vm.bulkArchiveData.selectedEntries.push(id);
                } else {
                    vm.bulkArchiveData.selectedEntries.splice(index, 1);
                }
                $(row).toggleClass('selected');
            });
        },

        editRecordPopup(editEl) {
            var self = this;
            var editRecordModal = document.getElementById("editRecordModal");
            editEl[0].addEventListener('click', function () {
                let data = this.getAttribute("data-data");
                let res = data.split(",");
                let id = res[0];
                self.vaccinationRecordDetails(id, 0);
            }, false);
            let closeRecords = document.getElementById("close-record")
            closeRecords.onclick = function () {
                editRecordModal.style.display = "none";
            }
        },
        ownerDetailsPopup(ownerEyeEl) {
            var self = this;
            // var editIcon = document.querySelectorAll('.viewOwnerRecord');
            var ownerDetailsModal = document.getElementById("ownerDetailsModal");
            // for (var i = 0; i < editIcon.length; i++) {
            ownerEyeEl[0].addEventListener('click', function () {
                self.isOwner = true
                let data = this.getAttribute("data-data");
                let res = data.split(",");
                let id = res[0];
                self.vaccinationRecordDetails(id, 1);
            }, false);
            // }

            let ownerDeleteRecords = document.getElementById("delete-owner-record")
            ownerDeleteRecords.onclick = function () {
                ownerDetailsModal.style.display = "none";
            }

            let closeOwnerPopup = document.getElementById("close-owner-popup")
            closeOwnerPopup.onclick = function () {
                ownerDetailsModal.style.display = "none";
            }
        },
        addOwnerInfoPopup(addOwnerDetails) {
            var self = this;
            // var editIcon = document.querySelectorAll('.viewOwnerRecord');
            var ownerDetailsModal = document.getElementById("ownerDetailsModal");
            // for (var i = 0; i < editIcon.length; i++) {
            addOwnerDetails[0].addEventListener('click', function () {
                self.isOwner = false
                let data = this.getAttribute("data-data");
                let res = data.split(",");
                let id = res[0];
                // self.vaccinationRecordDetails(id, 1);
                ownerDetailsModal.style.display = "block";
                self.record_id = id;
                // var ownerData = ''
                self.owner_name = ''
                self.telephone_mobile = ''
                self.email_address = ''
                self.owner_details = ''
                self.owner_note = '',
                    // this.owner_name = '',
                    // this.telephone_mobile = '',
                    // this.email_address = '',
                    // this.city_district_village = '',
                    // this.state_province = '',
                    // this.country = '',
                    // this.postal_code = '',
                    // this.owner_details = '',
                    // this.owner_note = '',
                    document.getElementById('autocomplete').value = ''
                document.getElementById("locality").value = ''
                document.getElementById("administrative_area_level_1").value = ''
                document.getElementById("country").value = ''
                document.getElementById("postal_code").value = ''
            }, false);
            // }

            // let ownerDeleteRecords = document.getElementById("delete-owner-record")
            // ownerDeleteRecords.onclick = function () {
            //     ownerDetailsModal.style.display = "none";
            // }

            let closeOwnerPopup = document.getElementById("close-owner-popup")
            closeOwnerPopup.onclick = function () {
                ownerDetailsModal.style.display = "none";
            }
        },
        vaccinationRecordDetails(recordId, whenCheckCall) {
            axios.get("/vaccination/record/details/" + this.projectId + "/" + recordId)
                .then(response => {
                    if (response.data.response.error.error_code == 0) {
                        var record = response.data.response.data.record;
                        if (whenCheckCall == 0) {
                            var editRecordModal = document.getElementById("editRecordModal");
                            editRecordModal.style.display = "block";
                            var isOwner = record.animal.owner;
                            /*if (isOwner == null) {
                                document.getElementById('save-record').style.display = "none";
                                document.getElementById('delete-record').style.display = "none";
                            } else {
                                document.getElementById('save-record').style.display = "inline-block";
                                document.getElementById('delete-record').style.display = "block";
                            }*/
                            var animal = record.animal;
                            this.record_id = record.id;
                            this.record_img = animal.most_recent_image_url;
                            this.uniqueid = animal.hsapps_id;
                            this.species = animal.species;
                            this.gender = animal.gender;
                            this.rfid = animal.rf_id;
                            this.reproductive_status = record.reproductive_status;
                            this.ownership = record.ownership;
                            this.note = record.note;
                            this.areaName = record.area.name;
                            this.givenVaccines = record.given_vaccine;
                            this.user = record.vaccine_assistant;
                            this.vaccinatedByName = record.vaccinated_by_name;
                            this.ownerName = record.animal.owner ? record.animal.owner.name : "N/A";
                            this.ownerNumber = record.animal.owner ? record.animal.owner.telephone_mobile : "N/A";
                            this.datetime = new Date(animal.created_at);
                            this.latlong = record.vaccination_location_lat_lng;
                            this.ownerData = record.animal.owner;
                        } else if (whenCheckCall == 1) {
                            var ownerDetailsModal = document.getElementById("ownerDetailsModal");
                            ownerDetailsModal.style.display = "block";
                            this.record_id = record.id;
                            var ownerData = record.animal.owner;
                            this.owner_name = ownerData.name;
                            this.telephone_mobile = ownerData.telephone_mobile;
                            this.email_address = ownerData.email_address;
                            this.owner_details = ownerData.details;
                            this.owner_note = record.note;
                            document.getElementById("locality").value = ownerData.google_locality;
                            document.getElementById("administrative_area_level_1").value = ownerData.admin_area_level1;
                            document.getElementById("country").value = ownerData.country;
                            document.getElementById("postal_code").value = ownerData.google_postal_code;
                            document.getElementById("autocomplete").value = ownerData.google_street_address;
                        }
                    }
                })
                .catch(error => {
                });
        },
        updateOwnerDetails() {
            this.country = document.getElementById("country").value;
            this.state_province = document.getElementById("administrative_area_level_1").value;
            this.google_street_address = document.getElementById("autocomplete").value;
            this.city_district_village = document.getElementById("locality").value;
            this.postal_code = document.getElementById("postal_code").value;
            axios.post('/vaccination/update/record/' + this.projectId + "/" + this.record_id, {
                owner_name: this.owner_name,
                telephone_mobile: this.telephone_mobile,
                email_address: this.email_address,
                google_street_address: this.google_street_address,
                country: this.country,
                admin_area_level1: this.state_province,
                google_locality: this.city_district_village,
                google_postal_code: this.postal_code,
                details: this.owner_details,
                note: this.owner_note,
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    var ownerDetailsModal = document.getElementById("ownerDetailsModal");
                    ownerDetailsModal.style.display = "none";
                    this.showAlert('Success', response.data.response.error.msg, true);
                }
            }).catch(error => {
                this.showAlert('Error', error.response.data.response.error.error_msg, false);
            });
        },
        updateRecordDetails() {
            axios.post('/vaccination/update/record/' + this.projectId + "/" + this.record_id, {
                note: this.note,
            }).then(response => {
                if (response.data.response.error.error_code == 0) {
                    var editRecordModal = document.getElementById("editRecordModal");
                    editRecordModal.style.display = "none";
                    this.showAlert('Success', response.data.response.error.msg, true);
                }
            }).catch(error => {
                this.showAlert('Error', error.response.data.response.error.error_msg, false);
            });
        },
        deleteRecordDetails() {
            let vm = this;
            new Swal({
                title: 'Alert!',
                text: 'Are you sure you want to archive record ? ',
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn btn-success btn-fill',
                cancelButtonClass: 'btn btn-danger btn-fill',
                confirmButtonText: 'yes',
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios.post('/vaccination/archive/' + vm.record_id, {}).then(response => {
                        if (response.data.response.error.error_code == 0) {
                            var editRecordModal = document.getElementById("editRecordModal");
                            editRecordModal.style.display = "none";
                            vm.showAlert('Success', response.data.response.error.msg, true);
                        }
                    }).catch(error => {
                        vm.showAlert('Error', error.response.data.response.error.error_msg, false);
                    });
                }
            });
        },
        initAutocomplete() {
            autocomplete = new google.maps.places.Autocomplete(
                document.getElementById('autocomplete'), {types: ['geocode']});
            autocomplete.setFields(['address_component']);
            autocomplete.addListener('place_changed', function () {
                var place = autocomplete.getPlace();

                for (var component in componentForm) {
                    document.getElementById(component).value = '';
                    document.getElementById(component).disabled = false;
                }

                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (componentForm[addressType]) {
                        var val = place.address_components[i][componentForm[addressType]];
                        document.getElementById(addressType).value = val;
                    }
                }
            });
        },
        geolocate() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    var geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    var circle = new google.maps.Circle(
                        {center: geolocation, radius: position.coords.accuracy});
                    autocomplete.setBounds(circle.getBounds());
                });
            }
        },
        showFullSizeImage(src) {
            var modal = document.getElementById("image-modal");
            var modalImg = document.getElementById("image-modal-src");
            modal.style.display = "block";
            modalImg.src = src;
            var span = document.getElementsByClassName("close-image-modal")[0];
            span.onclick = function () {
                modal.style.display = "none";
            }
        },
        async bulkArchive() {
            if (this.bulkArchiveData.selectedEntries.length) {
                let selectedIds = this.bulkArchiveData.selectedEntries.map((el) => {
                    return el.replace('row_', '');
                })
                const requestPayload = {
                    record_ids: selectedIds,
                    // record_ids: ["241147", "241148"],
                    area_id: +this.areaId,
                    project_id: +this.projectId
                }
                try {
                    this.bulkArchiveData.isLoading = true;
                    const bulkArchiveResponse = await axios.post('vaccination/bulk/archive', requestPayload);
                    this.getVaccinationRecords()
                    this.showAlert('Success', bulkArchiveResponse.data.response.error.msg, true);
                    this.bulkArchiveData.selectedEntries = [];
                } catch (error) {
                    console.log(error);
                    this.showAlert('Sorry', error.response.data.response.error.msg, false);
                }
                this.bulkArchiveData.isLoading = false;
            } else {
                this.showAlert('OOPS', 'Something went wrong !', false);
            }
        }
    },
    mounted() {
        let vm = this;
        vm.isReadOnly = false
        vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
            if (r.role_name == 'VIEWER') {
                vm.isReadOnly = true
            }
        })
        let project = vm.$store.getters.getCurrentSelectedProject;
        //Bus.$emit("projectype", project);
        vm.areas = project.areas;
        vm.projectName = project.name;
        vm.projectId = project.id;

        if (localStorage.getItem('vaccinationProjectLevelViewList') == 1) {
            vm.activeAreaName = "> Project Level View";
            vm.projectLevelView = true;
        } else {
            vm.activeAreaName = "> Area (" + localStorage.getItem('VaccinationAreaName') + ")";
            vm.projectLevelView = false;
        }

        vm.init();
        vm.getStatsData();
        GoogleMapsLoader.load((google) => {
            vm.initAutocomplete();
        });
    }
}

</script>
<style lang="scss" scoped>

.setWhite {
    background-color: white;
}

.card .card-body {
    text-align: center;
}

.setMarginTop {
    margin-top: 11px;
    /* margin-bottom: 11px; */
}

.setTitleDiv {
    height: 40px;
    margin-left: calc(10% - 10%) !important;
    border-radius: 25px;
    text-align: left;
    display: block !important;
    padding: 6px 0 0 0px;
    margin-bottom: 20px;
}

.setTitleDiv p {
    font-size: 15px;
    font-weight: 600;
    color: black;
}

.setShadow {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
}

.card .numbers p {
    color: #9A9A9A;
    font-size: 14px;
    margin: 0;
}

.card .numbers {
    font-size: 1.5em !important;
}

.set_row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

/* @media (min-width: 1441px){
   .set_table_center{
      margin-left: 33px;
   }
} */
/* Model Css */
/* The Modal (background) */
.modal_full_img {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1051;
    width: 100%;
    padding: 80px;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
    display: none;
}

/* Modal Content (image) */
.modal-content-full-img {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    //height: 100%;
}

/* Caption of Modal Image */
#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

/* Add Animation */
.modal-content-full-img, #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }
    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

/* The Close Button */
.close-image-modal {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close-image-modal:hover,
.close-image-modal:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
    .modal-content-full-img {
        width: 100%;
    }
}

.edit-modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    padding: 80px;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
    display: none;
    opacity: 1 !important;
}

.edit-modal-body {
    padding: 1rem !important;
    color: #000;
    margin-bottom: -60px;
}

.modal-footer {
    border-top: 1px solid #DDDDDD;
    padding: 10px !important;
}

.modal-title {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.close-edit-records {
    position: absolute;
    top: 15px;
    right: 35px;
    font-size: 40px;
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 900px !important;
        margin: 1.75rem auto;
    }
}

.delete_btn {
    background-color: red !important;
}

.set_footer {
    padding: 15px;
}

.edit-header {
    height: 60px !important;
    margin-top: -13px !important;;
}

.form-group {
    margin-bottom: 0px !important;
    position: relative;
}

label {
    display: inline-block;
    margin-bottom: 0.3rem !important;
}

.form-control {
    margin-bottom: 14px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.btn-group .btn + .btn {
    margin-left: 5px !important;
}

.pac-container {
    z-index: 1000000000 !important;
    opacity: 1.5 !important;
}

.vaccine-chip {
    padding: 5px;
    color: white;
    background-color: forestgreen;
    border-radius: 20px;
    margin-right: 10px;
}

</style>
