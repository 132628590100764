import axios from 'axios';

class CommunityService {
    routeAPIPrefix = 'communities';

    getCommunityList(projectId) {
        return axios.get(`${this.routeAPIPrefix}/get_all/${projectId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }

    addCommunity(projectId, payload) {
        return axios.post(`${this.routeAPIPrefix}/add`, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }

    updateCommunity(projectId, communityId, payload) {
        return axios.put(`${this.routeAPIPrefix}/edit/${projectId}/${communityId}`, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.data;
        });
    }
}

export default new CommunityService();