import { render, staticRenderFns } from "./HcSideBar.vue?vue&type=template&id=453552ca&"
import script from "./HcSideBar.vue?vue&type=script&lang=js&"
export * from "./HcSideBar.vue?vue&type=script&lang=js&"
import style0 from "./HcSideBar.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports