import defaultLogo from './assets/images/HSIApps-Trnprnt-green.png';

export default {
    appName: 'HSAPPS',
    logo: defaultLogo,
    title: 'Health Clinic',
    project: 'hc',
    nav: [
        {
            name: 'Dashboard',
            icon: 'ti-dashboard',
            p: '/overview',
        },
        {
            name: 'Assign Users',
            icon: 'ti-check-box',
            p: '/users',
        },
        {
            name: 'Manage Community',
            icon: 'ti-view-list',
            p: '/communities',
        },
        {
            name: 'Records',
            icon: 'ti-view-list-alt',
            id: 'third-party',
            p: '/records',
        },
        {
            name: 'Reports',
            icon: 'ti-bar-chart-alt',
            id: 'third-party',
            children: [
                {
                    name: 'Owner/Clinic Map',
                    p: '/report/map',
                },
                {
                    name: 'Users',
                    p: '/report/users',
                }
            ],
        },
        {
            name: 'Settings',
            icon: 'nc-icon nc-settings',
            p: '/settings',
        }
    ],
};
