<template>
    <div>
        <div class="sidebar" style="background-color:#66615B !important;">
            <div class="logo logo_img_hsaapps">
                <!--                <a href="/admin/dashboard" v-if="usertype=='admin'">
                                    <div class="logo-img" style="text-align: center;">
                                        <img :src="logo" style="width: 150px;">
                                    </div>
                                </a>-->
                <router-link to="/platform/projects">
                    <div class="logo-img" style="text-align: center;">
                        <img :src="logo" style="width: 150px;">
                    </div>
                </router-link>

            </div>
            <div class="sidebar-wrapper set_border" ref="sidebarScrollArea" v-if="usertype=='admin'">
                <slot>

                </slot>
                <ul class="nav">
                    <slot name="links">
                        <sidebar-item v-for="(link, index) in sidebarLinksAdmin"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>
                    </slot>
                </ul>
            </div>
            <div class="sidebar-wrapper set_border" ref="sidebarScrollArea" v-if="usertype=='user'">

                <div class="setShadow_menu" style="padding: 5px;">
                    <!--                    <div class="logo-img projectimg" v-bind:style="{width: '100%', height: '150px', backgroundPosition: 'center center',
                                      backgroundRepeat: 'no-repeat', backgroundImage : 'url('+projectimg+')', backgroundSize: 'contain', borderRadius: '5px'}">
                                        </div>-->
                    <div class="row text-center" :title="getProjectName" style="padding: 10px;">
                        <div class="col-sm-2 text-center">
                            <p style="text-align: center; margin: 0"><img style="max-width: none;" :src="dpmlogo"
                                                                          class="logo_img"/></p>
                        </div>
                        <div class="col-sm-10 text-center">
                            <h6 style="margin: 0" class="project_name">Population<br>Management<br>(spay neuter)</h6>
                        </div>
                    </div>
                </div>

                <ul class="nav">

                    <slot name="links">
                        <sidebar-item v-for="(link, index) in DPMprojectsidebarLinks"
                                      :key="link.name + index"
                                      :location="location"
                                      :id="link.id"
                                      :collapsed="link.collapsed"
                                      :link="link">

                            <sidebar-item v-for="(subLink, index) in link.children"
                                          :key="subLink.name + index"
                                          :location="location"
                                          :parent="link.id"
                                          :link="subLink">
                            </sidebar-item>
                        </sidebar-item>
                    </slot>
                    <li><img :src="hsiLogo" style="height: 60px; width: 60px; margin-left: 100px; margin-top: 130px;"/>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main-panel">
            <project-navbar
                usertype="user">
            </project-navbar>
            <div class="project-details-content">
                <transition name="slide-fade" mode="out-in">
                    <router-view :projectId="projectId"></router-view>
                </transition>
            </div>
            <content-footer></content-footer>
        </div>
    </div>
</template>
<script>

import ProjectNavbar from "../../Layout/ProjectNavbar";
import ContentFooter from "../../Layout/ContentFooter";

import pmLogo from '../../../../assets/images/pm_logo.png';

import PmSideNav from '../../../../../src/PmSideNav';

import dpmlogo from '../../../../assets/images/pm-logo.png';
import hsiLogo from '../../../../assets/images/hsi-transparent.png';


export default {
    components: {
        'project-navbar': ProjectNavbar,
        'content-footer': ContentFooter
    },
    data() {
        return {
            isReadOnly: false,
            location: {...location},
            ps: null,
            projecttype: '',
            projectname: '',
            projectimg: '',
        };
    },
    props: {
        usertype: {
            type: String,
            default: 'user',
        },
        projecturl: {
            type: String,
            default: 'url',
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
        projectId: {
            type: String,
        },
        dpmlogo: {
            type: String,
            default: dpmlogo,
        },
        hsiLogo: {
            type: String,
            default: hsiLogo,
        },
    },
    provide() {
        return {
            autoClose: this.autoClose,
        };
    },
    methods: {
        mvdHome() {
            window.location = '/project/details/' + this.projectId;
        },
        getLocation() {
            return this.location;
        },
        async initScrollBarAsync() {
            let PerfectScroll = await import('perfect-scrollbar');
            PerfectScroll.initialize(this.$refs.sidebarScrollArea);
        }
    },

    computed: {
        DPMprojectsidebarLinks() {
            let loc = this.location;

            var tmp = [];

            for (var i = 0; i < PmSideNav.nav.length; i++) {
                if ((PmSideNav.nav[i].name == "Settings" || PmSideNav.nav[i].name == "Assignments") && this.isReadOnly) {
                    continue
                }
                tmp.push(PmSideNav.nav[i]);
            }

            let links = tmp.map((item, index) => {
                item.collapsed = true;
                if (item.children && item.children.length) {
                    for (let idx = 0; idx < item.children.length; idx++) {
                        let child = item.children[idx];
                        item.children[idx].path = '/project/pm/' + this.projectId + item.children[idx].p;
                    }
                } else {
                    item.path = '/project/pm/' + this.projectId + item.p;
                }
                return item;
            });
            return links;
        },
        title() {
            return window.appConfig.appName;
        },
        logo() {
            return window.appConfig.logo;
        },
        getProjectName() {
            return this.projectname;
        }
    },
    mounted() {

        let vm = this;

        axios
            .get("/project/details", {
                params: {
                    project_id: vm.projectId,
                }
            })
            .then(response => {
                let project = response.data.response.data.project;
                //localStorage.project = JSON.stringify(vm.project);
                this.$store.dispatch("setCurrentSelectedProject", project);

                vm.isReadOnly = false
                vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
                    if(r.role_name == 'VIEWER'){
                        vm.isReadOnly = true
                    }
                })

                //Bus.$emit("projectype", vm.project);
                this.$store.dispatch("user/getUserRoles");
                vm.projecttype = project.type;
                vm.projectname = project.name;
                vm.projectimg = project.image_url.includes("pm_logo.png") ? pmLogo : project.image_url;
                vm.projectId = project.id;
                vm.$store.dispatch("setProjectType", "Population Management");
                vm.$store.dispatch("setProjectName", vm.projectname);

                if(project.organisation){
                    vm.$store.dispatch("setOrganisationName", project.organisation.name);
                    vm.$store.dispatch("setOrganisationLogo", project.organisation.logo_url);
                }

            })
            .catch(error => {
                console.log(error.response.data.response);
                let errors = [];
                if (error.response.data.response.data !== null) {
                    for (let key in error.response.data.response.data) {
                        console.log("Key: " + key);
                        console.log("Value: " + error.response.data.response.data[key]);
                        errors.push(error.response.data.response.data[key]);
                    }
                    vm.errorMsg = errors.toString();
                } else {
                    vm.errorMsg = error.response.data.response.error.error_msg;
                    console.log(vm.errorMsg);
                }
                vm.callingApi = false;
            });

        this.initScrollBarAsync();

    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
};

</script>
<style>

.main-panel > .project-details-content {
    padding: 0 30px 30px;
    min-height: calc(100vh - 123px);
    margin-top: 30px;
}

@media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}

.project_name_div {
    width: 100%;
}

.project_name {
    text-align: center;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.projectimg {
    text-align: center;
    margin-top: 10px;
}

.img_fix_size {
    height: 100px;
    margin-top: 12px;
}

.setShadow_menu {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
}

.sidebar-normal {
    margin-left: 44px !important;
}

.sidebar-mini-icon {
    display: none !important;
}

.logo_img_hsaapps {
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
}

.set_border {
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.sidebar .nav li.active > a.active::after,
.sidebar .nav li.active > a.active::before {
    opacity: 0 !important;
}

.sidebar .nav li > a.active, .off-canvas-sidebar .nav li > a.active {
    font-weight: 800;
    font-size: 14px;
}
</style>

<style scoped>

.sidebar:after {
    /*background-color: #004d40 !important;*/
}

</style>
