<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <h5 class="set_font_title">Manage Communities</h5>
            </div>
            <div v-if="project && project.organisation_id && !isReadOnly" class="col-sm-12 col-md-4 text-right">
                <button class="btn btn-success" @click="showAddCommunityDialog(true)">+ Add Community</button>
            </div>
        </div>
        <div class="card row">
            <div class="card-body col-sm-12 col-xl-12">
                <table id="hcCommunityRecords" class="display" width="100%"></table>
            </div>
        </div>
        <el-dialog width="60%" :title="(model.communityId ? 'Update' : 'Add') + ' Community'" :visible.sync="dialogVisible" @opened="onDialogOpened">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div>
                                <p class="card-title"><b>(Search for your city/region and then pin it
                                    on the map using your cursor)</b></p>
                            </div>
                            <div>
                                <div class="pac-card" id="pac-card">
                                    <div>
                                        <div id="title">
                                            Search Locations
                                        </div>
                                        <div id="type-selector" class="pac-controls">
                                            <input type="radio" name="type" id="changetype-all"
                                                   checked="checked">
                                            <label for="changetype-all">All</label>
                                            <input type="radio" name="type"
                                                   id="changetype-establishment">
                                            <label for="changetype-establishment">Establishments</label>
                                            <input type="radio" name="type" id="changetype-address">
                                            <label for="changetype-address">Addresses</label>
                                        </div>
                                    </div>
                                    <div id="pac-container">
                                        <input id="pac-input" type="text"
                                               placeholder="Enter a location">
                                    </div>
                                </div>
                                <div id="regularMap" class="map"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <p>
                                To load Country, State and Province please select location pin point on Google Maps.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <label class="control-label">Country</label>
                            <br/>
                            <fg-input :disabled="fetchingAddress" v-model="model.selectedCountry"
                                      :placeholder="selectCountryLabel"/>
                        </div>
                        <div class="col-lg-3">
                            <label class="control-label">State/Province</label>
                            <br/>
                            <fg-input :disabled="fetchingAddress" v-model="model.selectedState"
                                      :placeholder="selectStateLabel"/>
                        </div>
                        <div class="col-lg-3">
                            <label class="control-label">City/District/Village</label>
                            <br/>
                            <fg-input :disabled="fetchingAddress" v-model="model.selectedLocality"
                                      :placeholder="selectLocalityLabel"/>
                        </div>
                        <div class="col-lg-3">
                            <label class="control-label">Community Name</label>
                            <br/>
                            <fg-input :disabled="fetchingAddress" v-model="model.community"
                                      placeholder="Enter Community Name"/>
                        </div>
    
                        <div class="col-12 text-right mt-3">
                            <el-button native-type="button" class="btn btn-success" :loading="show.actionLoading" @click="saveCommunity">
                                {{ show.actionLoading ? 'Saving' : 'Save' }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>


import {API_KEY} from '../Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';

GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['places'];

import mixin from '../../../mixins/GlobalMixin';
import communityService from "../../../services/community.service";
import {
  Dialog,
  Button,
  Image,
  Form,
  FormItem,
  Input,
  Select,
} from "element-ui";
import VOffline from 'v-offline';
import { mapGetters } from "vuex";
export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        [Image.name]: Image,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Input.name]: Input,
        [Select.name]: Select,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        },
    },
    data() {
        return {
            googleCountries: [],
            googleStates: [],
            googleLocality: [],
            fetchingAddress: false,
            selectCountryLabel: 'Select Country',
            selectStateLabel: 'Select State',
            selectLocalityLabel: 'Select Locality',

            model: {
                selectedCountry: null,
                selectedState: null,
                selectedLocality: null,
                selectedLocation: null,
                community: null,
                communityId: null
            },

            map: null,
            dialogVisible: false,
            project: null,

            show: {
                actionLoading: false
            },
            records: [],
            mapInitialized: false,
        }
    },
    mixins: [mixin],
    computed: {
        ...mapGetters("user", ["userData"]),
    },
    methods: {
        onDialogOpened() {
            const vm = this;
            if (!this.mapInitialized) {
                GoogleMapsLoader.load((google) => {
                    vm.initRegularMap(google);
                });
                this.mapInitialized = true;
            } else {
                // Re-attach pac-card and other controls if the map is already initialized
                this.updateMapCenterAndMarker();
            }
        },
        initRegularMap(google) {
            let vm = this;

            // Regular Map
            const myLatlng = new window.google.maps.LatLng(20.0532212, 64.4407944);
            const mapOptions = {
                zoom: 7,
                center: myLatlng, // we disable de scroll over the map, it is a really annoing when you scroll through page
            };

            vm.map = new window.google.maps.Map(document.getElementById('regularMap'), mapOptions);
            let map = vm.map;
            let card = document.getElementById('pac-card');
            let input = document.getElementById('pac-input');

            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

            let autocomplete = new google.maps.places.Autocomplete(input);

            // Bind the map's bounds (viewport) property to the autocomplete object,
            // so that the autocomplete requests use the current map bounds for the
            // bounds option in the request.
            autocomplete.bindTo('bounds', map);

            // Set the data fields to return when the user selects a place.
            autocomplete.setFields(
                ['address_components', 'geometry', 'icon', 'name']);

            autocomplete.addListener('place_changed', function () {
                let place = autocomplete.getPlace();
                if (!place.geometry) {
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);  // Why 17? Because it looks good.
                }
            });

            // Sets a listener on a radio button to change the filter type on Places
            // Autocomplete.
            function setupClickListener(id, types) {
                let radioButton = document.getElementById(id);
                radioButton.addEventListener('click', function () {
                    autocomplete.setTypes(types);
                });
            }

            setupClickListener('changetype-all', []);
            setupClickListener('changetype-address', ['address']);
            setupClickListener('changetype-establishment', ['establishment']);

            google.maps.event.addListener(map, 'click', function (event) {
                vm.placeMarker(event.latLng);
            });
            
            vm.updateMapCenterAndMarker();
        },

        getGeoLocation: function (latLng) {
            let vm = this;

            vm.selectCountryLabel = 'Loading..';
            vm.selectStateLabel = 'Loading...';
            vm.selectLocalityLabel = 'Loading...';

            vm.model.selectedLocation = latLng;

            vm.googleCountries = [];
            vm.model.selectedCountry = null;

            vm.googleStates = [];
            vm.model.selectedState = null;

            vm.googleLocality = [];
            vm.model.selectedLocality = null;

            vm.fetchingAddress = true;

            delete window.axios.defaults.headers.common['Authorization'];

            window.axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    latlng: latLng,
                    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
                },
            }).then(response => {
                for (let i = 0; i < Object.keys(response.data.results).length; i++) {
                    for (let j = 0; i < Object.keys(response.data.results[i].address_components).length; j++) {
                        if (response.data.results[i].address_components[j].types.indexOf("country") > -1) {
                            vm.googleCountries.push(response.data.results[i].address_components[j].long_name);
                        }
                        if (response.data.results[i].address_components[j].types.indexOf("administrative_area_level_1") > -1) {
                            vm.googleStates.push(response.data.results[i].address_components[j].long_name);
                        }
                        if (response.data.results[i].address_components[j].types.indexOf("locality") > -1) {
                            vm.googleLocality.push(response.data.results[i].address_components[j].long_name);
                        }
                    }
                }
                if (vm.googleStates.length < 1) {
                    vm.googleStates = vm.googleCountries;
                }
                if (vm.googleLocality.length < 1) {
                    vm.googleLocality = vm.googleStates;
                }

                vm.fetchingAddress = false;

                vm.selectCountryLabel = 'Select Country';
                vm.selectStateLabel = 'Select State';
                vm.selectLocalityLabel = 'Select Locality';

                vm.model.selectedCountry = vm.googleCountries[0];
                vm.model.selectedState = vm.googleStates[0];
                vm.model.selectedLocality = vm.googleLocality[0];

            }).catch(error => {
                console.log(error);

                if (vm.googleStates.length < 1) {
                    vm.googleStates = vm.googleCountries;
                }
                if (vm.googleLocality.length < 1) {
                    vm.googleLocality = vm.googleStates;
                }

                vm.fetchingAddress = false;
                vm.selectCountryLabel = 'Select Country';
                vm.selectStateLabel = 'Select State';
                vm.selectLocalityLabel = 'Select Locality';

                vm.model.selectedCountry = vm.googleCountries[0];
                vm.model.selectedState = vm.googleStates[0];
                vm.model.selectedLocality = vm.googleLocality[0];
            });

            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + vm.$store.getters.getJwt;

        },

        updateMapCenterAndMarker() {
            let vm = this;

            if (vm.model.selectedLocation) {
                // Extract latitude and longitude from the selectedLocation
                const location = vm.model.selectedLocation.split(',');
                const latlng = {
                    lat: parseFloat(location[0]),
                    lng: parseFloat(location[1])
                };

                // Center the map on the selected location
                vm.map.setCenter(latlng);
                vm.map.setZoom(15);

                // Place a marker on the selected location
                vm.placeMarker(latlng);
            } else if (navigator.geolocation) {
                // Use geolocation to center the map if no location is selected
                navigator.geolocation.getCurrentPosition(function (position) {
                    let pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    vm.map.setCenter(pos);
                    vm.map.setZoom(15);
                }, function () {
                    vm.handleLocationError(true);
                });
            }
        },

        handleLocationError(browserHasGeolocation) {
            if (browserHasGeolocation) {
                //alert('Error: The Geolocation service failed.');
                console.log('Error: The Geolocation service failed.');
            } else {
                //alert('Error: Your browser doesn\'t support geolocation.');
                console.log('Error: Your browser doesn\'t support geolocation.');
            }
        },

        placeMarker(location) {
            let vm = this;

            // Clear the previous marker
            if (vm.selectedMarker && vm.selectedMarker.setMap) {
                vm.selectedMarker.setMap(null);
            }

            // Create a new marker
            vm.selectedMarker = new google.maps.Marker({
                position: location,
                map: vm.map
            });

            // Store the latitude and longitude as selectedLocation
            let latLng = `${vm.selectedMarker.getPosition().lat()},${vm.selectedMarker.getPosition().lng()}`;
            vm.model.selectedLocation = latLng;

            // Fetch geo-location details
            vm.getGeoLocation(latLng);
        },

        showAddCommunityDialog(isNew = false) {
            const vm = this;
            vm.dialogVisible = true;
            if (isNew) {
                vm.model = {
                    selectedCountry: null,
                    selectedState: null,
                    selectedLocality: null,
                    selectedLocation: null,
                    community: null,
                    communityId: null
                }
            }
        },

        addCommunity() {
            const vm = this;
            const payload = {
                lat_lng: vm.model.selectedLocation,
                country: vm.model.selectedCountry,
                state: vm.model.selectedState,
                city: vm.model.selectedLocality,
                project_id: vm.projectId,
                added_by: vm.userData.user.id,
                community: vm.model.community
            };
            vm.show.actionLoading = true;
            communityService.addCommunity(vm.projectId, payload)
            .then((apiResponse) => {
                apiResponse = apiResponse.response;
                vm.show.actionLoading = false;
                if (!apiResponse.error.error_code) {
                    vm.dialogVisible = false;
                    vm.showAlert('Success', `New community ${vm.model.community} added`, true);
                    vm.getCommunityList();
                    vm.resetModel();
                } else {
                    vm.showAlert('Error', apiResponse.error.msg, false);
                }
            })
            .catch((error) => {
                vm.show.actionLoading = false;
                vm.showAlert('Error', error.response.error.error_msg, false);
            })
        },

        getCommunityList() {
            let vm = this;
            const loading = this.$loading({
                lock: true,
                text: 'Please wait loading records...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            communityService.getCommunityList(vm.projectId)
            .then((apiResponse) => {
                loading.close();
                vm.records = apiResponse.response.data.communities;
                vm.mapRecordWithTable();
            });
        },

        mapRecordWithTable() {
            const vm = this;

            // Define the columns array dynamically based on the isReadOnly flag
            const columns = [
                {
                    title: 'Community',
                    data: 'community'
                },
                {
                    title: 'City',
                    data: 'city'
                },
                {
                    title: 'State',
                    data: 'state'
                },
                {
                    title: 'Country',
                    data: 'country'
                },
                {
                    title: 'Location',
                    data: 'lat_lng',
                    render: function (data, type) {
                        if (data) {
                            return `<a href="https://www.google.com/maps/search/?api=1&query=${data}" target="_blank">Map <i class="fa fa-external-link"></i> </a>`
                        } else {
                            return 'N/A';
                        }
                    }
                },
                {
                    title: 'Action',
                    data: null,
                    render: function (data, type, row) {
                        if (vm.isReadOnly) {
                            return `<button type="button" class="btn btn-warning viewHcCommunity" data-data="${encodeURIComponent(JSON.stringify(data))}">View</button>`;
                        }
                        return `<button type="button" class="btn btn-warning viewHcCommunity" data-data="${encodeURIComponent(JSON.stringify(data))}">View/Edit</button>`;
                    }
                }
            ]

            $("#hcCommunityRecords").DataTable({
                search: {
                    caseInsensitive: false
                },
                data: vm.records,
                destroy: true,
                dom: 'lBfrtip',
                "bInfo": false,
                "scrollX": true,
                language: {
                    emptyTable: "No records available",
                },
                columns: columns, // Use the dynamically defined columns
                buttons: [
                    {
                        extend: 'excelHtml5',
                        text: 'Export to excel',
                        className: 'btn btn-default',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                            orthogonal: 'export'
                        },
                    },
                    {
                        extend: 'colvis',
                        text: 'Filter Columns',
                        className: 'btn btn-default'
                    }
                ],
                'fnRowCallback': function (nRow) {
                    const viewEditBtn = $('.viewHcCommunity', nRow);
                    vm.editCommunityDialog(viewEditBtn);
                    return nRow;
                }
            })
        },
        editCommunityDialog(editEl) {
            let vm = this;
            editEl[0].addEventListener('click', function () {
                let record = JSON.parse(decodeURIComponent(this.getAttribute("data-data")));
                vm.model.selectedCountry = record.country;
                vm.model.selectedState = record.state;
                vm.model.selectedLocality = record.city;
                vm.model.selectedLocation = record.lat_lng;
                vm.model.community = record.community;
                vm.model.communityId = record.id;
                vm.showAddCommunityDialog();
            }, false);
        },

        saveCommunity() {
            const vm = this;
            if (vm.model.communityId) {
                vm.updateCommunity();
            } else {
                vm.addCommunity();
            }       
        },

        updateCommunity() {
            const vm = this;
            const payload = {
                lat_lng: vm.model.selectedLocation,
                country: vm.model.selectedCountry,
                state: vm.model.selectedState,
                city: vm.model.selectedLocality,
                community: vm.model.community
            };
            vm.show.actionLoading = true;
            communityService.updateCommunity(vm.projectId, vm.model.communityId, payload)
            .then((apiResponse) => {
                apiResponse = apiResponse.response;
                vm.show.actionLoading = false;
                if (!apiResponse.error.error_code) {
                    vm.dialogVisible = false;
                    vm.showAlert('Success', `Community ${vm.model.community} updated`, true);
                    vm.getCommunityList();
                    vm.resetModel();
                } else {
                    vm.showAlert('Error', apiResponse.error.msg, false);
                }
            })
            .catch((error) => {
                vm.show.actionLoading = false;
                vm.showAlert('Error', error.response.error.error_msg, false);
            })
        },

        resetModel() {
            vm.model = {
                selectedCountry: null,
                selectedState: null,
                selectedLocality: null,
                selectedLocation: null,
                community: null,
                communityId: null
            }
        }


    },
    mounted() {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        vm.isReadOnly = false
        vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
            if (r.role_name == 'VIEWER') {
                vm.isReadOnly = true
            }
        });
        vm.getCommunityList();
        vm.mapRecordWithTable();
    }
}

</script>
<style scoped>

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

.set_font_title {
    font-size: 20px;
    font-weight: 600;
    color: black;
}

</style>