<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-md-6">
                        <h5 class="set_font_title">Drugs/Vaccines</h5>
                    </div>
                    <div class="col-md-6">
                        <div class="add-drugs-animals-button">
                            <button
                                type="button"
                                class="btn btn-success btn-round"
                                style="background-color: #2a9055 !important;"
                                @click="addVaccinationDrug"
                            >
                                Add Vaccination/Drug
                            </button>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-12">
                        <p>Add Vaccines/Drugs the organization intends to use on various projects. This will be used in
                            the application later when records are created to record what drugs/vaccines were
                            administrated.</p>
                    </div>
                </div>

                <div class="row" v-for="(item, index) in drugData" :key="index">
                    <div class="col-md-10 col-sm-6">
                        <p class="animal_name">{{ item.vaccination_drug_name }}</p>
                    </div>
                    <template v-if="item.vaccination_drug_name!=='Rabies'">
                        <div class="col-md-2 col-sm-6 icon-main">
                            <span
                                class="ti-pencil icon-pencil"
                                @click="updateDrug(item)"
                            ></span>
                            <span
                                class="ti-trash icon-trash"
                                @click="deleteDrug(item.id)"
                            ></span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div v-if="durgModal" class="modal animal-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content animle-modal-content">
                    <div class="modal-header animal-header">
                        <h4 class="modal-title">Edit Vaccination/Drug</h4>
                    </div>
                    <div class="modal-body animal-modal-body">
                        <div class="form-group row animal-row">
                            <label>Drug/Vaccine Name</label><br/>
                            <input
                                class="form-control col-sm-12"
                                type="text"
                                name="animal-name"
                                v-model="vaccination_drug_name"
                            />
                            <br/>
                            <p>
                                (Note: This will make new Drug/Vaccine visible to entire
                                organisation)
                            </p>
                        </div>
                    </div>
                    <div class="animal-footer">
                        <button
                            type="button"
                            class="btn btn-default"
                            style="background-color: #eb6532 !important;"
                            data-dismiss="modal"
                            @click="hideDrugModal"
                        >
                            Close
                        </button>
                        <button
                            v-bind:disabled="!onLine"
                            type="button"
                            class="btn btn-default"
                            style="background-color: #2a9055 !important;"
                            data-dismiss="modal"
                            @click="editDrug"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="addDrugModal" class="modal animal-modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content animle-modal-content">
                    <div class="modal-header animal-header">
                        <h4 class="modal-title">Add Vaccination/Drug</h4>
                    </div>
                    <div class="modal-body animal-modal-body">
                        <div class="form-group row animal-row">
                            <label>Drug/Vaccine Name</label><br/>
                            <input
                                class="form-control col-sm-12"
                                type="text"
                                name="animal-name"
                                v-model="add_drug_name"
                            />
                            <br/>
                            <p>
                                (Note: This will make new Drug/Vaccine visible to entire
                                organisation)
                            </p>
                        </div>
                    </div>
                    <div class="animal-footer">
                        <button
                            type="button"
                            class="btn btn-default"
                            style="background-color: #eb6532 !important;"
                            data-dismiss="modal"
                            @click="hideAddDrugModal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            class="btn btn-default"
                            style="background-color: #2a9055 !important;"
                            data-dismiss="modal"
                            @click="addDrug"
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <help-modal ref="helpModal"></help-modal>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

import mixin from "../../mixins/GlobalMixin";
import Swal from "sweetalert2"

export default {
    components: {},
    data: function () {
        return {
            durgModal: false,
            drugData: [],
            vaccination_drug_name: "",
            drugId: "",
            addDrugModal: false,
            add_drug_name: "",
        };
    },
    mixins: [mixin],
    methods: {
        addVaccinationDrug() {
            this.addDrugModal = true;
        },
        hideAddDrugModal() {
            this.addDrugModal = false;
        },
        addDrug() {
            var vm = this;
            axios
                .post(
                    "/vaccination_drug/add?vaccination_drug_name=" +
                    vm.add_drug_name +
                    "&organisation_id=" +
                    vm.organisationId
                )
                .then((response) => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert("Success", response.data.response.error.msg, true);
                        vm.getVaccinationDrug(vm.organisationId);
                        vm.add_drug_name=''
                        this.addDrugModal = false;
                    }
                })
                .catch((error) => {
                });
        },
        async getVaccinationDrug(id) {
            var vm = this;
            await vm.$store.dispatch('getOrgVaccinationDurg', id);
            const drugList = vm.$store.getters.getVaccinationDrugs;
            vm.drugData = drugList;
        },
        hideDrugModal() {
            this.durgModal = false;
        },
        updateDrug(data) {
            this.durgModal = true;
            this.vaccination_drug_name = data.vaccination_drug_name;
            this.drugId = data.id;
        },
        deleteDrug(durgId) {
            let vm = this;
            new Swal({
                title: "Alert!",
                text: "Are you sure you want to delete vaccination drug ? ",
                type: "warning",
                icon: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "yes",
                buttonsStyling: false,
            }).then(function (result) {
                if (result.value) {
                    axios
                        .delete(
                            "/vaccination_drug/remove/" +
                            vm.organisationId +
                            "?vaccination_drug_id=" +
                            durgId
                        )
                        .then((response) => {
                            if (response.data.response.error.error_code == 0) {
                                vm.showAlert("Success", response.data.response.error.msg, true);
                                vm.getVaccinationDrug(vm.organisationId);
                            } else {
                                alert(response.data.response.error.error_msg)
                            }
                        })
                        .catch((error) => {
                            vm.showAlert(
                                "Error",
                                error.response.data.response.error.error_msg,
                                false
                            );
                        });
                }
            });
        },
        editDrug() {
            var vm = this;
            axios
                .put(
                    "/vaccination_drug/edit/" +
                    vm.organisationId +
                    "?vaccination_drug_id=" +
                    vm.drugId +
                    "&edited_drug_name=" +
                    vm.vaccination_drug_name
                )
                .then((response) => {
                    if (response.data.response.error.error_code == 0) {
                        vm.showAlert("Success", response.data.response.error.msg, true);
                        vm.getVaccinationDrug(vm.organisationId);
                        this.durgModal = false;
                    }
                })
                .catch((error) => {
                });
        },
        showUrl() {
            this.$refs.helpModal.loadUrl(window.USER_MANUAL_URL + "#h.bswdx03ab2pw");
        },
    },
    computed: {
        ...mapGetters("user", ["organisation"]),
    },
    mounted() {
        const vm = this;
        vm.organisationId = this.organisation.id;
        this.getVaccinationDrug(vm.organisationId);
    },
};
</script>
<style scoped>
.card-body {
    text-align: left !important;
}

a {
    color: white;
}

.a_color {
    color: black !important;
}

/* Pradeep Start */
@media (min-width: 320px) {
    .set_row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

/* Pradeep end */
.set_font_title {
    margin-top: 15px;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.animal_name {
    margin-top: 5px !important;
    text-align: left;
}

.icon-main {
    margin-top: 5px !important;
    text-align: right;
}

.icon-pencil {
    cursor: pointer;
}

.icon-trash {
    cursor: pointer;
    margin-left: 10px;
}

.modal-footer {
    border-top: 1px solid #dddddd;
    padding: 10px !important;
}

.modal-title {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.animal-footer {
    text-align: right;
    margin-right: 4%;
    margin-bottom: 1%;
}

.add-drugs-animals-button {
    text-align: left;
    float: right;
    /* margin-left: 12px; */
}

.animal-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    padding: 80px;
    height: 100%;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.66); /* Black w/ opacity */
    display: block;
    opacity: 1 !important;
}
@media (max-width: 767px) {
    .animal-modal{
        padding: 0;
    }
    .modal-title {
        font-size: 1.4em;
    }
    .modal-body{
        padding: 20px 30px;
    }
}

.animal-modal-body {
    color: #000;
}
</style>
