<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <h5 class="set_font_title">Owner/Clinic Map Report</h5>
            </div>
        </div>

        <div class="row setMarginTop">
            <div class="col">
                <p>Select Type</p>
                <el-select v-model="selectedType" @change="onTypeChange">
                    <el-option value="owner" label="Owner"></el-option>
                    <el-option value="clinic" label="Clinic"></el-option>
                </el-select>
            </div>
            <div class="col-md-3">
                <p>Select Date</p>
                <el-select v-model="dateFilterOption">
                    <el-option value="All">All</el-option>
                    <el-option value="Date Range">Date Range</el-option>
                </el-select>
            </div>
            <div class="col" v-if="dateFilterOption === 'Date Range'">
                <p>Select Date(s)</p>
                <v-md-date-range-picker
                    :presets="dateRangePresets"
                    :start-date="startDatePast"
                    :end-date="endDatePast"
                    @change="handleChange"
                    style="width:100% !important">
                </v-md-date-range-picker>
            </div>
            <div class="col">
                <p></p>
                <el-button @click="getMapReport" class="mt-4">Generate</el-button>
            </div>
        </div>
        <div class="row mt-5 card">
            <div class="col card-body">
                <GmapMap
                    :key="mapKey"
                    gestureHandling="cooperative"
                    :center="center"
                    :zoom="3"
                    style="width: 100%; height: 600px"
                >
                    <GmapCluster :zoom-on-click="true" :max-zoom="21">
                        <gmap-info-window
                            v-if="currentMarkerContent"
                            :options="infoOptions"
                            :position="infoWindowPos"
                            :opened="infoWinOpen"
                            @closeclick="infoWinOpen=false">
                            <div class="row">
                                <div class="col-sm-12">
                                    <table>
                                        <tr>
                                            <td rowspan="9" style="padding: 5px;">
                                                <img :src="currentMarkerContent.photo" height="150"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Gender:</td>
                                            <td>{{ currentMarkerContent.gender }}</td>
                                        </tr>
                                        <tr>
                                            <td>treatment:</td>
                                            <td>{{ currentMarkerContent.treatment }}</td>
                                        </tr>
                                        <tr>
                                            <td>deworming:</td>
                                            <td>{{ currentMarkerContent.deworming }}</td>
                                        </tr>
                                        <tr>
                                            <td>vaccination:</td>
                                            <td>{{ currentMarkerContent.vaccination }}</td>
                                        </tr>
                                        <tr class="owner" v-if="selectedType === 'owner'">
                                            <td>
                                                <b>Owner Details:</b>
                                                <p>Name: {{ currentMarkerContent.owner.name }}</p>
                                                <p>Mobile: {{ currentMarkerContent.owner.mobile }}</p>
                                                <p>Locality: {{ currentMarkerContent.owner.locality }}</p>
                                                <p>Address: {{ currentMarkerContent.owner.address }}, {{ currentMarkerContent.owner.postalcode }}</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </gmap-info-window>
                        <gmap-marker
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="m.postion"
                            :icon="m.icon"
                            @click="markersclick(m,index)">
                        </gmap-marker>
                    </GmapCluster>
                </GmapMap>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VOffline from 'v-offline';
import {Button} from 'element-ui';
import VMdDateRangePicker from "v-md-date-range-picker";
import healthClinicService from "../../../../services/health-clinic.service";
import dateRangeDefaultPresetsMixin from '../../../../mixins/DateRangeDefaultPresetsMixin';

Vue.use(VMdDateRangePicker);

export default {
    components: {
        [Button.name]: Button,
        VOffline
    },
    props: {
        projectId: {
            type: String | Number
        },
    },
    data() {
        return {
            dateFilterOption: 'All',
            dateRangePresets: [],
            startDatePast: '',
            endDatePast: '',
            startDate: '',
            endDate: '',
            selectedType: 'owner',
            records: [],
            markers: [],
            center: {lat: 33.8291523, lng: -116.8004315},
            infoWindowPos: null,
            infoWinOpen: false,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            markerContent: {
                photo: 'http://www.hsi.org/assets/images/logos/hsi_global.png',
                gender: '',
                treatment: '',
                vaccination: '',
                deworming: '',
                owner: {
                    name: '',
                    mobile: '',
                    address: ''
                }
            },
            currentMarkerContent: null,
            mapKey: new Date().getMilliseconds()
        }
    },
    mixins: [dateRangeDefaultPresetsMixin],
    methods: {

        getMapReport() {
            const vm = this;
            vm.markers = [];
            if (vm.dateFilterOption === 'All') {
                vm.startDate = '';
                vm.endDate = '';
            } else if (vm.startDate === '' || vm.endDate === '') {
                vm.startDate = vm.formatDate(vm.dateRangePresets[4].range[0]);
                vm.endDate = vm.formatDate(vm.dateRangePresets[4].range[1]);
            }
            const params = {
                type: vm.selectedType,
                start_date: vm.startDate,
                end_date: vm.endDate
            }

            const loading = vm.$loading({
                lock: true,
                text: 'Please wait generating users report...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            healthClinicService.getMapReport(vm.projectId, params)
            .then((apiResposne) => {
                if (!apiResposne.error.error_code) {
                    const records = apiResposne.data.records;
                    if (records.length) {
                        records.map((item) => {
                            const latLng = vm.selectedType === 'owner' ? `${item.owner.location_lat},${item.owner.location_lng}`.split(",")  : item.caught_location.split(",");
                            const latitude = latLng[0];
                            const longitude = latLng[1];
                            const iconUrl = item.animal.gender.toLowerCase() == 'male' ? 'https://hsapps-prod-media.s3.amazonaws.com/dog_markers/blue_dog.png' : 'https://hsapps-prod-media.s3.amazonaws.com/dog_markers/yellow_dog.png';
                            let obj = {
                                postion: {lat: parseFloat(latitude) || 0, lng: parseFloat(longitude) || 0},
                                icon: iconUrl,
                                gender: item.animal.gender,
                                photo: item.image_url,
                                treatment: item.treatment,
                                vaccination: item.vaccination_other,
                                deworming: item.deworming
                            }
                            if (vm.selectedType === 'owner') {
                                const owner = item.owner;
                                obj['owner'] = {
                                    name: owner.name,
                                    mobile: owner.telephone_mobile,
                                    locality: owner.google_locality,
                                    address: owner.google_address,
                                    postalcode: owner.google_postal_code
                                }
                            }
                            vm.markers.push(obj);
                        });
                    }
                    if (vm.markers.length != 0) {
                        vm.center = vm.markers[0].postion;
                    }
                }
                loading.close();
            })
            .catch(() => {
                loading.close();
            });
        },
        handleChange(values) {
            this.startDate = this.formatDate(values[0]._d);
            this.endDate = this.formatDate(values[1]._d);
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },
        markersclick(marker, idx) {
            this.infoWindowPos = marker.postion;
            this.infoContent = marker.icon;

            this.currentMarkerContent = marker;

            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            } else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        onTypeChange() {
            this.infoWinOpen = false;
            this.infoWindowPos = null;
            this.infoWinOpen = false;
            this.currentMarkerContent = this.markerContent;
            this.mapZoom = 3;
            this.mapKey = new Date().getMilliseconds()
            this.getMapReport();
        }

    },
    async mounted() {
        const vm = this;

        // this line was calling the API twice and was doubling the markers on initial load
        // await vm.onTypeChange();

        vm.$store.dispatch("setScreenName", "Map Report");
        const date = new Date();
        vm.startDatePast = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('en-US');
        vm.endDatePast = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('en-US');
        vm.dateRangePresets = vm.getDateRangePresets();
        vm.getMapReport();
    }
}
</script>
<style scoped>
.owner {
    border-top: 1px solid;
}
.owner p {
    margin: 0;
}
</style>
