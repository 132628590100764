var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project_list set_self_project_res"},[_c('div',{staticClass:"navbar navbar-expand-lg navbar-transparent nav_link_list setWhite desktopnavbar boxshadowsetheader responsive_menu_item",attrs:{"id":"scrolldiv"}},[_c('div',{staticClass:"col-md-6"},[_c('ul',{staticClass:"navbar-nav"},[(_vm.projectCount && (_vm.projectCount.organisation || _vm.showOrgTab))?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"custom":"","to":{ path: 'projects', query: { type: 'organisation' } },"tag":"a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{class:[
                  'nav-link',
                  isExactActive && 'nav-link-active-Projects' ],style:(isExactActive ? 'color: #f58220' : ''),attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t("platform.projects.list.nav.organisation"))+" ("+_vm._s(_vm.projectCount.organisation)+")")])]}}],null,false,248472258)})],1):_vm._e(),(_vm.projectCount && _vm.projectCount.shared)?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"custom":"","to":{ path: 'projects', query: { type: 'shared' } },"tag":"a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:[
                  'nav-link',
                  isExactActive && 'nav-link-active-Projects' ],style:(isExactActive ? 'color: #f58220' : ''),attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t("platform.projects.list.nav.shared"))+" ("+_vm._s(_vm.projectCount.shared)+") ")])]}}],null,false,2730401442)})],1):_vm._e(),(_vm.projectCount && _vm.projectCount.private)?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"custom":"","to":{ path: 'projects', query: { type: 'private' } },"tag":"a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:[
                  'nav-link',
                  isExactActive && 'nav-link-active-Projects' ],style:(isExactActive ? 'color: #f58220' : ''),attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t("platform.projects.list.nav.private"))+" ("+_vm._s(_vm.projectCount.private)+") ")])]}}],null,false,2903289410)})],1):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[(_vm.ownProjects.length > 0)?[_c('div',{staticClass:"top-bar set_search_textbox"},[[_c('h5')],_c('div',[_c('router-link',{attrs:{"custom":"","to":"newproject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var route = ref.route;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:isActive,attrs:{"href":href},on:{"click":navigate}},[_c('button',{staticClass:"btn btn-round btn-success",attrs:{"type":"button"}},[_vm._v(" + "+_vm._s(_vm.$t("platform.projects.list.nav.newProject"))+" ")])])]}}],null,false,2177288096)})],1)],2)]:[_c('div',{staticClass:"top-bar set_search_textbox"},[_c('h5',{staticStyle:{"margin":"0"}}),_c('div',[[_c('span',{staticClass:"set_textbox_left"},[_c('el-autocomplete',{attrs:{"fetch-suggestions":_vm.remoteMethod,"placeholder":_vm.$t('platform.projects.list.nav.searchProject'),"disabled":"disabled"},model:{value:(_vm.selectedProject),callback:function ($$v) {_vm.selectedProject=$$v},expression:"selectedProject"}})],1)],_c('router-link',{attrs:{"custom":"","to":"newproject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var route = ref.route;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{class:isActive,attrs:{"href":href},on:{"click":navigate}},[_c('button',{staticClass:"btn btn-round btn-default",attrs:{"type":"button"}},[_vm._v(" + "+_vm._s(_vm.$t("platform.projects.list.nav.newProject"))+" ")])])]}}])})],2)])]],2)]),(_vm.organisation && _vm.selectedProjectType === 'organisation')?[_c('el-tabs',{staticClass:"project-tab",attrs:{"type":"border-card"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('el-tab-pane',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('img',{attrs:{"src":_vm.organisation.logo_url,"width":"30"}}),_vm._v(" "+_vm._s(_vm.organisation.name))]),_c('org-reporting')],1),_c('el-tab-pane',{attrs:{"lazy":"","label":_vm.$t('platform.projects.tab.projects')}},[_c('div',{staticClass:"set_msg organisation-project"},[_c('div',{staticClass:"row mr-2 ml-2"},_vm._l((_vm.filteredOwnProjects),function(project,index){return _c('div',{key:index,staticClass:"col-sm-12 col-md-6 col-lg-6 col-xl-4"},[_c('project-card',{attrs:{"project-id":_vm.selectedProjectType=='shared'?project.project_id:project.id,"project-type":project.type,"is-org-project":project.organisation_id != null,"org-name":project.organisation_name ? project.organisation_name : null,"title":project.name,"field-users":project.field_user_count,"animals-treated":project.animals_treated_count,"created-on":_vm.convertDate(
                    project.created_at,
                    project.date_format,
                    project.timezone
                  ),"last-active":_vm.activeAgo(project.updated_at, project.timezone),"project-image":project.image_url,"user-role":project.user_role?project.user_role:'Owner'},on:{"get-projects":function($event){return _vm.getProjects()}}})],1)}),0),(_vm.filteredOwnProjects.length == 0)?_c('div',{staticClass:"row",staticStyle:{"margin-left":"11px","margin-right":"11px"}},[_c('h5',{staticStyle:{"font-size":"19px","margin-left":"73px"}},[_vm._v(" No Projects found ")])]):_vm._e()])]),_c('el-tab-pane',{attrs:{"label":_vm.$t('platform.projects.tab.fieldTeams')}},[_c('field-teams')],1),_c('el-tab-pane',{attrs:{"label":_vm.$t('platform.projects.tab.drugsVaccines')}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('health-and-care')],1)])]),_c('el-tab-pane',{attrs:{"label":_vm.$t('platform.projects.tab.sharing')}},[_c('project-sharing')],1),_c('el-tab-pane',{attrs:{"label":_vm.$t('platform.projects.tab.organisationSettings')}},[_c('organisation-settings')],1)],1)]:_vm._e(),(_vm.selectedProjectType !== 'organisation')?_c('div',{staticClass:"set_msg private-project"},[_c('div',{staticClass:"row mt-5 mr-2 ml-2"},_vm._l((_vm.filteredOwnProjects),function(project,index){return _c('div',{key:index,staticClass:"col-sm-12 col-md-6 col-lg-6 col-xl-4"},[_c('project-card',{attrs:{"project-id":_vm.selectedProjectType=='shared'?project.project_id:project.id,"project-type":project.type,"is-org-project":project.organisation_id != null,"org-name":project.organisation_name ? project.organisation_name : null,"title":project.name,"field-users":project.field_user_count,"animals-treated":project.animals_treated_count,"created-on":_vm.convertDate(
                project.created_at,
                project.date_format,
                project.timezone
              ),"last-active":_vm.activeAgo(project.updated_at, project.timezone),"project-image":project.image_url,"user-role":project.user_role?project.user_role:'Owner'},on:{"get-projects":function($event){return _vm.getProjects()}}})],1)}),0)]):_vm._e(),(
        (_vm.ownProjects.length == 0 && this.value == '' && _vm.fetchProjectsApiExecuted)
         || !(_vm.projectCount && (_vm.projectCount.organisation || _vm.projectCount.shared || _vm.projectCount.private))
      )?[(!_vm.organisation)?_c('div',{staticClass:"new-user-instructions"},[_c('h2',{staticClass:"text-center"},[_vm._v("Welcome to HSIApps!")]),_c('p',[_vm._v(" Are you an admin user or a field user? ")]),_vm._m(0),_vm._m(1),_c('p',[_vm._v(" Then open the Field User app and app and login with your HSIApps account to make sure you can login successfully or contact your manager if you have any install problems. ")]),_c('p',[_vm._v(" Note: You may see the message “You have not been assigned any projects” if you are not yet assigned to a project. That is normal, you will get an email when you are assigned to a project and you can continue at that point. ")])]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" If you are an admin user, start project by clicking on "),_c('b',[_c('span',{staticClass:"text-primary"},[_vm._v("+ NEW PROJECT")])]),_vm._v(" button. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" If you are a field user, "),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=in.fusionbit.hsapps","target":"_blank"}},[_vm._v("click here")]),_vm._v(" to download the app from the Google Play Store. ")])}]

export { render, staticRenderFns }