<template>
  <div class="project_list set_self_project_res">
    <div
      class="navbar navbar-expand-lg navbar-transparent nav_link_list setWhite desktopnavbar boxshadowsetheader responsive_menu_item"
      id="scrolldiv"
    >
      <div class="col-md-6">
        <ul class="navbar-nav" style="">
<!--          <li v-if="projectCount && ((projectCount.organisation || showOrgTab) || projectCount.shared || projectCount.private)">-->
<!--              <p>{{ $t("platform.projects.list.nav.label") }}:</p>-->
<!--          </li>-->
          <li class="nav-item" v-if="projectCount && (projectCount.organisation || showOrgTab)">
            <router-link
              custom
              v-slot="{ href, navigate, isExactActive }"
              :to="{ path: 'projects', query: { type: 'organisation' } }"
              tag="a"
            >
              <a
                :href="href"
                @click="navigate"
                :style="isExactActive ? 'color: #f58220' : ''"
                :class="[
                  'nav-link',
                  isExactActive && 'nav-link-active-Projects',
                ]"
                >{{ $t("platform.projects.list.nav.organisation") }} ({{projectCount.organisation}})</a
              >
            </router-link>
          </li>
          <li class="nav-item" v-if="projectCount && projectCount.shared">
            <router-link
              custom
              v-slot="{ href, navigate, isExactActive }"
              :to="{ path: 'projects', query: { type: 'shared' } }"
              tag="a"
            >
              <a
                :href="href"
                @click="navigate"
                :style="isExactActive ? 'color: #f58220' : ''"
                :class="[
                  'nav-link',
                  isExactActive && 'nav-link-active-Projects',
                ]"
                >{{ $t("platform.projects.list.nav.shared") }} ({{projectCount.shared}})
              </a>
            </router-link>
          </li>
          <li class="nav-item" v-if="projectCount && projectCount.private">
            <router-link
              custom
              v-slot="{ href, navigate, isExactActive }"
              :to="{ path: 'projects', query: { type: 'private' } }"
              tag="a"
            >
              <a
                :href="href"
                @click="navigate"
                :style="isExactActive ? 'color: #f58220' : ''"
                :class="[
                  'nav-link',
                  isExactActive && 'nav-link-active-Projects',
                ]"
                >{{ $t("platform.projects.list.nav.private") }} ({{projectCount.private}})
              </a>
            </router-link>
          </li>
        </ul>
        <!-- pradeep -->
      </div>
      <div class="col-md-6">
        <template v-if="ownProjects.length > 0">
          <div class="top-bar set_search_textbox">
            <template>
              <h5></h5>
            </template>
            <div>
<!--              <template>-->
<!--                <span class="set_textbox_left">-->
<!--                  <el-autocomplete-->
<!--                    v-model="selectedProject"-->
<!--                    :fetch-suggestions="remoteMethod"-->
<!--                    :placeholder="$t('platform.projects.list.nav.searchProject')"-->
<!--                    @select="showProjectDetails"-->
<!--                  ></el-autocomplete>-->
<!--                </span>-->
<!--              </template>-->
              <router-link
                custom
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                to="newproject"
              >
                <a :href="href" @click="navigate" :class="isActive">
                  <button type="button" class="btn btn-round btn-success">
                    + {{ $t("platform.projects.list.nav.newProject") }}
                  </button>
                </a>
              </router-link>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="top-bar set_search_textbox">
            <h5 style="margin: 0;"></h5>
            <div>
              <template>
                <span class="set_textbox_left">
                  <el-autocomplete
                    v-model="selectedProject"
                    :fetch-suggestions="remoteMethod"
                    :placeholder="$t('platform.projects.list.nav.searchProject')"
                    disabled="disabled"
                  ></el-autocomplete>
                </span>
              </template>
              <router-link
                custom
                v-slot="{ href, route, navigate, isActive, isExactActive }"
                to="newproject"
              >
                <a :href="href" @click="navigate" :class="isActive">
                  <button type="button" class="btn btn-round btn-default">
                    + {{ $t("platform.projects.list.nav.newProject") }}
                  </button>
                </a>
              </router-link>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- ORGANISATION Project list with tabs -->
      <template v-if="organisation && selectedProjectType === 'organisation'">
    <el-tabs
      v-model="selectedTab"
      type="border-card" class="project-tab">
      <el-tab-pane>
        <span slot="label"
          ><img :src="organisation.logo_url" width="30" />
          {{ organisation.name }}</span
        >
          <org-reporting></org-reporting>

      </el-tab-pane>
        <el-tab-pane lazy :label="$t('platform.projects.tab.projects')">
            <div class="set_msg organisation-project">
                <div class="row mr-2 ml-2">
                    <div
                        class="col-sm-12 col-md-6 col-lg-6 col-xl-4"
                        v-for="(project, index) in filteredOwnProjects"
                        v-bind:key="index"
                    >
                        <project-card
                            :project-id="selectedProjectType=='shared'?project.project_id:project.id"
                            :project-type="project.type"
                            :is-org-project="project.organisation_id != null"
                            :org-name="
                  project.organisation_name ? project.organisation_name : null
                "
                            :title="project.name"
                            :field-users="project.field_user_count"
                            :animals-treated="project.animals_treated_count"
                            :created-on="
                  convertDate(
                    project.created_at,
                    project.date_format,
                    project.timezone
                  )
                "
                            :last-active="activeAgo(project.updated_at, project.timezone)"
                            :project-image="project.image_url"
                            :user-role="project.user_role?project.user_role:'Owner'"
                            @get-projects="getProjects()"
                        ></project-card>
                    </div>
                </div>
                <div
                    class="row"
                    style="margin-left: 11px;margin-right: 11px;"
                    v-if="filteredOwnProjects.length == 0"
                >
                    <h5 style="font-size: 19px;margin-left: 73px;">
                        No Projects found
                    </h5>
                </div>
            </div>
        </el-tab-pane>
      <el-tab-pane :label="$t('platform.projects.tab.fieldTeams')">
        <field-teams></field-teams>
      </el-tab-pane>
      <el-tab-pane :label="$t('platform.projects.tab.drugsVaccines')">
        <div class="row">
          <div class="col-12">
            <health-and-care></health-and-care>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('platform.projects.tab.sharing')">
        <project-sharing></project-sharing>
      </el-tab-pane>
      <el-tab-pane :label="$t('platform.projects.tab.organisationSettings')">
        <organisation-settings></organisation-settings>
      </el-tab-pane>
    </el-tabs>
      </template>

    <!-- SHARED & PRIVATE Project list without tabs -->
    <div class="set_msg private-project" v-if="selectedProjectType !== 'organisation'">
      <div class="row mt-5 mr-2 ml-2">
        <div
          class="col-sm-12 col-md-6 col-lg-6 col-xl-4"
          v-for="(project, index) in filteredOwnProjects"
          v-bind:key="index"
        >
          <project-card
              :project-id="selectedProjectType=='shared'?project.project_id:project.id"
            :project-type="project.type"
            :is-org-project="project.organisation_id != null"
            :org-name="
              project.organisation_name ? project.organisation_name : null
            "
            :title="project.name"
            :field-users="project.field_user_count"
            :animals-treated="project.animals_treated_count"
            :created-on="
              convertDate(
                project.created_at,
                project.date_format,
                project.timezone
              )
            "
            :last-active="activeAgo(project.updated_at, project.timezone)"
            :project-image="project.image_url"
            :user-role="project.user_role?project.user_role:'Owner'"
            @get-projects="getProjects()"
          ></project-card>
        </div>
      </div>
      <!-- <div
        class="row ml-5 mt-3"
        v-if="filteredOwnProjects.length == 0"
      >
        <h5>
          {{
            selectedProjectType === 'organisation' ?
            'You have not created a project for your organization yet, click +NEW PROJECT to get started' :
            selectedProjectType === 'shared' ?
            'No projects shared with you yet' :
            'You have not created a private project yet,  click +NEW PROJECT to get started'
          }}
        </h5>
      </div> -->
    </div>

    <template
      v-if="
        (ownProjects.length == 0 && this.value == '' && fetchProjectsApiExecuted)
         || !(projectCount && (projectCount.organisation || projectCount.shared || projectCount.private))
      "
    >
      <div class="new-user-instructions" v-if="!organisation">
        <h2 class="text-center">Welcome to HSIApps!</h2>
        <p>
          Are you an admin user or a field user?
        </p>
        <p>
          If you are an admin user, start project by clicking on
          <b>
            <span class="text-primary">+ NEW PROJECT</span>
          </b>
          button.
        </p>
        <p>
          If you are a field user,
          <a
            href="https://play.google.com/store/apps/details?id=in.fusionbit.hsapps"
            target="_blank"
            >click here</a
          >
          to download the app from the Google Play Store.
        </p>
        <p>
          Then open the Field User app and app and login with your HSIApps
          account to make sure you can login successfully or contact your
          manager if you have any install problems.
        </p>
        <p>
          Note: You may see the message “You have not been assigned any
          projects” if you are not yet assigned to a project. That is normal,
          you will get an email when you are assigned to a project and you can
          continue at that point.
        </p>
      </div>
    </template>
    <!-- End -->
  </div>
</template>
<script>
import ProjectCard from "./UIComponents/Cards/ProjectCard.vue";
import { mapGetters } from "vuex";
import { Autocomplete, Tabs, TabPane } from "element-ui";
import FieldTeams from "./FieldTeams.vue";
import ProjectSharing from "./ProjectSharing.vue";
import OrganisationSettings from "./OrganisationSettings.vue";
import OrgReporting from "./Organisation/OrgReporting.vue"
import HealthAndCare from "../platform/HealthAndCare";


export default {
  components: {
      OrgReporting,
    ProjectCard,
      HealthAndCare,
    FieldTeams,
    ProjectSharing,
    OrganisationSettings,
    [Autocomplete.name]: Autocomplete,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },

  data() {
    return {
      fetchProjectsApiExecuted: false,
      info: "Loading...",
      statsCards: [
        {
          type: "warning",
          icon: "nc-icon nc-globe",
          title: "Capacity",
          value: "105GB",
          footerText: "Updated now",
          footerIcon: "nc-icon nc-refresh-69",
        },
        {
          type: "success",
          icon: "nc-icon nc-money-coins",
          title: "Revenue",
          value: "$1,345",
          footerText: "Last day",
          footerIcon: "nc-icon nc-calendar-60",
        },
        {
          type: "danger",
          icon: "nc-icon nc-vector",
          title: "Errors",
          value: "23",
          footerText: "In the last hour",
          footerIcon: "nc-icon nc-bell-55",
        },
        {
          type: "info",
          icon: "nc-icon nc-favourite-28",
          title: "Followers",
          value: "+45",
          footerText: "Updated now",
          footerIcon: "nc-icon nc-refresh-69",
        },
      ],
      imageUrl: "",
      searchQuery: "",
      value: "",
      searchedProjects: [],
      selectedProject: "",
      timeout: null,
      selectedProjectType: "organisation",
      selectedTab: "0",
      isLoadingData: false,
      //isOrgDeleted:false,
      projectCount: null,
      showOrgTab: false,
    };
  },
  props: {
    query: {
      type: Object,
    },
  },
  watch: {
    query: {
      handler: function(val, oldVal) {
        if (!this.organisation && this.selectedProjectType === 'organisation' && !this.projectCount) {
          val.type = 'shared';
        }
        this.selectedProjectType = val.type
          ? val.type
          : this.selectedProjectType;
        if (this.selectedProjectType === "organisation") {
          this.selectedTab = "0";
        }
        this.setProjectType(this.selectedProjectType);
      },
    },
  },
  methods: {
    init() {
      const vm = this;
      vm.showOrgTab = vm.userData.organisation && vm.userData.organisation.verified;
      if (vm.projectCount.organisation || vm.showOrgTab) {
        vm.setProjectType('organisation');
      } else if (vm.projectCount.shared) {
        vm.setProjectType('shared');
      } else if (vm.projectCount.private) {
        vm.setProjectType('private');
      }
    },
    setProjectType(type) {
      const vm = this;
      vm.selectedProjectType = type;
      if (vm.query.type !== type) {
        vm.$router.push({ query: { type: vm.selectedProjectType } });
      }
      vm.getProjects();
    },
    getProjects() {
      const loading = this.$loading({
        lock: true,
        text: "Please wait loading projects in...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$store
        .dispatch("user/getProjectsByType", this.selectedProjectType)
        .then((response) => {
          loading.close();
          this.fetchProjectsApiExecuted = true;
        });
    },
    remoteMethod(query, cb) {
      let vm = this;
      if (query !== "") {
        axios
          .get("project/search", {
            params: {
              name: query,
            },
          })
          .then((response) => {
            clearTimeout(vm.timeout);
            vm.searchedProjects = query
              ? response.data.response.data.projects.filter(
                  this.createFilter(query)
                )
              : response.data.response.data.projects;
            if (vm.ownProjects.length == 0) {
              var errMsg = [
                { id: 1, type: "errMsg", value: "You don't have any project" },
              ];
              cb(errMsg);
            } else {
              cb(vm.searchedProjects);
            }
          })
          .catch((error) => {
            vm.searchedProjects = [];
            cb(vm.searchedProjects);
          });
      } else {
        clearTimeout(vm.timeout);
        vm.searchedProjects = [];
        cb(vm.searchedProjects);
      }
    },

    createFilter(query) {
      return (searchedProjects) => {
        return (
          searchedProjects.value.toLowerCase().indexOf(query.toLowerCase()) ===
          0
        );
      };
    },
    showProjectDetails(project) {
      //alert(JSON.stringify(project));
      //window.location.href = "project/details/" + project.id;
      if (project.type == "MV") {
        this.$router.push({ path: "/project/mv/" + project.id + "/overview" });
      } else if (project.type == "PM") {
        this.$router.push({ path: "/project/pm/" + project.id + "/overview" });
      } else if (project.type == "hc") {
        this.$router.push({ path: "/project/hc/" + project.id + "/overview" });
      }
    },
  },

  computed: {
    ...mapGetters("user", ["ownProjects", "organisation", "userProjectCount", "userData"]),
    filteredOwnProjects: function() {
      return this.ownProjects;
    },
  },
  created() {
    if (!this.query.type) {
      this.$router.push({ query: { type: this.selectedProjectType } });
    }
    this.selectedProjectType = this.query.type
      ? this.query.type
      : this.selectedProjectType;
      if (this.query.selected_tab) {
          this.selectedTab=this.query.selected_tab
      }
  },
  async mounted() {
    let vm = this;
    await this.$store.dispatch('user/getOrganisation');
    if (vm.userProjectCount) {
      vm.projectCount = vm.userProjectCount;
      vm.init();
    } else {
      this.$root.$on('userDataSet', () => {
        vm.projectCount = vm.userProjectCount;
          vm.init();
      });
    }

    Bus.$on("search.query", (payLoad) => {});
    var data = localStorage.getItem("projectDropData");
    if (data != null) {
      data = JSON.parse(data);
      vm.searchQuery = data.project_type;
      vm.value = data.value;
      localStorage.removeItem("projectDropData");
    }
  },
};
</script>
<style scoped>
.nav-link-active-Projects {
  color: #f58220;
}

.new-user-instructions {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: 50px;
}
.new-user-instructions > p {
  line-height: 30px;
}

.el-tabs--border-card {
  background: none;
}

.organisation-project{
  @media (max-width: 767px) {
    margin-top: 15px !important;
    .mr-2, .ml-2{
      margin-right: -15px !important;
      margin-left: -15px !important;
    }
  }
}
.set_msg{
  @media (max-width: 480px) {
    .card-header{
      flex-flow: column;
      [role="img"]{
        margin-bottom: 15px;
      }
      .project-properties{
        align-self: center;
        margin: 10px 0 0;
      }
    }
  }
}
@media (max-width:991px) {
  .project_list{
    .navbar-nav{
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .el-autocomplete {
      width: 180px !important;
  }
}
@media (max-width: 600px) {
  .container-fluid{
      padding: 0;
  }
}
@media (max-width: 450px) {
  .project_list .nav_link_list li{
    margin: 0 !important;
  }
  .top-bar.set_search_textbox {margin-right: 0;}
  .top-bar .set_textbox_left{ left: 0;}
  .el-autocomplete {
      width: 125px !important;
      margin-right: 10px;
  }

}

</style>
