<template>
    <div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <el-tabs
                        v-model="selectedTab">

                        <el-tab-pane :label="$t('platform.projects.tab.species')">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <species-list></species-list>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('platform.projects.tab.veterinarians')">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <veterinarian-list></veterinarian-list>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane label="Drugs/Vaccines">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <drug-vaccine-list></drug-vaccine-list>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane label="Treatments">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <manage-treatment-list></manage-treatment-list>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane label="Deworming">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <manage-deworming-list></manage-deworming-list>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>


                    </el-tabs>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import {Tabs, TabPane} from "element-ui";
import DrugVaccineList from "../Template/DrugVaccineList";
import ManageTreatmentList from "./ManageTreatmentList";
import SpeciesList from "../Template/SpeciesList";
import VeterinarianList from "../Template/VeterinarianList";
import ManageDewormingList from "./ManageDewormingList";

export default {

    components: {
        ManageDewormingList,
        VeterinarianList,
        SpeciesList,
        ManageTreatmentList,
        DrugVaccineList,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
    },

    data() {
        return {
            selectedTab: "0",
        }
    }

}

</script>
