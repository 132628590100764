<template>
    <div>
        <v-offline
            online-class="online"
            offline-class="offline"
            @detected-condition="amIOnline">
            <template v-slot:[onlineSlot] :slot-name="onlineSlot">
                {{ onlineOfflineMsg }}
            </template>
            <template v-slot:[offlineSlot] :slot-name="offlineSlot">
                {{ onlineOfflineMsg }}
            </template>
        </v-offline>
        <div class="row">
            <div class="col-md-12 card">
                <div class="card-body row">
                    <div class="col-sm-12 col-xl-12">
                        <table id="hcRecords" class="display" width="100%"></table>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog width="80%" title="Edit/Update Details" :visible.sync="show.editRecordView">
            <div class="hc-edit-records row px-3">
                <el-form class="col" ref="recordForm" :model="selectedRecord">
                    <div class="row">
                        <div class="col-lg-2">
                            <el-image
                                style="width: 100px; height: 100px"
                                :src="selectedRecord.image_url"
                                :preview-src-list="[selectedRecord.image_url]"
                                lazy
                                alt="Animal Image"
                            >
                                <div slot="placeholder" class="image-slot">
                                Loading<span class="dot">...</span>
                                </div>
                            </el-image>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Collar Id" prop="collar_id">
                                <el-input placeholder="" v-model="selectedRecord.collar_id"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Species" prop="species">
                                <el-input placeholder="" v-model="selectedRecord.species"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Gender" prop="gender">
                                <el-select v-model="selectedRecord.gender" placeholder="Select">
                                    <el-option label="Male" value="Male"></el-option>
                                    <el-option label="Female" value="Female"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Age" prop="age_category">
                                <el-input placeholder="" v-model="selectedRecord.age_category"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Animal Name" prop="animal_name">
                                <el-input placeholder="" v-model="selectedRecord.animal_name"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Name" prop="name">
                                <el-input placeholder="" v-model="selectedRecord.name"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Ownership" prop="ownership">
                                <el-select v-model="selectedRecord.ownership" placeholder="Select">
                                    <el-option
                                        v-for="ownership in ownerships"
                                        :key="ownership"
                                        :label="ownership"
                                        :value="ownership">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Sterilized" prop="sterilized">
                                <el-select v-model="selectedRecord.sterilized" placeholder="Select">
                                    <el-option label="Already Sterilized" value="Already Sterilized"></el-option>
                                    <el-option label="Unsterilized" value="Unsterilized"></el-option>
                                    <el-option label="Unknown" value="Unknown"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Vaccination Given" prop="vaccination_other">
                                <el-select
                                    v-model="selectedRecord.given_vaccination_drug_ids"
                                    multiple
                                    placeholder="Select">
                                    <el-option
                                        v-for="pv in projectVaccineDrugs"
                                        :key="pv.id"
                                        :label="pv.vaccination_drug_name"
                                        :value="pv.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Vaccination Other" prop="vaccination_other">
                                <el-input placeholder="" v-model="selectedRecord.vaccination_other"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Deworming Done" prop="deworming">
                                <el-select
                                    v-model="selectedRecord.given_deworming_ids"
                                    multiple
                                    placeholder="Select">
                                    <el-option
                                        v-for="pd in projectDewormings"
                                        :key="pd.id"
                                        :label="pd.deworming_name"
                                        :value="pd.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Treatments Done" prop="treatment">
                                <el-select
                                    v-model="selectedRecord.given_treatment_ids"
                                    multiple
                                    placeholder="Select">
                                    <el-option
                                        v-for="pt in projectTreatments"
                                        :key="pt.id"
                                        :label="pt.treatment_name"
                                        :value="pt.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Notes" prop="notes">
                                <el-input type="textarea" :rows="2" v-model="selectedRecord.notes"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <template v-if="!isReadOnly">
                    <h6 class="border-top pt-3">Owner Details</h6>
                    <div class="row pt-2">
                        <div class="col-lg-2">
                            <el-form-item label="Owner Name" prop="owner_name">
                                <el-input placeholder="" v-model="selectedRecord.owner_name"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Owner Mobile" prop="owner_mobile">
                                <el-input placeholder="" v-model="selectedRecord.owner_mobile"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-2">
                            <el-form-item label="Owner Locality" prop="owner_locality">
                                <el-input placeholder="" v-model="selectedRecord.owner_locality"></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-lg-3">
                            <el-form-item label="Owner Address" prop="owner_address">
                                <el-input type="textarea" :rows="2" v-model="selectedRecord.owner_address"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    </template>
                    <div v-if="!isReadOnly" class="float-right d-flex">
                        <el-button
                            type="primary"
                            plain
                            @click="hideEditView">Cancel
                        </el-button>
                        <el-button
                            type="success"
                            @click="saveRecord">Save
                        </el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import mixin from '../../../mixins/GlobalMixin';
import {
  Dialog,
  Button,
  Card,
  Row,
  Col,
  Image,
  Form,
  FormItem,
  DatePicker,
  Input,
  Select,
  Option,
  Checkbox,
  Radio,
  RadioGroup,
  Tooltip,
  Alert
} from "element-ui";
import Swal from 'sweetalert2';
import VOffline from 'v-offline';
import healthClinicService from "../../../services/health-clinic.service";
export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        [Image.name]: Image,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Input.name]: Input,
        [Select.name]: Select,
        VOffline
    },
    props: {
        projectId: {
            type: Number,
        },
    },
    data() {
        return {
            isReadOnly: false,
            project: null,
            records: [],
            show: {
                editRecordView: false
            },
            selectedRecord: {
                id: null,
                rf_id: null,
                image_url: null,
                species:'',
                gender:'',
                animal_name:'',
                rf_id_location:'',
                name:'',
                ownership:'',
                age_category:'',
                collar_id:'',
                vaccination_other:'',
                deworming:'',
                treatment:'',
                notes:'',
                sterilized:'',
                owner_name: '',
                owner_mobile: '',
                owner_address: '',
                owner_locality: '',
                given_deworming_ids: '',
                given_treatment_ids: '',
                given_vaccination_drug_ids: ''
            },
            projectTreatments: [],
            projectDewormings: [],
            projectVaccineDrugs: [],
            ownerships: [
                "Owned",
                "Owned free roaming",
                "Community",
                "Community free roaming",
                "Stray",
                "Wild"
            ]
        }
    },
    mixins: [mixin],
    methods: {
        init() {
            const vm = this;
            vm.$store.dispatch("setScreenName", "Records");
            vm.setTreatments();
            vm.setDewormings();
            vm.setVaccineDrugs();
            vm.getRecords();
        },
        setTreatments() {
            const vm = this;
            const projectTreatments = JSON.parse(vm.project.treatment_ids);
            const orgTreatments = vm.$store.getters.getTreatments;
            orgTreatments.forEach((ot) => {
                if (projectTreatments.find((pt) => pt == ot.id)) {
                    vm.projectTreatments.push(ot)
                }
            });
        },
        setDewormings() {
            const vm = this;
            const projectDewormings = JSON.parse(vm.project.deworming_ids);
            const orgDewormings = vm.$store.getters.getDeworming;
            orgDewormings.forEach((ot) => {
                if (projectDewormings.find((pt) => pt == ot.id)) {
                    vm.projectDewormings.push(ot)
                }
            });
        },
        setVaccineDrugs() {
            const vm = this;
            const projectVaccineDrugs = JSON.parse(vm.project.vaccination_drug_ids);
            const orgVaccine = vm.$store.getters.getVaccinationDrugs;
            orgVaccine.forEach((ot) => {
                if (projectVaccineDrugs.find((pt) => pt == ot.id)) {
                    vm.projectVaccineDrugs.push(ot)
                }
            });
        },
        getRecords() {
            let vm = this;
            const loading = this.$loading({
                lock: true,
                text: 'Please wait loading records...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            healthClinicService.getRecords(vm.projectId)
            .then((apiResponse) => {
                loading.close();
                vm.records = apiResponse.response.data.records;
                vm.mapRecordWithTable();
            });
        },
        addHealthRecordViewListener(editEl) {
            let vm = this;
            editEl[0].addEventListener('click', function () {
                let record = JSON.parse(decodeURIComponent(this.getAttribute("data-data")));
                vm.selectedRecord = {
                    id: record.id,
                    rf_id: record.animal.rf_id,
                    image_url: record.image_url,
                    species: record.animal.species,
                    gender: record.animal.gender,
                    animal_name: record.animal.name,
                    rf_id_location: record.animal.rf_id_location,
                    name: record.animal.name,
                    ownership: record.ownership,
                    age_category: record.age_category,
                    collar_id: record.collar_id,
                    vaccination_other: record.vaccination_other,
                    deworming: record.deworming,
                    treatment: record.treatment,
                    notes: record.notes,
                    sterilized: record.sterilized,
                    owner_name: record.owner ? record.owner.name : null,
                    owner_mobile: record.owner ? record.owner.telephone_mobile : null,
                    owner_address: record.owner ? record.owner.google_street_address : null,
                    owner_locality: record.owner ? record.owner.google_locality : null,
                    given_deworming_ids: (record.given_deworming_ids && typeof record.given_deworming_ids === 'string') ? JSON.parse(record.given_deworming_ids) : record.given_deworming_ids ? record.given_deworming_ids: [],
                    given_treatment_ids: (record.given_treatment_ids && typeof record.given_treatment_ids === 'string') ? JSON.parse(record.given_treatment_ids) : record.given_treatment_ids ? record.given_treatment_ids: [],
                    given_vaccination_drug_ids: (record.given_vaccination_drug_ids && typeof record.given_vaccination_drug_ids === 'string') ? JSON.parse(record.given_vaccination_drug_ids) : record.given_vaccination_drug_ids ? record.given_vaccination_drug_ids: []
                };
                vm.show.editRecordView = true;
            }, false);
        },
        saveRecord() {
            const vm = this;
            const loading = vm.$loading({
                lock: true,
                text: 'Please wait updating record...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            healthClinicService.updateRecord(vm.projectId, vm.selectedRecord.id, vm.selectedRecord)
            .then((apiResponse) => {
                apiResponse = apiResponse.response;
                loading.close();
                if (!apiResponse.error.error_code) {
                    vm.updatePasswordDialogVisible = false;
                    vm.showAlert('Success', `Record updated successfully`, true);
                    vm.show.editRecordView = false;
                    vm.getRecords();
                } else {
                    vm.showAlert('Error', apiResponse.error.msg, false);
                }
            }).catch(error => {
                loading.close();
                vm.showAlert('Error', error.response.error.error_msg, false);
            });
        },
        hideEditView() {
            const vm = this;
            vm.show.editRecordView = false;
        },
        mapRecordWithTable() {
            const vm = this;

            // Define the columns array dynamically based on the isReadOnly flag
            const columns = [
                {
                    title: 'Date/Time',
                    data: 'created_at',
                    render: function (data) {
                        return vm.convertDate(data);
                    }
                },
                {
                    title: 'User',
                    data: 'user.email'
                },
                {
                    title: 'Image',
                    data: 'image_url',
                    render: function (data, type) {
                        return type !== 'export'
                            ? '<img src=' + data + ' class="datatable_image_thumbnail" style="cursor: pointer;" height="70" width="70"/>'
                            : data;
                    }
                },
                {
                    title: 'Coller Id',
                    data: 'collar_id'
                },
                {
                    title: 'Gender',
                    data: 'animal',
                    render: {
                        _: 'gender'
                    }
                },
                {
                    title: 'Age',
                    data: 'age_category'
                },
                {
                    title: 'Species',
                    data: 'animal',
                    render: {
                        _: 'species'
                    }
                },
                {
                    title: 'Treatment',
                    data: null,
                    render: function (data) {
                        const treatment = vm.getNameForTreatmentVaccineDeworming(data, 'given_treatment_ids', 'treatment_name', 'projectTreatments');
                        const deworming = vm.getNameForTreatmentVaccineDeworming(data, 'given_deworming_ids', 'deworming_name', 'projectDewormings');
                        const vaccination = vm.getNameForTreatmentVaccineDeworming(data, 'given_vaccination_drug_ids', 'vaccination_drug_name', 'projectVaccineDrugs');
                        return `<span>Treatment: ${treatment}</span></br><span>&nbsp;Deworming: ${deworming}</span></br><span>&nbsp;Vaccination: ${vaccination}</span>`;
                    }
                },
                {
                    title: 'Collected By',
                    data: 'user',
                    render: function (data) {
                        return `${data.first_name} ${data.last_name}`;
                    }
                },
                // Conditionally add 'Owner Name' and 'Owner Number' columns based on isReadOnly flag
                ...(vm.isReadOnly ? [] : [
                    {
                        title: 'Owner Name',
                        data: 'owner',
                        render: function (data) {
                            return data ? data.name : 'N/A';
                        },
                    },
                    {
                        title: 'Owner Number',
                        data: 'owner',
                        render: function (data) {
                            return data ? data.telephone_mobile : 'N/A';
                        }
                    },
                    {
                        title: 'Owner Location',
                        data: 'owner',
                        render: function (data, type) {
                            if (data) {
                                const location = data.location_lat + ',' + data.location_lng;
                                return type !== 'export'
                                    ? `<a href="https://www.google.com/maps/search/?api=1&query=${location}" target="_blank">Map</a>`
                                    : location;
                            } else {
                                return 'N/A';
                            }
                        }
                    },
                ]),
                {
                    title: 'Action',
                    data: null,
                    render: function (data, type, row) {
                        if (vm.isReadOnly) {
                            return `<button type="button" class="btn btn-warning viewHealthRecordDetails" data-data="${encodeURIComponent(JSON.stringify(data))}">View</button>`;
                        }
                        return `<button type="button" class="btn btn-warning viewHealthRecordDetails" data-data="${encodeURIComponent(JSON.stringify(data))}">View/Edit</button>`;
                    }
                }
            ]

            $("#hcRecords").DataTable({
                search: {
                    caseInsensitive: false
                },
                data: vm.records,
                destroy: true,
                dom: 'lBfrtip',
                "bInfo": false,
                "scrollX": true,
                columns: columns, // Use the dynamically defined columns
                buttons: [
                    {
                        extend: 'excelHtml5',
                        text: 'Export to excel',
                        className: 'btn btn-default',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                            orthogonal: 'export'
                        },
                    },
                    {
                        extend: 'colvis',
                        text: 'Filter Columns',
                        className: 'btn btn-default'
                    }
                ],
                'fnRowCallback': function (nRow) {
                    const imageElement = $('.datatable_image_thumbnail', nRow);
                    vm.zoomImagePopup(imageElement);
                    const viewEditBtn = $('.viewHealthRecordDetails', nRow);
                    vm.addHealthRecordViewListener(viewEditBtn);
                    return nRow;
                }
            })
        },
        getNameForTreatmentVaccineDeworming(data, dataKey, valueName, mapedData) {
            const vm = this;
            return ((data[dataKey] && typeof data[dataKey] === 'string') ? JSON.parse(data[dataKey]) : data[dataKey] ? data[dataKey]: []).map((dIds) => {
                return vm[mapedData].find((pt) => pt.id == dIds)[valueName];
            }).join(", ");
        }
    },
    mounted() {
        let vm = this;
        vm.project = vm.$store.getters.getCurrentSelectedProject;
        vm.isReadOnly = false
        vm.$store.getters.getUserRoleForCurrentProject.forEach(r => {
            if (r.role_name == 'VIEWER') {
                vm.isReadOnly = true
            }
        });
        vm.init();
    }
}

</script>
<style lang="scss">
.hc-edit-records {
  .el-form-item__label {
    padding: 0 !important;
    margin-bottom: 5px !important;
    line-height: 10px !important;
  }
  .save-btn {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 15px;
    bottom: 0;
  }
}
</style>
